import styled from 'styled-components';

const Toolbar = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 84.8px;
    background: ${(props) => props.theme.text};
    color: ${(props) => props.theme.secondaryText};
`;

export { Toolbar };
