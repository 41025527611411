import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ExpandMore } from './Icons';

const Container = styled.div`
    position: relative;
    display: block;
    width: 250px;
    margin: 0 auto;
    font-size: 18px;
    color: ${(props) => props.theme.secondaryText};
    fill: ${(props) => props.theme.secondaryText};
    outline: none;
`;

const Label = styled.div`
    font-weight: 500;
    text-align: ${(props) => props.textAlign || 'left'}
    color: ${(props) => props.theme.secondaryText};
    margin-bottom: 0.2em;
`;

const Selected = styled.div`
    display: flex
    position: relative;
    cursor: pointer;
    outline: none;
    color: ${(props) => props.theme.secondaryText};
    background: ${(props) => props.theme.text};
    border: 2px ${(props) => props.theme.primary} solid;
    border-radius: 10px;
`;

const SelectedLabel = styled.p`
    display: none;
    width: 100%;
    margin: 0;
    padding: 12px;
`;

const SelectedInput = styled.input`
    display: none;

    :checked + ${SelectedLabel} {
        display: block;
    }
`;

const Options = styled.ul`
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    color: ${(props) => props.theme.secondaryText}
    background: ${(props) => props.theme.text};
    border: 2px ${(props) => props.theme.primary} solid;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 0.2em;
    z-index: 1;
`;

const Option = styled.li`
    display: block;
    padding: 15px;
    cursor: pointer;

    :hover {
        background: ${(props) => props.theme.primary}
    }

    > label {
        cursor: pointer;
    }
`;

const ExpandIcon = styled(ExpandMore)`
    height: 46px;
    transition: transform 0.15s;
    fill: ${(props) => props.theme.secondaryText};

    ${(props) => props.open && `
        transform: rotate(180deg);
    `}
`;

const Select = ({ options, name, value, label, onChange, ...rest }) => {
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setSelected(value);
    }, [value]);
    useEffect(() => {
        if (selected == null && options && options.length > 0) {
            setSelected(options[0]);
            if (onChange) {
                onChange(options[0]);
            }
        }
    }, [onChange, options, selected]);
    return (
        <Container {...rest} tabIndex='0' onBlur={() => setOpen(false)}>
            <Label textAlign={rest.labelTextAlign}>{label}</Label>
            <Selected onClick={() => setOpen(!open)}>
                <SelectedInput
                    type='radio'
                    id={selected}
                    value={selected ? selected.value : ''}
                    name={name}
                    defaultChecked='checked'
                />
                <SelectedLabel>{selected && selected.label}</SelectedLabel>
                <ExpandIcon open={open} />
            </Selected>
            {open && (
                <Options>
                    {options && options.map((item, index) => (
                        <Option key={index} onClick={() => {
                            setSelected(options[index]);
                            setOpen(false);
                            if (onChange) {
                                onChange(item);
                            }
                        }}>
                            <label>{item.label}</label>
                        </Option>
                    ))}
                </Options>
            )}
        </Container>
    );
};

export { Select };
