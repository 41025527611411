import { Advertisement } from './Advertisement';
import { Content } from './Content';
import { Title } from './Title';
import { Product } from './Product';
import { Value } from './Value';

Advertisement.Content = Content;
Advertisement.Title = Title;
Advertisement.Product = Product;
Advertisement.Value = Value;

export { Advertisement };
