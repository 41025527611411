import React from 'react';
import styled from 'styled-components';

import { Image as ImageTemplate } from '../Image';

const IMAGE_DIM = '100px';

const ImageContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;

    text-align: center;
`;

const Image = ({ image }) => {
    return (
        <ImageContainer>
            <ImageTemplate
                style={{
                    height: IMAGE_DIM,
                    width: IMAGE_DIM
                }}
                image={image}
            />
        </ImageContainer>
    );
};

export { Image };
