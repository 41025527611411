import styled from 'styled-components';
import { Svg } from '../Icons/Svg';

const Button = styled.button`
    display: block;
    align-items: center;
    text-transform: uppercase;

    background: ${(props) => props.disabled ? 'grey' : (props.bg || props.theme.tertiary)};
    color: ${(props) => props.primaryColor || 'white'};

    border: ${(props) => props.borderWidth || '0px'} ${(props) => props.borderColor || props.theme.primary} solid;
    border-radius: 10px;

    min-width: 200px;
    height: 63px;

    font-size: ${(props) => props.fontSize || '100%'};
    font-weight: bold;
    font-family: "Titillium Web";

    cursor: pointer;

    flex: ${(props) => props.flex ? 1 : 'initial'};
    outline: none;
    transition: background 0.25s;

    :hover {
        background: ${(props) => props.disabled ? 'gray': (props.hoverBg || props.theme.secondaryText)};
    }

    > ${Svg} {
        position: relative;
        width: 17px;
        height: 17px;
        top: 2px;
        margin-left: 2em;

        @media (max-width: 700px) {
            margin-left: 1em;
        }
    }
`;

export { Button };
