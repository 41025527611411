import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import * as Cookies from 'js-cookie';
import styled from 'styled-components';
import { connect } from 'react-redux';
import queryString from 'query-string';
import isMobile from 'is-mobile';
import { Page } from '../common';
import { getLangValue } from '../common/functions';
import { isNumber } from '../common/functions/numbers';
import { useFetch } from '../common/hooks';
import { showMessage } from '../common/Notifier';
import * as userActions from '../store/actions';
import { Button } from '../common';
import i18n from '../i18n';
import config from '../config';

const PLAYABLE_LOTTERY_FRAME_NAME = 'playableLotteryFrame';

const IframeOverlay = styled.div`
    position: absolute;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PlayButton = styled(Button)`

`;

const PlayableLottery = ({
    history,
    location,
    match: { params: { slug } },
    user: { authenticated },
    userActions: { logout, refreshAccounts }
}) => {
    const [{ data: lottery }] =
          useFetch(`${config.backendAddress}/api/v1/lotteries/by-slug/${slug}`);
    const [mobile, setMobile] = useState(false);

    const locationSearch = queryString.parse(
        location.search,
        {
            parseBooleans: true,
            parseNumbers: true,
        }
    );

    //console.log('locationSearch', locationSearch);

    const purchaseAmount = (
        location.state ?
            location.state.purchaseAmount :
            locationSearch.purchase_amount
    );

    //console.log('purchaseAmount', purchaseAmount);

    const demoGame = (
        location.state ?
            location.state.demoGame :
            locationSearch.demo_game
    );

    //console.log('demoGame', demoGame);

    const toggleIframeFullscreen = () => {
        if(document.fullscreenElement || document.webkitFullscreenElement) {
            if(document.exitFullscreen) {
                document.exitFullscreen();
            }
            if(document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        } else {
            const el = document.getElementById(PLAYABLE_LOTTERY_FRAME_NAME);
            if(el.requestFullscreen) {
                el.requestFullscreen();
            }
            if(el.webkitRequestFullscreen) {
                el.webkitRequestFullscreen();
            }
        }
    };

    const showClient = () => {
        toggleIframeFullscreen();
        document
            .getElementById('clientOverlay')
            .style
            .display = 'none';
    };

    useEffect(() => {
        if(isMobile()) {
            setMobile(true);
        }

        setTimeout(() => {
            const beacon = document.getElementById('beacon-container');
            if(beacon) {
                beacon.hidden = true;
            }
        }, 1000);

        return () => {
            const beacon = document.getElementById('beacon-container');
            if(beacon) {
                beacon.hidden = false;
            }
        };
    }, []);

    useEffect(() => {
        let timeoutHandle;
        let playableLotteryUrl;

        if(lottery && lottery.playable_lottery_address) {
            playableLotteryUrl = new URL(lottery.playable_lottery_address);
        }
        const messageHandler = (event) => {
            // console.log('Handle event from game client:', event);
            if(event.origin !== playableLotteryUrl.origin) {
                return;
            }
            let message;

            if(typeof(event.data) === 'object') {
                // Client gave data as an object
                message = event.data;
            } else {
                // Assume client gave data as a string
                message = JSON.parse(event.data);
            }
            if(message.version !== 1) {
                showMessage(i18n.gettext('game_client_invalid_version'), true);
            } else if(message.type === 'finish') {
                const beacon = document.getElementById('beacon-container');
                if(beacon) {
                    beacon.hidden = false;
                }

                // Use might have purchased tickets, get accounts
                if(authenticated) {
                    refreshAccounts();
                }

                if(message.reason === 'ok') {
                    history.push('/pelattavat/' + lottery.slug);
                } else if(message.reason === 'noSaldo') {
                    showMessage('Lisää saldoa ostoa varten', false);
                    history.push(
                        `/tili/lisaa-saldoa?amount=${lottery.playable_ticket_bundle_size * lottery.playable_ticket_prices[0].price}`, {
                            redirect_to: `/pelattavat/${slug}/pelaa?purchase_amount=${purchaseAmount}`
                        }
                    );
                } else if(message.reason === 'noAuth') {
                    showMessage(i18n.gettext('signature_has_expired'), true);
                    logout();
                } else if(message.reason === 'noPlaytime') {
                    showMessage(i18n.gettext('playtime_limited'), true);
                    history.push('/pelattavat/' + lottery.slug);
                } else {
                    showMessage(i18n.gettext('unknown_error'), true);
                    history.push('/pelattavat/' + lottery.slug);
                }
            } else if(message.type === 'getInit') {
                console.log('Game client called to get initialization');
                initClient();
            } else {
                showMessage(i18n.gettext('game_client_init_failed'), true);
            }
        };

        const initClient = () => {
            try {
                document
                    .getElementById(PLAYABLE_LOTTERY_FRAME_NAME)
                    .contentWindow
                    .postMessage(
                        JSON.stringify({
                            type: 'init',
                            version: 1,
                            userToken: demoGame ? 'demo' : Cookies.get('id_token'),
                            lotteryId: lottery.id,
                            lotterySlug:  slug,
                            purchaseAmount: purchaseAmount,
                            ticketPrice: lottery.playable_ticket_prices[0].price,
                            apiUrlPrefix: demoGame ? '/demo-api' : '/api',
                            demoGame: demoGame !== undefined ? !!demoGame : false,
                            discount: lottery.playable_ticket_discount,
                        }),
                        playableLotteryUrl.origin
                    );
            } catch(exc) {
                showMessage(i18n.gettext('game_client_send_failed'), true);
            }
        };

        if(lottery && lottery.playable_lottery_address) {
            console.log('Setup message handler:', lottery.playable_lottery_address);
            window.addEventListener('message', messageHandler);
        }

        return () => {
            console.log('Cleanup');
            window.removeEventListener('message', messageHandler);
            timeoutHandle && window.clearTimeout(timeoutHandle);
        };
    }, [lottery, slug, refreshAccounts, history, purchaseAmount]);

    if(demoGame !== undefined) {
        if(demoGame !== false && demoGame !== true) {
            return <div>Invalid value for demo game</div>;
        }
    }

    if(purchaseAmount !== undefined) {
        if(!isNumber(purchaseAmount)) {
            return <div>Invalid value for purchase amount</div>;
        }
    }

    return (
        <Page>
            {
                (lottery && lottery.playable_lottery_address) ? (
                    <Page.Content flex>
                        {
                            (authenticated || demoGame)
                                ? (
                                    <>
                                        <iframe
                                            allow='fullscreen'
                                            id={PLAYABLE_LOTTERY_FRAME_NAME}
                                            src={lottery.playable_lottery_address}
                                            style={{
                                                border: '0',
                                                minHeight: 'calc(100vh - 80px)',
                                                minWidth: 'calc(100vw)'
                                            }}
                                            title={getLangValue(lottery.names, 'fi')}
                                        >
                                        </iframe>

                                        {
                                            mobile &&
                                                <IframeOverlay id='clientOverlay'>
                                                    <PlayButton onClick={() => showClient()}>
                                                        Pelaa
                                                    </PlayButton>
                                                </IframeOverlay>
                                        }
                                    </>
                                )
                                : <div>Kirjaudu sisään</div>
                        }
                    </Page.Content>
                ) : 'Lataa arpaa'
            }
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ConnectedPlayableLottery = connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayableLottery);

export { ConnectedPlayableLottery as PlayableLottery };
