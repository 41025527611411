import React from 'react';
import styled from 'styled-components';

import { Backdrop } from './Backdrop';

const DrawerArea = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 500px;
    max-width: 100%;
    background: white;
    transition: all 0.25s;
    overflow-x: hidden;
    transform: translateX(100%);

    &[open] {
        pointer-events: auto;
        transform: translateX(0);
    }
`;

const Drawer = ({ open, ...props }) => {
    return (
        <Backdrop open={open}>
            <DrawerArea open={open} {...props} />
        </Backdrop>
    );
};

export { Drawer };
