import styled from 'styled-components';

const Content = styled.div`
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;
    padding: 5%;
    margin: 0 auto;
    overflow: auto;

    ${(props) => props.minHeight && `
        min-height: ${(props.minHeight)}
    `}

    > * {
        width: 100%;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    @media(max-width: 800px) {
        margin-bottom: 100px;
    }

`;

export { Content };
