import { createAction } from 'redux-actions';
import * as Cookies from 'js-cookie';
import * as constants from './constants';
import { userManager } from '../store';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const updateUser = createAction(constants.UPDATE_USER);
const updateCart = createAction(constants.UPDATE_CART);
const addItem = createAction(constants.ADD_CART_ITEM);
const removeItem = createAction(constants.REMOVE_CART_ITEM);
const clearCart = createAction(constants.CLEAR_CART);
const openMenu = createAction(constants.OPEN_MENU);
const closeMenu = createAction(constants.CLOSE_MENU);
const updateAccounts = createAction(constants.UPDATE_ACCOUNTS);

const register = () => {
    return (dispatch, getState) => {
        window.sessionStorage.setItem('return_url', window.location.href);
        userManager.signinRedirect({
            extraQueryParams: {
                request_registration: '1'
            }
        });
    };
};

const login = () => {
    return (dispatch, getState) => {
        window.sessionStorage.setItem('return_url', window.location.href);
        userManager.signinRedirect();
    };
};

const logout = () => {
    return (dispatch, getState) => {
        Cookies.remove('profile');
        Cookies.remove('access_token');
        Cookies.remove('id_token');
        userManager.signoutRedirect();
    };
};

const refreshAccounts = () => {
    return (dispatch, getState) => {
        fetch(`${config.backendAddress}/api/v1/accounts/self`)
            .then((response) => response.json())
            .then((json) => {
                dispatch(updateAccounts(json.items));
            });
    };
};

const loadShopCart = (callback) => {
    return (dispatch, getState) => {
        fetch(`${config.backendAddress}/api/v1/shop/cart`)
            .then((response) => response.json())
            .then((json) => {
                dispatch(updateCart({ items: json.items }));
            })
            .finally(() => {
                callback && callback();
            });
    };
};

const saveShopCart = (callback) => {
    return (dispatch, getState) => {
        const state = getState();

        fetch(`${config.backendAddress}/api/v1/shop/cart`, {
            method:'POST',
            body: JSON.stringify({
                items: state.user.cart.items.map((item) => {
                    return {
                        id: item.id,
                        option: item.option?.variation_id,
                    };
                })
            })
        })
            .finally(() => {
                callback && callback();
            });
    };
};

const saveState = () => {
    return (dispatch, getState) => {
    };
};

export {
    login,
    logout,
    register,
    updateUser,
    openMenu,
    closeMenu,
    updateCart,
    addItem,
    removeItem,
    clearCart,
    refreshAccounts,
    loadShopCart,
    saveShopCart,
    updateAccounts,
    saveState,
};
