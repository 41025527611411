import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
    color: ${(props) => props.theme.textSecondary};
    font-weight: 500;
    font-size: 1.5em;
    text-align: center;

    @media (max-width: ${(props) => props.theme.mobileLimit}) { font-size: 2em; }
`;
const TitleContainer = styled.div`
    background-color: ${(props) => props.theme.tertiary};
    border-radius: 0px;
    color: white;
    display: flex;
    flex-flow: column;
    font-weight: 400;
    padding: 2em 0 2em;
`;

const SubsectionHeader = ({ sectionTitle }) => {

    return(
        <TitleContainer>
            <Title>{sectionTitle}</Title>
        </TitleContainer>
    );
};

export default SubsectionHeader;
