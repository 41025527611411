import React from 'react';
import styled from 'styled-components';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const SlideContainer = styled.div`
   .each-slide > div {
        display: flex;
        align-items: center;
        justify-content: center;

        min-height: 300px;
        height: 21vw;
        width: 100%;

        background-size: cover;
        background-position-x: center;
    }

    .each-slide span {
        margin-top: 2em;
        padding: 10px;
        color: white;

        font-size: 64pt;
        font-family: 'Titillium Web';
        font-weight: 500;

        text-transform: uppercase;
        background-color: ${(props) => props.theme.tertiary};
        border-radius: 0px;
        text-align: center;

        @media (max-width: 1200px) {
            font-size: 30pt;
        }

        @media (max-width: 700px) {
            font-size: 20pt;
        }
    }
`;


const Slideshow = ({ slides }) => {
    return slides ? (
        <SlideContainer className='slide-container'>
            <Slide arrows={true} autoplay={true}>
                {slides.map((item, index) => (
                    <div
                        key='slide-{index}'
                        className='each-slide'
                    >
                        <div
                            style={{ 'backgroundImage': `url(${item.image})` }}
                            dangerouslySetInnerHTML={{
                                __html: item.content && item.content
                            }}
                        >
                        </div>
                    </div>
                ))}
            </Slide>
        </SlideContainer>
    ) : '';
};

export { Slideshow };
