import React, { Component } from 'react';
import { TextField } from './TextField';
import i18n from '../i18n';

class IntegerField extends Component {
    validateInteger = (target, value) => {
        if(!value) {
            target.setCustomValidity('');
            return;
        }

        const parsed = parseInt(value, 10);
        if(isNaN(parsed)) {
            target.setCustomValidity(i18n.gettext('not_integer'));
        } else {
            target.setCustomValidity('');
        }
    };

    integerChange = (event, origOnChange) => {
        this.validateInteger(event.target, event.target.value);
        origOnChange && origOnChange(event);
    };

    render() {
        const { onChange, ...props } = this.props;
        return (
            <TextField
                {...props}
                onChange={(event) => this.integerChange(event, onChange)}
                mountFun={this.validatePostalCode}
            />
        );
    };
};

export { IntegerField };
