import { ItemPage } from './ItemPage';
import { Image } from './Image';
import { Content } from './Content';
import { Title } from './Title';
import { Description } from './Description';
import { Text } from './Text';
import { Actions } from './Actions';

ItemPage.Image = Image;
ItemPage.Content = Content;
ItemPage.Title = Title;
ItemPage.Description = Description;
ItemPage.Text = Text;
ItemPage.Actions = Actions;

export { ItemPage };
