import React from 'react';
import styled from 'styled-components';

import { Backdrop } from './Backdrop';

const DialogWindow = styled.div`
    position: fixed;
    width: 100%;

    max-height: ${(props) => props.maxHeight};
    max-width: ${(props) => props.maxWidth || '550px'};
    background: ${(props) => props.bg || props.theme.background};
    border-radius: 5px;
    transition: all 0.25s;
    pointer-events: none;
    overflow-x: ${(props) => props.overflowX || 'hidden'};

    &[open] {
        pointer-events: auto;
    }

    @media (min-width: 701px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);

        &[open] {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    @media (max-width: 700px) {
        top: 0;
        left: 0;
        height: 100%;
        transform: scale(0);
        max-width: none;
        border-radius: 0;

        &[open] {
            transform: scale(1);
        }
    }
`;

const Dialog = React.forwardRef(({ open, ...props }, ref) => {
    return (
        <Backdrop open={open}>
            <DialogWindow ref={ref} open={open} {...props} />
        </Backdrop>
    );
});

export { Dialog };
