import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { List, Item, HeaderItem } from '../common/IncrementalList';
import SubsectionHeader from '../common/AccountSubsectionHeader';
import i18n from '../i18n';
import { getLangValue, formatCurrency } from '../common/functions';


const AccountInfo = styled.div`
  margin: 1em 2em 0 2em;
  font-size: 120%;
`;


const Saldos = ({ user }) => {
    return (
        <>
            <SubsectionHeader sectionTitle='Tilien saldot'/>

            <AccountInfo>
                <div><strong>Voitot:</strong> sisältää arvoista voittamasi voitot, tällä saldolla voit ostaa tuotteita voittokaupasta.</div>

                <div><strong>Lisävoitot:</strong> arpojen kahdesta pienimmästä voittoluokasta voittamasi voitot. Tämän saldon voi käyttää uusien arpojen ostoon ja tuotteiden ostamiseen voittokaupasta.</div>

                <div><strong>Raha:</strong> pankista siirtämäsi saldo, jota voit käyttää arpojen ostoon.</div>

                <div><strong>Ostobonus:</strong> kampanjasta saamasi saldo, jolla voit ostaa tuotteita verkkokaupasta.</div>

                <div><strong>Pelibonus:</strong> kampanjasta saamasi saldo, jolla voit ostaa arpoja.</div>
            </AccountInfo>

            <List>
                <tbody>
                    {
                        <HeaderItem key='listHeader'>
                            <th key='headerLotteryName'>{i18n.gettext('lottery')}</th>
                            <th key='headerTicketSeriesAlias'>{i18n.gettext('ticket_series_alias')}</th>
                            <th key='headerAccountVariety'>{i18n.gettext('variety')}</th>
                            <th key='headerSaldo'>{i18n.gettext('saldo')}</th>
                            <th key='headerUserAccountValidityEnd'>{i18n.gettext('user_account_validity_end')}</th>
                        </HeaderItem>
                    }
                    {
                        user.accounts.filter((account) => account.saldo != 0 && account.variety != 'payments').map((account) => (
                            <Item key={account.id}>
                                <td key='lottery'>{
                                    account.lottery && account.lottery.names && getLangValue(account.lottery.names, 'fi')
                                }</td>
                                <td key='ticketSeries'>{
                                    account.ticket_series && account.ticket_series.alias
                                }</td>
                                <td key='variety'>{i18n.gettext(account.variety)}</td>
                                <td key='saldo'>{formatCurrency(account.saldo)} €</td>
                                <td key='userAccountValidityEnd'>{
                                    account.ticket_series && account.ticket_series.user_account_validity_end && (
                                        moment.utc(account.ticket_series.user_account_validity_end)
                                            .local()
                                            .format('DD.MM.YY HH:mm')
                                    )
                                }</td>
                            </Item>
                        ))
                    }
                </tbody>
            </List>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const ConnectedSaldos = connect(mapStateToProps)(Saldos);

export { ConnectedSaldos as Saldos };
