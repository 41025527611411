import { Dialog } from './Dialog';
import { Header } from './Header';
import { Content } from './Content';
import { Text } from './Text';
import { Actions } from './Actions';
import { Footer } from './Footer';

Dialog.Header = Header;
Dialog.Content = Content;
Dialog.Text = Text;
Dialog.Actions = Actions;
Dialog.Footer = Footer;

export { Dialog };
