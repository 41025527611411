import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'form-association-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import './index.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { ThemeProvider } from 'styled-components';
import { Theme } from './theme';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import * as Cookies from 'js-cookie';
import { store, userManager } from './store';
import Callback from './Callback';
import Auth from './Auth';
import config from './config';

if(process.env.NODE_ENV === 'production') {
    console.log = function () {};
}

const ConditionalWrapper = ({ conditionFn, wrapper, children }) => conditionFn() ? wrapper(children) : children;

ReactDOM.render(
    <ConditionalWrapper
        conditionFn={() => {
            const ccCookie = JSON.parse(Cookies.get('cc_cookie') || '{}');
            const index = ccCookie?.categories?.indexOf('analytics');
            if (typeof index === 'number' && index >= 0)  {
                return config.gtm.id;
            }
            return null;
        }}
        wrapper={(children) => (
            <GTMProvider state={{
                id: config.gtm.id,
                injectScript: false
            }}>
                {children}
            </GTMProvider>
        )}
    >
        <ThemeProvider theme={Theme}>
            <Provider store={store}>
                <OidcProvider store={store} userManager={userManager}>
                    <BrowserRouter>
                        <Switch>
                            <Route path='/callback' component={Callback} />
                            <Route path='/auth' component={Auth} />
                            <Route component={App} />
                        </Switch>
                    </BrowserRouter>
                </OidcProvider>
            </Provider>
        </ThemeProvider>
    </ConditionalWrapper>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
