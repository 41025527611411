import React, { useState } from 'react';
import { Form, PostalCodeField, TextField, Checkbox } from '../common';

const Contact = ({ user, onNextStep }) => {
    const [firstname, setFirstname] = useState(user.first_name);
    const [lastname, setLastname] = useState(user.last_name);
    const [address, setAddress] = useState(user.address ? user.address.street_address : '');
    const [postalCode, setPostalCode] = useState(user.address ? user.address.postal_code : '');
    const [city, setCity] = useState(user.address ? user.address.city : '');
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone_number || '');
    const [marketingAllowed, setMarketingAllowed] = useState(user.marketing_allowed);
    const [advertisementAllowed, setAdvertisementAllowed] = useState(user.advertisement_allowed);

    return (
        <div>
            <Form action={onNextStep} id='contactForm'>
                <Form.SectionContainer>
                    <Form.Section>
                        <Form.SectionTitle>Nimitiedot</Form.SectionTitle>
                        <TextField name='firstname' label='Etunimi' required value={firstname} onChange={(event) => setFirstname(event.target.value)} />
                        <TextField name='lastname' label='Sukunimi' required value={lastname} onChange={(event) => setLastname(event.target.value)} />
                    </Form.Section>
                    <Form.Section>
                        <Form.SectionTitle>Yhteystiedot</Form.SectionTitle>
                        <TextField type='email' name='email' label='Sähköposti' required value={email} onChange={(event) => setEmail(event.target.value)} />
                        <TextField type='tel' name='phone' label='Puhelinnumero' required value={phone} onChange={(event) => setPhone(event.target.value)} />
                        <Checkbox name='marketing_allowed' label='Haluan saada Arpamaailma aiheista sähköpostia.' checked={marketingAllowed} onChange={(event) => setMarketingAllowed(event.target.checked)} />
                        <Checkbox name='advertisement_allowed' label='Haluan saada Arpamaailman yhteistyökumppaneilta tietoa.' checked={advertisementAllowed} onChange={(event) => setAdvertisementAllowed(event.target.checked)} />
                    </Form.Section>
                    <Form.Section>
                        <Form.SectionTitle>Osoitetiedot</Form.SectionTitle>
                        <TextField name='address' label='Lähiosoite' required value={address} onChange={(event) => setAddress(event.target.value)} />
                        <PostalCodeField type='tel' name='postal_code' label='Postinumero' required value={postalCode} onChange={(event) => setPostalCode(event.target.value)} />
                        <TextField name='city' label='Postitoimipaikka' required value={city} onChange={(event) => setCity(event.target.value)} />
                    </Form.Section>
                </Form.SectionContainer>
            </Form>
        </div>
    );
};

export { Contact };
