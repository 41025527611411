import styled from 'styled-components';
import { Button } from '../common/Button';

const LotteryButton = styled(Button)`
    background: ${(props) => props.theme.tertiary};
    color: ${(props) => props.theme.text};
    height: 45px;
    font-size: 16px;

    width: 10vw;
    min-width: 100px;

    :hover {
        background: ${(props) => props.hoverBg || props.theme.buttonHover};


`;

export { LotteryButton };
