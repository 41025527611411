import React from 'react';
import isMobile from 'is-mobile';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Facebook } from './Icons';

const Wrapper = styled.footer`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 120px;

    box-shadow: 0em -1em 1em rgba(0,0,0,0.2);
    position: relative;
    z-index: 1;

    background-color: ${(props) => props.theme.background};
    font-family: 'Titillium Web';

    padding: 0 5%;
    align-items: center;

    @media (max-width: 1200px) {
        flex-direction: column-reverse;
        height: auto;
        padding-top: 5%;

        @media (max-width: ${(props) => props.theme.mobileLimit}) {
            height: 300px;
            margin-bottom: 55px;
        }

        > * {
            margin-bottom: 1em;
        }
    }
`;

const CompanyInfo = styled.div`
    color: rgb(255,255,255);
    @media (max-width: 1200px) {
        text-align: center;
    }
`;

const Links = styled.div`
    display: flex;

    @media (max-width: 1200px) {
        flex-direction: column;
        text-align: center;
    }
`;

const Link = styled(NavLink)`
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 0em;
    white-space: nowrap;
    color: rgb(255,255,255);
    margin: 0 2em;

    @media (max-width: 1200px) {
        margin-bottom: 1em;
    }

    &.active {
        text-decoration: underline;
    }
`;

const Help = styled.a`
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 0em;
    white-space: nowrap;
    color: rgb(255,255,255);
    margin: 0 2em;

    @media (max-width: 1200px) {
        margin-bottom: 1em;
    }

    &.active {
        text-decoration: underline;
    }
`;

const Social = styled.div`
    width: 150px;
`;

const SocialTitle = styled.p`
    color: rgb(255,255,255);
    margin: 0 0 0.5em 0;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
`;

const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    fill: ${(props) => props.theme.primary};
`;

const Footer = () => {
    return (
        <>
            <Wrapper>
                <CompanyInfo>
                    Palvelun tuottaa: Eventio Group Oy<br />
                    Tierankatu 4 B, 20520 Turku
                </CompanyInfo>
                <Links>
                    <Link to='/ehdot'>Ehdot ja Säännöt</Link>
                    <Link to='/tietosuoja'>Rekisteri ja tietosuojaseloste</Link>
                    <Link to='/evasteet'>Evästeiden käyttö</Link>
                </Links>
                <Help href='#' onClick={ () => {
                    window.Beacon('open');
                }}>Ota yhteyttä</Help>
                <Social>
                    {/*<SocialTitle>Seuraa meitä</SocialTitle>
                       <SocialIcons>
                       <a href='https://fi-fi.facebook.com/lumilyhtyarpa'><Facebook /></a>
                       </SocialIcons>*/}
                </Social>
            </Wrapper>
        </>
    );
};

export { Footer };
