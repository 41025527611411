import styled from 'styled-components';

const Actions = styled.div`
    display: flex;
    padding: 2em 7%;
    height: 150px;

    > * {
        flex: 1;
        margin: 0 1em;
    }

    > *:first-child {
        margin-left: 0;
    }

    > *:last-child {
        margin-right: 0;
    }

    @media (max-width: 700px) {
        height: auto;
    }
`;

export { Actions };
