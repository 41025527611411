import { useEffect } from 'react';
import * as Cookies from 'js-cookie';
import { userManager } from './store';

const Callback = ({ history, children }) => {
    useEffect(() => {
        userManager.signinRedirectCallback()
            .then((user) => {
                Cookies.set('profile', user.profile);
                Cookies.set('id_token', user.id_token);
                Cookies.set('access_token', user.access_token);
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                const returnUrl = window.sessionStorage.getItem('return_url');
                if (returnUrl) {
                    window.location.href = returnUrl;
                } else {
                    history.push('/');
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children || null;
};

export default Callback;
