import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Cookies from 'js-cookie';
import config from '../config';
import { TextField, Button, showMessage } from '../common';
import { Dialog } from '../common/Dialog';

const ChangePasswordButton = styled(Button)`
    width: 75%;
    &:disabled {
        background: gray;
    }
`;

const PasswordChange = ({ open, setOpen }) => {
    const passwordChangeDialogRef = useRef(null);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [changePasswordButtonEnabled, setChangePasswordButtonEnabled] = useState(false);

    //useEffect(() => {
    //    const closeHandler = (event) => {
    //        setOpen(
    //            () => open && passwordChangeDialogRef.current.contains(event.target)
    //        );
    //    };
    //
    //    if(open) {
    //        document.addEventListener('click', closeHandler);
    //        return () => {
    //            document.removeEventListener('click', closeHandler);
    //        };
    //    } else {
    //        setOldPassword('');
    //        setNewPassword1('');
    //        setNewPassword2('');
    //        document.removeEventListener('click', closeHandler);
    //        return () => {};
    //    }
    //}, [open, setOpen]);

    useEffect(() => {
        if(!open) {
            setOldPassword('');
            setNewPassword1('');
            setNewPassword2('');
        }
    }, [open]);

    useEffect(() => {
        if(!oldPassword || !newPassword1 || !newPassword2) {
            setChangePasswordButtonEnabled(false);
        } else if(newPassword1 != newPassword2) {
            setChangePasswordButtonEnabled(false);
        } else {
            setChangePasswordButtonEnabled(true);
        }
    }, [
        setChangePasswordButtonEnabled,
        oldPassword,
        newPassword1,
        newPassword2
    ]);

    return (
        <Dialog ref={passwordChangeDialogRef} open={open}>
            <Dialog.Header>Vaihda salasanasi</Dialog.Header>
            <Dialog.Content>
                <TextField
                    name='oldPassword'
                    type='password'
                    value={oldPassword}
                    label='Vanha salasana'
                    onChange={(event) => setOldPassword(event.target.value)}
                />
                <TextField
                    name='newPassword1'
                    type='password'
                    value={newPassword1}
                    label='Uusi salasana'
                    onChange={(event) => setNewPassword1(event.target.value)}
                />
                <TextField
                    name='newPassword2'
                    type='password'
                    value={newPassword2}
                    label='Toista uusi salasana'
                    onChange={(event) => setNewPassword2(event.target.value)}
                />
            </Dialog.Content>

            <Dialog.Actions>
                <ChangePasswordButton
                    onClick={() => {
                        fetch(
                            `${config.accountAddress}/me/password`, {
                                method: 'PATCH',
                                headers: {
                                    Authorization: `Bearer ${Cookies.get('access_token')}`,
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    current_password: oldPassword,
                                    new_password: newPassword1,
                                })
                            }
                        )
                            .then((response) => {
                                if (!response.ok) {
                                    throw response;
                                }
                                return response;
                            })
                            .then((json) => {
                                showMessage('Salasana vaihdettu');
                                setOpen(false);
                            })
                            .catch((response) => {
                                showMessage('Salasanan vaihto epäonnistui', true);
                            });
                    }}
                    disabled={!changePasswordButtonEnabled}
                >
                    Vaihda
                </ChangePasswordButton>
                <Button onClick={(event) => {
                    event.stopPropagation();
                    setOpen(false);
                }}>
                    Sulje
                </Button>
            </Dialog.Actions>
        </Dialog>
    );
};

export { PasswordChange };
