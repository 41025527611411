import React from 'react';
import styled from 'styled-components';

import { Logo } from '../Logo';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 90px;
    color: white;
    background: ${(props) => props.bg || props.theme.background};
    text-align: center;
    border-radius: 5px 5px 0 0;
    margin: 0;
    padding: 0.5em 1em;
    font-weight: bold;
    font-size: 2em;

    @media (max-width: 700px) {
        justify-content: center;
        border-radius: 0;
    }
`;

const Header = ({ children, ...props }) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export { Header };
