import * as Cookies from 'js-cookie';
import { showMessage } from '../Notifier';
import i18n from '../../i18n';
import config from '../../config';
import { logout } from '../../store/actions';
import { store } from '../../store';

const fetchWrap = (url, options, ignoreStatusCodes) => {
    const init = { ...options };
    if (!init.headers) {
        init.headers = {};
    }
    // Allow browser to determine content-type header of payload is instance of FormData
    if (!init.headers['Content-Type'] && !(init.body instanceof FormData)) {
        init.headers['Content-Type'] = 'application/json';
    }

    if(url.indexOf(config.backendAddress) === 0) {
        const csrfToken = Cookies.get('_csrf');
        if(csrfToken) {
            init.headers.Csrftoken = csrfToken;
        }
        const token = Cookies.get('id_token');
        if (token) {
            init.headers.Authorization = 'Bearer ' + token;
        }
        init.credentials = 'include';
    }

    return fetch(url, init)
        .then((response) => {
            if (!response.ok) {
                throw response;
            }
            return response;
        })
        .catch((response) => {
            if(ignoreStatusCodes && ignoreStatusCodes.indexOf(response.status) !== -1) {
                throw response;
            }

            const contentType = response.headers.get('content-type');
            if (response instanceof Response) {
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    response.json().then((json) => {
                        if (json.code) {
                            if(Array.isArray(json.code)) {
                                showMessage(i18n.gettext(...json.code), true);
                            } else {
                                if (response.status === 401 &&
                                    json.code === 'signature_has_expired') {
                                    logout()(null, store.getState);
                                }

                                showMessage(i18n.gettext(json.code), true);
                            }
                        } else {
                            showMessage(json.description || json.title || `${response.status} ${response.statusText}`, true);
                        }
                    });
                } else {
                    showMessage(`${response.status} ${response.statusText}`, true);
                }
            } else if (response instanceof Error){
                showMessage(response.message, true);
            } else {
                showMessage(`${response.status} ${response.statusText}`, true);
            }
            throw response;
        });
};

export default fetchWrap;
