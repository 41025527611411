import { Form } from './Form';
import { Section, GridSection, SectionContainer } from './Section';
import { SectionTitle } from './SectionTitle';

Form.Section = Section;
Form.GridSection = GridSection;
Form.SectionContainer = SectionContainer;
Form.SectionTitle = SectionTitle;

export { Form };
