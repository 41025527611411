import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

let showMessageFn;

const Container = styled.div`
    display: flex;
    position: fixed;
    align-items: center;
    padding: 1em;
    bottom: ${(props) => props.open ? 0 : '-50px'};
    width: 100%;
    background: ${(props) => props.error ? props.theme.warning : props.theme.success};
    color: white;
    z-index: 1000;
    transition: 0.5s bottom;
    height: 50px;
`;

const Notifier = ({ theme, autoHideDuration }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        let timeout;
        if (open) {
            timeout = setTimeout(() => {
                setOpen(false);
            }, autoHideDuration);
        } else {
            clearTimeout(timeout);
        }
        return () => clearTimeout(timeout);
    }, [open, autoHideDuration]);
    const [message, setMessage] = useState(false);
    const [error, setError] = useState(false);
    showMessageFn = (message, error = false) => {
        setMessage(message);
        setOpen(true);
        setError(error);
    };

    return (
        <Container open={open} error={error}>
            {message}
        </Container>
    );
};

export const showMessage = (value, error = false) => {
    showMessageFn(value, error);
};

Notifier.defaultProps = {
    autoHideDuration: 4000
};

export { Notifier };
