const Theme = {
    primary: 'rgb(142,90,223)',
    primaryDark: 'rgb(51, 0, 102)',
    primaryText: 'white',
    secondary: 'rgb(115,137,255)',
    secondaryDark: 'rgb(90, 100, 230)',
    secondaryText: 'black',
    tertiary: 'rgba(153, 0, 102, 1)',
    tertiaryDark: 'rgba(100, 0, 52, 1)',
    background: 'rgb(56,58,65)',
    backgroundDark: '#2c394c',
    warning: '#dd4a70',
    warningDark: '#7a2a3e',
    warningText: 'white',
    success: '#00b009',
    successDark: '#006e06',
    successText: 'white',
    text: 'white',
    active: 'gray',
    buttonHover: 'black',
    mobileLimit: '800px',
    whitesmoke: 'rgb(245, 245, 245)',
    darkroom: '#36374f',
    backgroundCard: '#e6e6fa'
};

export { Theme };
