import React from 'react';
import styled from 'styled-components';
import { Title } from './Title';
import { Amount } from './Amount';
import { formatCurrency } from '../functions';
import { Button } from '../';

const Balances = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 2em;
    padding-right: 2em;
    height: 100%;
    color: ${(props) => props.theme.text};
    background-color: ${(props) => props.theme.tertiary};

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        height: 9vh;
        font-size: 0.6rem;
        padding: 0 0 0 0;
        width: 100%;
        padding-bottom: 0.5em;
        justify-content: space-evenly;
    }
`;

const Separator = styled.div`
    margin-right: 2em;
    font-weight: 900;

    &:before {
        content: '|';
    }
`;

const AddSaldoButton = styled(Button)`
    background-color: ${(props) => props.theme.secondaryDark};
    height: 3em;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        height: 2em;
        width: 55%;
        min-width: 100px;
        max-width: 200px;
        font-size: 1.2rem;
    }
`;

const BalanceItem = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

const BalanceItemInner = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        display: inline;
    }
`;


const BalancesContainer = ({ history, value1, value2, titleText1, titleText2, hideAddSaldoButton }) => {
    return (
        <Balances>
            <BalanceItem>
                <BalanceItemInner>
                    <Title>{titleText1 || 'Saldosi'}:&nbsp;</Title>
                    <Amount>{formatCurrency(value1)} €</Amount>
                </BalanceItemInner>
                {value2 ? (
                    <BalanceItemInner>
                        <Title>{titleText2 || 'Saldosi'}:&nbsp;</Title>
                        <Amount>{formatCurrency(value2)} €</Amount>
                    </BalanceItemInner>
                ) : null}
            </BalanceItem>
            {!hideAddSaldoButton && (
                <AddSaldoButton onClick={
                    () => {
                        history.push('/tili/lisaa-saldoa');
                    }}
                >
                    Lisää saldoa
                </AddSaldoButton>
            )}
        </Balances>
    );
};

export { BalancesContainer as Balances };
