import styled from 'styled-components';

const TopContainer = styled.div`
    display: flex;
    flex-flow: row;
    min-height: calc(100vh - 200px);

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        flex-flow: column;
    }
`;

export { TopContainer };
