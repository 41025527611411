import queryString from 'query-string';
import * as Cookies from 'js-cookie';

const ALLOWED = [
    {
        key: 'unelma-arpa-pelihuone',
        url: 'https://unelma-arpa.fi/pelihuone',
        name: 'Palaa Pelihuoneeseen'
    },
    {
        key: 'dev-unelma-arpa-pelaamo',
        url: 'https://dev.unelma-arpa.fi/pelaamo',
        name: 'Palaa Pelihuoneeseen'
    },
    {
        key: 'voittokauppa',
        url: 'https://dreamshop.arpamaailma.fi',
        name: 'Palaa Voittokauppaan'
    },
    {
        key: 'dev-voittokauppa',
        url: 'https://dreamshop.dev.arpamaailma.fi',
        name: 'Palaa Voittokauppaan'
    },
    {
        key: 'local-voittokauppa',
        url: 'http://localhost:8050',
        name: 'Palaa Voittokauppaan'
    },
];

const getIncomingFrom = (location) => {
    const { incoming_from } = queryString.parse(location.search);
    const { automatic_login } = queryString.parse(location.search);
    const { campaign } = queryString.parse(location.search);

    if(campaign) {
        Cookies.set('am_campaign', campaign);
    }

    const foundItem = ALLOWED.find((item) => item.key === incoming_from);

    if(foundItem) {
        sessionStorage.setItem('incoming_from', incoming_from);
        if(automatic_login) {
            sessionStorage.setItem('outgoing_automatic_login', automatic_login);
        }
        return incoming_from;
    }

    return sessionStorage.getItem('incoming_from');
};

const getURLForIncomingFrom = (removeItem = true, forceAutomaticLogin = false) => {
    const incomingFrom = sessionStorage.getItem('incoming_from');
    const outgoingAutomaticLogin = sessionStorage.getItem('outgoing_automatic_login');
    if(removeItem) {
        sessionStorage.removeItem('incoming_from');
        sessionStorage.removeItem('outgoing_automatic_login');
    }

    const foundItem = ALLOWED.find((item) => item.key === incomingFrom);
    if (foundItem) {
        let url = foundItem.url;
        if(outgoingAutomaticLogin || forceAutomaticLogin) {
            url += '?automatic_login=1';
        }
        return url;
    }

    return null;
};

const getNameForIncomingFrom = () => {
    const incomingFrom = sessionStorage.getItem('incoming_from');
    const foundItem = ALLOWED.find((item) => item.key === incomingFrom);
    return foundItem ? foundItem.name : null;
};

export { getIncomingFrom, getURLForIncomingFrom, getNameForIncomingFrom };
