import React from 'react';
import styled from 'styled-components';

import { Header } from './Header';
import { Drawer } from './Drawer';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`;

const Desktop = styled.div`
    @media (max-width: 700px) {
        display: none;
    }
`;

const Mobile = styled.div`
    @media (min-width: 701px) {
        display: none;
    }
`;

const Layout = ({ children, ...props }) => {
    return (
        <Wrapper {...props}>
            <Header />
            {children}
            <Drawer />
        </Wrapper>
    );
};

Layout.Desktop = Desktop;
Layout.Mobile = Mobile;

export { Layout };
