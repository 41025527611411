import styled from 'styled-components';

const Product = styled.div`
    color: white;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    font-size: 1.5em;
    font-weight: bold;
    text-shadow: 2px 2px 10px black;
`;

export { Product };
