import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../store/actions';
import { Spinner, Page, ItemPage } from '../common';
import { useFetch } from '../common/hooks';
import { Home } from './Step1Home';
import { EnterNumbers } from './Step2EnterNumbers';
import { Prize } from './Step3Prize';
import { RedeemForeignUser } from './PrizeCodeStep1';
import { RedeemLoggedUser } from './PrizeCodeStep2';
import { Redeem } from './Step4Redeem';
import { Back } from '../common/Icons';
import { Balances } from '../common/Balance';
import config from '../config';
import { getImageValue, getShopUrl } from '../common/functions';

const Title = styled(Page.Title)`
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        display: none;
    }
`;

const PageTitleAndBalance = styled.div`
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        height: 9vh;
        font-size: 0.9rem;
        display: inline;
        align-items: flex-start;
    }
`;

const Lottery = ({
    history,
    location,
    app: { cms },
    match: { params: { slug } },
    user,
    user: { accounts, authenticated },
    userActions: { login, refreshAccounts }
}) => {
    const [prize, setPrize] = useState(null);
    const [prizeCode, setPrizeCode] = useState(null);
    const [option, setOption] = useState(null);
    const [step, setStep] = useState(0);
    const [totalSaldo, setTotalSaldo] = useState(0);
    const [{ data: lottery, loading }] = useFetch(`${config.backendAddress}/api/v1/lotteries/by-slug/${slug}`);

    const [bottomDesription, setBottomDescription] = useState(null);

    const beacon = document.getElementById('beacon-container');
    if(beacon) {
        beacon.hidden = true;
    }

    useEffect(() => {
        const winsAccountsSaldo = accounts.reduce((acc, cur) => {
            // Include wins, shop currency, extra wins
            if(['wins', 'bonus', 'extra_wins'].indexOf(cur.variety) !== -1) {
                return acc + cur.saldo;
            }
            return acc;
        }, 0);
        const saldo = winsAccountsSaldo;
        setTotalSaldo(saldo);
    }, [accounts]);

    useEffect(() => {
        return () => {
            const beacon = document.getElementById('beacon-container');
            if(beacon) {
                beacon.hidden = false;
            }
        };
    }, []);

    useEffect(() => {
        if(!slug || !cms) return;
        const index = cms.claimable_lotteries.findIndex((item) => item.slug === slug);
        if(index === -1) return;
        setBottomDescription(cms.claimable_lotteries[index]['bottom_description']);
    }, [slug, cms]);

    return (
        <>
            {step === 2 && (
                <Redeem
                    lottery={lottery}
                    user={user}
                    prize={prize}
                    option={option}
                    onClaim={() => history.push('/success-redeem')}
                    onClaimExtra={() => history.push('/success-extra')}
                />
            )}
            {step !== 2 && (
                <Page>
                    <Switch>
                        <Route path='/lunastettavat/:slug' exact render={() =>
                            <PageTitleAndBalance>
                                <Title>Lunasta</Title>
                                {lottery && lottery.show_balance && (
                                    <Balances
                                        history={history}
                                        titleText1='Voittosaldosi'
                                        value1={totalSaldo}
                                        hideAddSaldoButton={true}
                                    />
                                )}
                            </PageTitleAndBalance>
                        } />
                        <Route path='/lunastettavat/:slug/nettilunastus' render={() =>
                            <Page.Title>{step === 0 ? 'Tarkistusnumerot' : prize && prize.product_id ? 'Onneksi olkoon!' : 'Ei voittoa'}</Page.Title>
                        } />
                    </Switch>
                    <Route path='/lunastettavat/:slug/nettilunastus' render={() => (
                        <>
                            {step === 0 && <Page.BackButton onClick={() => history.push(`/lunastettavat/${slug}`)}><Back />Takaisin</Page.BackButton>}
                        </>
                    )} />
                    <Page.Content flex>
                        {!loading && lottery && (
                            <ItemPage>
                                <ItemPage.Image src={getImageValue(lottery.images, 'large')} />
                                <ItemPage.Content>
                                    <Switch>
                                        <Route path='/lunastettavat/:slug' exact render={() => (
                                            <Home
                                                lottery={lottery}
                                                authenticated={user.authenticated}
                                                onLogin={() => login()}
                                                onRedeem={() => {
                                                    setStep(0);
                                                    history.push(`/lunastettavat/${slug}/nettilunastus`);
                                                }} />
                                        )} />
                                        <Route path='/lunastettavat/:slug/nettilunastus' render={() => (
                                            <>
                                                {step === 0 && (
                                                    <EnterNumbers
                                                        lottery={lottery}
                                                        onContinue={(newPrize) => {
                                                            if (user.authenticated) {
                                                                refreshAccounts();
                                                            }
                                                            setPrize(newPrize);
                                                            if (newPrize?.prize_code) {
                                                                if (user.authenticated) {
                                                                    setStep(4);
                                                                } else {
                                                                    setStep(3);
                                                                }
                                                            } else {
                                                                setStep(1);
                                                            }
                                                        }}
                                                    />
                                                )}
                                                {step === 1 && (
                                                    <Prize
                                                        lottery={lottery}
                                                        prize={prize}
                                                        option={option}
                                                        authenticated={user.authenticated}
                                                        onChangeOption={(option) => setOption(option)}
                                                        onRedeemPrize={() => setStep(2)}
                                                        onSelectPrize={() => {
                                                            history.push('/voittokauppa');
                                                        }}
                                                        onReturn={() => history.push('/')}
                                                        onEnterNew={() => setStep(0)}
                                                    />
                                                )}
                                                {step === 3 && (
                                                    <RedeemForeignUser
                                                        lottery={lottery}
                                                        onSend={() => setStep(4)}
                                                    />
                                                )}
                                                {step === 4 && (
                                                    <RedeemLoggedUser onEnterNew={() => setStep(0)} />
                                                )}
                                            </>
                                        )} />
                                    </Switch>
                                </ItemPage.Content>
                            </ItemPage>
                        )}
                        {loading && <Spinner />}
                    </Page.Content>

                    {location.pathname === `/lunastettavat/${slug}` && bottomDesription &&
                     <div
                         style={{ flex: 1, margin: 0 }}
                         dangerouslySetInnerHTML={{
                             __html: bottomDesription
                         }}
                     >
                     </div>
                    }
                </Page>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ConnectedLottery = connect(mapStateToProps, mapDispatchToProps)(Lottery);

export { ConnectedLottery as Lottery };
