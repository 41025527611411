import styled from 'styled-components';

const Amount = styled.span`
    font-weight: 600;
    font-size: 2em;
    margin-right: 1em;
    min-width: 3em;
    text-align: center;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
         margin-right: 0;
         text-align: right;
    }
`;

export { Amount };
