import styled from 'styled-components';
import { Button } from './Button';

const Confirm = styled(Button)`
    background: ${(props) => props.theme.success};
    border: 3px ${(props) => props.theme.success} solid;
    color: ${(props) => props.theme.successText};

    :hover {
        background: ${(props) => props.theme.successDark};
        border-color: ${(props) => props.theme.successDark};
    }
`;

export { Confirm };
