import { Page } from './Page';
import { Content } from './Content';
import { Title } from './Title';
import { BackButton } from './BackButton';
import { Drawer } from './Drawer';

Page.Title = Title;
Page.BackButton = BackButton;
Page.Content = Content;
Page.Drawer = Drawer;

export { Page };
