import React from 'react';
import { Button, Form, TextField, TripletTextField, ItemPage } from '../common';
import { getLangValue } from '../common/functions';
import config from '../config';


const getSubTitle = (lottery) => {
    if(lottery.tags?.indexOf('internal.nuorelle-siivet.lottery') !== -1) {
        return 'Syötä arvasta löytyvä internetkoodi:';
    }

    if(lottery.codes_check_type !== 2) {
        return 'Syötä arvasta löytyvät tarkistusnumerot.';
    }

    return 'Syötä arvasta löytyvät tarkistusnumero.';
};

const EnterNumbers = ({ lottery, onContinue, onBack, drawerOpen, onDrawerOpen, onDrawerClose }) => {
    return (
        <>
            <ItemPage.Title>{getLangValue(lottery.names, 'fi')}</ItemPage.Title>
            <ItemPage.Description>
                <ItemPage.Text mobileCenter>
                    { getSubTitle(lottery) }
                </ItemPage.Text>
                <Form action={`${config.backendAddress}/api/v1/lotteries/${lottery.id}/claims/`} id='numberForm' withSpinner onResponse={(prize) => onContinue(prize)}>
                    <Form.Section>
                        {lottery.codes_check_type === 2 &&
                         <TripletTextField
                             name='code_b'
                             label='Tarkistusnumero'
                             autoComplete='off'
                             required
                             style={{ width: '100%', maxWidth: 400 }} />
                        }
                        {lottery.codes_check_type !== 2 && <TextField type='tel' name='code_a' label='Tarkistusnumero 1' autoComplete='off' required style={{ width: '100%', maxWidth: 400 }} />}
                        {lottery.codes_check_type === 4 && <TextField type='tel' name='code_b' label='Tarkistusnumero 2' required autoComplete='off' style={{ width: '100%', maxWidth: 400 }} />}
                    </Form.Section>
                </Form>
            </ItemPage.Description>
            <ItemPage.Actions>
                <Button type='submit' form='numberForm'>Jatka</Button>
            </ItemPage.Actions>
        </>
    );
};

export { EnterNumbers };
