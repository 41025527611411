import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Wrapper = styled.div`
    display: inline-block;
    position: absolute;
    width: 164px;
    height: 164px;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0);
`;

const FixedWrapper = styled.div`
    position: fixed;
    width: 164px;
    height: 164px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
`;

const Ring = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 151px;
    height: 151px;
    margin: 6px;
    border: 16px solid #fff;
    border-radius: 50%;
    animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.theme.secondary} transparent transparent transparent;
`;

const FixedRing = styled.div`
    box-sizing: border-box;
    position: fixed;
    width: 151px;
    height: 151px;
    margin: 6px;
    border: 16px solid #fff;
    border-radius: 50%;
    animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.theme.secondary} transparent transparent transparent;
`;

const Ring1 = Ring;

const Ring2 = styled(Ring)`
    animation-delay: -0.45s;
`;

const Ring3 = styled(Ring)`
    animation-delay: -0.3s;
`;

const Ring4 = styled(Ring)`
    animation-delay: -0.15s;
`;

const FixedRing1 = FixedRing;

const FixedRing2 = styled(FixedRing)`
    animation-delay: -0.45s;
`;

const FixedRing3 = styled(FixedRing)`
    animation-delay: -0.3s;
`;

const FixedRing4 = styled(FixedRing)`
    animation-delay: -0.15s;
`;

const Spinner = (props) => {
    return (
        <Wrapper {...props}>
            <Ring1 />
            <Ring2 />
            <Ring3 />
            <Ring4 />
        </Wrapper>
    );
};

const FixedSpinner = (props) => {
    return (
        <FixedWrapper {...props}>
            <FixedRing1 />
            <FixedRing2 />
            <FixedRing3 />
            <FixedRing4 />
        </FixedWrapper>
    );
};

export { FixedSpinner, Spinner };
