import React from 'react';
import { Group } from './Group';
import { Input } from './Input';
import { Label } from './Label';

const Checkbox = ({ label, id, onChange, ...props }) => {
    return (
        <Group {...props}>
            <Input id={id} onChange={onChange} {...props} />
            <Label labelColor={props.labelColor} htmlFor={id}>{label}</Label>
        </Group>
    );
};

export { Checkbox };
