import styled from 'styled-components';

const Section = styled.div`
    flex: 1;
`;

const GridSection = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.gridTemplateColumns};
    align-items: center;
`;

const SectionContainer = styled.div`
    display: ${(props) => props.display || 'flex'};

    @media (max-width: 1200px) {
        display: ${(props) => props.smallDisplay || 'block'};
        > * {
            margin: 0;
        }
    }
`;

export { Section, GridSection, SectionContainer };
