import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    background: ${(props) => props.theme.text};
    display: flex;
    justify-content: center;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    width: ${(props) => `${props.children.filter((item) => item !== false).length * 180}px`}
    height: 100px;
    padding: 0.5em 5em;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 10.5em);
        border-bottom: 5px ${(props) => props.theme.primary} solid;
        margin: 0.5em 5em;
        height: 30px;
    }

    @media (max-width: 700px) {
        height: 80px;
        padding: 0.5em 1em;

        &:before {
            height: 25px;
            margin: 0.3em 1em;
            width: calc(100% - 2em);
        }
    }
`;

const Stepper = ({ children, value, ...props }) => {
    const activeChildren = children.filter((item) => item !== false);
    const childrenWithProps = [];
    for (let i = 0; i < activeChildren.length; i++) {
        let status;
        if (i < value) {
            status = 'done';
        } else if (i > value) {
            status = 'pending';
        } else {
            status = 'active';
        }
        childrenWithProps.push(React.cloneElement(activeChildren[i], { status , key: i }));
    }
    return (
        <Wrapper {...props}>
            <Container>{childrenWithProps}</Container>
        </Wrapper>
    );
};

export { Stepper };
