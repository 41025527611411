import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Page, Button, Footer } from '../common';
import * as userActions from '../store/actions';

const Message = styled.div`
    color: ${(props) => props.theme.secondaryText};

    font-weight: 400;
    font-size: 1.5em;
    text-align: center;

    margin-top: 2em;
`;

const Thanks = ({ history, userActions: { clearCart, saveShopCart } }) => {
    useEffect(() => {
        clearCart();
        saveShopCart();
    }, []);

    return (
        <Page>
            <Page.Title>Lunastettu</Page.Title>
            <Page.Content flex style={{ overflowX: 'hidden' }}>
                <div style={{ height: 'calc(100% - 120px)' }}>
                    <Message>Kiitos tilauksestasi!</Message>
                    <Button onClick={() => history.push('/')} style={{ margin: '2em auto 0 auto', width: 250, height: 80 }}>Takaisin etusivulle</Button>
                </div>
                <Footer />
            </Page.Content>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ConnectedThanks = connect(mapStateToProps, mapDispatchToProps)(Thanks);

export { ConnectedThanks as Thanks };
