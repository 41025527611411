import styled from 'styled-components';

const Description = styled.div`
    color: ${(props) => props.theme.secondaryText}
    white-space: pre-wrap;

    font-weight: 400;

    @media (max-width: 700px) {
        flex: 1
    }
`;

export { Description };
