import styled from 'styled-components';

import { Button } from '../';

const Actions = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 85px;

    > ${Button} {
        margin-left: 2em;
        border: 2px ${(props) => props.theme.primary} solid;
    }
`;

const ActionsMobile = styled(Actions)`
    @media (min-width: ${(props) => props.theme.mobileLimit}) {
        display: none;
    }
`;

export { Actions, ActionsMobile };
