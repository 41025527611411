import styled from 'styled-components';

const Footer = styled.div`
    display: block;
    text-align: center;
    color: ${(props) => props.theme.secondary};
    font-weight: 500;
    padding: 1em;
    border-radius: 0 0 5px 5px;
    background: ${(props) => props.theme.background};
`;

export { Footer };
