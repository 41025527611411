import styled from 'styled-components';

const Title = styled.div`
    text-transform: uppercase;
    color: 'white';
    font-weight: bold;
    letter-spacing: 0.1em;
    cursor: pointer;

`;

export { Title };
