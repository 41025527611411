import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createOidcMiddleware, { createUserManager } from 'redux-oidc';
import * as Cookies from 'js-cookie';
import { reducer as userReducer, appReducer } from './reducer';
import config from '../config';

const userManager = createUserManager(config.oauth2_settings);
userManager.events.addAccessTokenExpiring(() => {
    userManager.signinSilent()
        .then((user) => {
            Cookies.set('profile', user.profile);
            Cookies.set('id_token', user.id_token);
            Cookies.set('access_token', user.access_token);
        })
        .catch((error) => {
            console.error('Failed to refresh token. ' + error);
        });
});

const oidcMiddleware = createOidcMiddleware(userManager, () => true, false, '/callback');

const middlewares = [oidcMiddleware, thunk];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(reduxImmutableStateInvariant());
}

const rootReducer = combineReducers({
    user: userReducer,
    app: appReducer,
});

const store = createStore(rootReducer, {}, applyMiddleware(...middlewares));

const profile = Cookies.get('profile');
if (profile) {
    store.dispatch({ type: 'UPDATE_USER', payload: JSON.parse(profile) });
}
setTimeout(() => {
    const profile = Cookies.get('profile');
    if (profile) {
        store.dispatch({ type: 'UPDATE_USER', payload: JSON.parse(profile) });
    }
}, 1000);

export { store, userManager };
