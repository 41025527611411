import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';

import {
    Button,
    Cart,
    Footer,
    Page,
    PrizeCard,
    RangeSlider,
    Select,
    Spinner,
    Splitter,
    Toolbar,
} from '../common';

import {
    Balance,
    BalanceMobileActions,
} from '../common/Balance';

import { useFetch } from '../common/hooks';
import { stableSort, getLangValue, getImageValue, formatCurrency } from '../common/functions';
import config from '../config';

const Grid = styled.div`
    display: grid;
    width: 100%;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    padding: 1em 5%;
    margin: 2em auto;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        grid-template-columns: 1fr;
    }
`;

const FilterToolbar = styled(Toolbar)`
    flex-direction: column;
`;

const DesktopFilterToolbar = styled(FilterToolbar)`
    min-height: 10em;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        display: none;
    }
`;

const MobileFilterToolbar = styled(FilterToolbar)`
    height: auto;

    @media (min-width: ${(props) => props.theme.mobileLimit}) {
        display: none;
    }
`;

const Filters = styled.div`
    display: flex;
    width: 100%;
    flex-grow: 1;

    > * {
    }

    > *:first-child {
        margin-left: 0;
    }

    > *:last-child {
        margin-right: 0;
    }
`;

const DesktopFilters = styled(Filters)`
    align-items: center;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        display: none;
    }
`;

const MobileFilters = styled(Filters)`
    flex-direction: column;
    align-items: center;

    > * {
        margin: 0 0 1em 0;
    }

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        display: flex;
    }

    @media (min-width: ${(props) => props.theme.mobileLimit}) {
        display: none;
    }
`;

const StepButton = styled(Button)`
    display: inline;
    margin: 3px;

    background: ${(props) => props.bg || 'rgba(153, 0, 102, 1)'};
    color: ${(props) => props.color || 'white'};

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        min-width: 150px;
        height: 50px;
    }

    :hover {
        background: ${(props) => props.theme.buttonHover};
        color: ${(props) => props.theme.text};
    }

    :disabled {
        background: gray;
    }
`;

const StepButtonContainer = styled.div`
    text-align: center;
    background-color: white;
`;

const filterDuplicates = (list) => {
    const newList = [];
    for (const item of list) {
        if (newList.find((existing) => existing.id === item.id) == null) {
            newList.push(item);
        }
    }
    return newList;
};

const filterWithCategory = (list, category) => {
    return list.filter((item) => {
        return category === 'all' || item.tags.find((tag) => tag.id === category) != null;
    });
};

const filterWithRange = (list, min, max) => {
    return list.filter((item) => {
        return item.value >= min && item.value <= max;
    });
};

const findMin = (list) => {
    let min = 1000000;
    for (const item of list) {
        if (item.value < min) {
            min = item.value;
        }
    }
    return min;
};

const findMax = (list) => {
    let max = 0;
    for (const item of list) {
        if (item.value > max) {
            max = item.value;
        }
    }
    return max;
};

const Title = styled(Page.Title)`
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        display: none;
    }
`;

const Categories = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        display: none;
    }
`;

const Category = styled.div`
    flex: 1 200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 100;
    font-size: 1.1em;
    user-select: none;

    background-color: ${(props) => props.selected ? props.theme.secondaryDark: 'white'};
    color: ${(props) => props.selected ? 'white': 'black'};
`;
const Shop = ({
    history,
    user: { authenticated, accounts, cart },
}) => {
    const [balance, setBalance] = useState(null);
    const [orderBy, setOrderBy] = useState('name');
    const [direction, setDirection] = useState('asc');
    const [category, setCategory] = useState('all');
    const [minLimit, setMinLimit] = useState(0);
    const [maxLimit, setMaxLimit] = useState(1000000);
    const [page, setPage] = useState(1);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1000000);
    const [filterOpen, setFilterOpen] = useState(false);
    const [{ data: products, loading }, setUrl] = useFetch();

    useEffect(() => {
        const winsAccountsSaldo = accounts.reduce((acc, cur) => {
            // Include wins, shop currency, extra wins
            if(['wins', 'bonus', 'extra_wins'].indexOf(cur.variety) !== -1) {
                return acc + cur.saldo;
            }
            return acc;
        }, 0);
        const saldo = winsAccountsSaldo;
        setBalance(saldo);
    }, [accounts]);

    useEffect(() => {
        if (products && products.data && products.data.length > 0) {
            const min = findMin(products.data);
            const max = findMax(products.data);
            setMinLimit(min);
            setMaxLimit(max);
            setMin(min);
            setMax(max);
        }
    }, [category, page, products]);

    useEffect(() => {
        if(category === 'all') {
            setUrl(
                `${config.backendAddress}/api/v1/shop/products?page=${page}&sort=${direction === 'desc'? '-': ''}${orderBy}`
            );
        } else {
            setUrl(`${config.backendAddress}/api/v1/shop/products?page=${page}&sort=${direction === 'desc'? '-': ''}${orderBy}&category_id=${category}`);
        }
    }, [orderBy, direction, category, page]);

    const [{ data: categoriesData }] = useFetch(`${config.backendAddress}/api/v1/shop/categories`);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if(categoriesData && categoriesData.data ) {
            setCategories(categoriesData.data.filter((category) => category.default_template === 'product-list'));
        }
    }, [categoriesData]);

    return (
        <Page>
            <DesktopFilterToolbar className='filter-toolbar' open={filterOpen}>

                <DesktopFilters className='desktop-filters'>
                    {/*<Select
                        name='category'
                        label='Kategoria'
                        options={[{ label: 'Kaikki', value: 'all' }, ...categories.map((tag) => {
                            return { label: getLangValue(tag.names, 'fi'), value: tag.id };
                        })]}
                        onChange={(item) => {
                            setPage(1);
                            setCategory(item.value);
                        }}
                        style={{ textAlign: 'center' }}
                        />*/}
                    {/*<Select
                        name='filter'
                        label='Lajittelu'
                        options={[
                            { label: 'Oletustapa', value: { orderBy: 'id', direction: 'asc' } },
                            { label: 'Halvin ensin', value: { orderBy: 'price', direction: 'asc' } },
                            { label: 'Kallein ensin', value: { orderBy: 'price', direction: 'desc' } }
                        ]}
                        onChange={(item) => {
                            setOrderBy(item.value.orderBy);
                            setDirection(item.value.direction);
                        }}
                        style={{ textAlign: 'center' }}
                        />*/}
                    {/*<RangeSlider
                        min={minLimit}
                        max={maxLimit}
                        label='Hinta'
                        formatMinMax={(value) => `${formatCurrency(value)} €`}
                        onChange={(values) => {
                            setMin(values[0]);
                            setMax(values[1]);
                        }}
                        style={{
                            minWidth: '180px',
                            textAlign: 'center',
                            width: 250
                        }}
                        />*/}
                    <Title height='100%'>Voittokauppa</Title>
                    <Splitter />
                    {authenticated && (
                        <Cart
                            items={cart.items}
                            reverse
                            onCartClick={() => history.push('/ostoskori')}
                            style={{ marginLeft: '40px', marginRight: '60px' }}
                        />
                    )}
                    <Splitter />
                    {authenticated && <Balance value={balance} />}
                </DesktopFilters>

                <Categories>
                    {[
                        <Category selected={category === 'all'} onClick={() => setCategory('all')}>Kaikki</Category>, ...categories?.map((tag) => (
                            <Category selected={category === tag.id} onClick={() => {
                                setPage(1);
                                setCategory(tag.id);
                            }}>
                                {getLangValue(tag.names, 'fi')}
                            </Category>
                        ))]}
                </Categories>

            </DesktopFilterToolbar>
            <Page.Content style={{ backgroundColor: '#36374f' }}>
                <MobileFilterToolbar className='filter-toolbar' open={filterOpen}>
                    {authenticated &&
                     <BalanceMobileActions style={{ minHeight: '2em' }}>
                         <Splitter />
                         <Cart
                             items={cart.items}
                             reverse
                             onCartClick={() => history.push('/ostoskori')}
                         />
                         <Splitter />
                         <Balance value={balance}/>
                     </BalanceMobileActions>}

                    <MobileFilters style={{ minHeight: '8em' }} className='mobile-filters'>
                        <Select
                            name='category'
                            label='Kategoria:'
                            options={[{ label: 'Kaikki', value: 'all' }, ...categories.map((tag) => {
                                return { label: getLangValue(tag.names, 'fi'), value: tag.id };
                            })]}
                            onChange={(item) => {
                                setPage(1);
                                setCategory(item.value);
                            }}
                        />
                        {/*<Select
                           name='filter'
                           label='Lajittelu:'
                           options={[
                           { label: 'Oletustapa', value: { orderBy: 'names', direction: 'asc' } },
                           { label: 'Halvin ensin', value: { orderBy: 'value', direction: 'asc' } },
                           { label: 'Kallein ensin', value: { orderBy: 'value', direction: 'desc' } }
                           ]}
                           onChange={(item) => {
                           setOrderBy(item.value.orderBy);
                           setDirection(item.value.direction);
                           }}
                           />*/}
                        {/*<RangeSlider
                           min={minLimit}
                           max={maxLimit}
                           label='Hinta:'
                           formatMinMax={(value) => `${formatCurrency(value)} €`}
                           style={{ width: 250 }}
                           onChange={(values) => {
                           setMin(values[0]);
                           setMax(values[1]);
                           }}/>*/}
                    </MobileFilters>
                </MobileFilterToolbar>
                <div style={{ display: 'grid', alignItems: 'end', minHeight: 'calc(100vh - 360px)' }}>
                    <div style={{ height: '100%' }}>
                        <Grid>
                            <>
                                {!loading &&
                                 products &&
                                 products.data.filter((item) => !item.hidden).map((product) => {
                                     return (
                                         <PrizeCard key={product.id}>
                                             <PrizeCard.Image
                                                 style={{
                                                     width: '20em',
                                                 }}
                                                 src={getImageValue(product.images, 'small')}
                                             />

                                             <PrizeCard.PrizeValue
                                                 style={{
                                                     fontSize: '2em'
                                                 }}
                                             >{formatCurrency(product.value)} €
                                             </PrizeCard.PrizeValue>

                                             <PrizeCard.Title>
                                                 {`${product.custom_id ? `${product.custom_id}. ` : ''}${getLangValue(product.names, 'fi')}`}
                                             </PrizeCard.Title>

                                             <PrizeCard.Button
                                                 onClick={
                                                     () => history.push(
                                                         `/voittokauppa/${product.id}`
                                                     )
                                                 }
                                             >
                                                 Valitse
                                             </PrizeCard.Button>
                                         </PrizeCard>
                                     );
                                 })}
                                {loading && <Spinner />}
                            </>
                        </Grid>
                    </div>

                    {products && products.meta && products.meta.page_count > 1 &&
                     <StepButtonContainer>
                         <StepButton
                             key='step_prev'
                             disabled={!(page > 1 && page <= products.meta.page_count)}
                             onClick={() => setPage(page-1)}
                         >
                             Edellinen
                         </StepButton>

                         {page} / {products && products.meta && products.meta.page_count}

                         <StepButton
                             key='step_next'
                             disabled={!(products.meta.page_count > page)}
                             onClick={() => setPage(page+1)}
                         >
                             Seuraava
                         </StepButton>
                     </StepButtonContainer>
                    }

                </div>

                <Footer />
            </Page.Content>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const ConnectedShop = connect(mapStateToProps, mapDispatchToProps)(Shop);

export { ConnectedShop as Shop };
