import styled from 'styled-components';

const Content = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    width: 500px;

    @media (max-width: 700px) {
        left: 0;
        width: 100%;
    }
`;

export { Content };
