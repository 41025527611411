import React from 'react';
import styled from 'styled-components';
import { StepLabel } from './StepLabel';
import { Svg } from '../Icons/Svg';

const Container = styled.div`
    position: relative;
    height: 100%;

    > ${Svg} {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 3px ${(props) => props.theme.primary} solid;
        margin: auto;
        margin-bottom: 0.1em;
        padding: 5px;

        @media (max-width: 700px) {
            width: 45px;
            height: 45px;
        }
    }

    ${(props) => props.status === 'done' && `
        ${Svg} {
            background-color: ${props.theme.primary};
            fill: ${props.theme.background}
        }
    `}

    ${(props) => props.status === 'active' && `
        ${Svg} {
            background-color: ${props.theme.warning};
            border-color: ${props.theme.primary};
            fill: ${props.theme.text};
        }
    `}

    ${(props) => props.status === 'pending' && `
        ${Svg} {
            background-color: ${props.theme.text};
            fill: ${props.theme.primary}
        }
    `}
`;

const Step = ({ children, icon, label, ...props }) => {
    return (
        <Container {...props}>
            {icon}
            <StepLabel>{label}</StepLabel>
        </Container>
    );
};

export { Step };
