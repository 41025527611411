import React from 'react';
import moment from 'moment';
import { IncrementalList } from '../common';
import SubsectionHeader from '../common/AccountSubsectionHeader';
import { formatCurrency } from '../common/functions';
import config from '../config';
import i18n from '../i18n';

const getItemCmp = (item) => {

    const value = (item.variety === 'withdraw') ? item.value * -1 : item.value;
    return ([
        <td key='variety'>{i18n.gettext(item.variety)}</td>,
        <td key='state'>{i18n.gettext(item.state)}</td>,
        <td key='value'>{formatCurrency(value)} €</td>,
        <td key='timestamp'>{moment.utc(item.timestamp).local().format('DD.MM.YYYY HH:mm')}</td>
    ]);
};

const getListHeader = () => {
    return (
        <>
            <th key='headerVariety'>{i18n.gettext('variety')}</th>
            <th key='headerState'>{i18n.gettext('state')}</th>
            <th key='headerValue'>{i18n.gettext('value')}</th>
            <th key='headerTimestamp'>{i18n.gettext('timestamp')}</th>
        </>
    );
};


const AccountEvents = () => {
    return (
        <>
            <SubsectionHeader sectionTitle='Tilitapahtumat'/>
            <IncrementalList
                url={`${config.backendAddress}/api/v1/account-events`}
                getItemCmp={getItemCmp}
                getListHeader={getListHeader}
                noItemsMessage='Ei tapahtumia'
            />
        </>
    );
};

export { AccountEvents };
