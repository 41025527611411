import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Cookies from 'js-cookie';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../store/actions';
import { StronglyAuthenticatedInfo, Form, PostalCodeField, TextField, Checkbox,  Button, showMessage, SubscriptionInfo } from '../common';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';
import { PasswordChange } from './PasswordChange';

const SubmitButton = styled(Button)`
    margin: 0 auto;

    background: ${(props) => props.bg || 'rgba(153, 0, 102, 1)'};
    color: ${(props) => props.color || 'white'};

    :hover {
        background: ${(props) => props.theme.buttonHover};
        color: ${(props) => props.theme.text};
    }

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        width: 100%;
        margin: 5rem 0 3rem 0;
    }
`;

const PasswordChangeDialogOpenButton = styled(Button)`
    background: white;
    border: 2px solid gray;
    color: black;
    height: 2em;
    margin: 2em 3em 0;
    float: right;

    :hover {
        background: black;
        color: white;
        border: 2px solid black;
    }
`;

const SectionTitle = {
    background: 'white',
    color: 'black',
    height: '2em',
    margin: '0.7em 5em 0 2em',
    float: 'left',
    fontSize: '2em'
};

const NameTextField = styled(TextField)`
    input {
        color: ${(props) => props.disabled ? '#c0c0c0' : props.color || 'black'}
    }
`;

const Info = ({ history, user, location: { search }, userActions: { login, updateUser, refreshAccounts } }) => {
    const [firstname, setFirstname] = useState(user.first_name);
    const [lastname, setLastname] = useState(user.last_name);
    const [address, setAddress] = useState(user.address ? user.address.street_address : '');
    const [postalCode, setPostalCode] = useState(user.address ? user.address.postal_code : '');
    const [city, setCity] = useState(user.address ? user.address.city : '');
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone_number || '');
    const [marketingAllowed, setMarketingAllowed] = useState(user.marketing_permission || false);
    const [passwordChangeDialogOpen, setPasswordChangeDialogOpen] = useState(false);
    const stronglyAuthenticated = user.strongly_authenticated;
    const params = new URLSearchParams(search);

    useEffect(() => {
        const auth = params.get('auth');
        const profile = JSON.parse(Cookies.get('profile'));
        if (auth==='eventio-error') {
            alert('Henkilötunnuksellasi on jo vahvistettu toinen pelitili. Kirjaudu sisään aiemmin vahvistamaasi pelitiliin.');
        } else if (auth==='error') {
            showMessage('Käyttäjän tunnistaminen epäonnistui.');
        } else if (auth==='ok' && profile.strongly_authenticated === undefined) {
            fetch(`${config.accountAddress}/me`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${Cookies.get('access_token')}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    if (!response.ok) {
                        throw response.json();
                    }
                    return response.json();
                })
                .then((data) => {
                    showMessage('Tunnistautumistiedot päivitetty');
                    login();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        window.history.replaceState({}, document.title, window.location.pathname);
    }, []);

    return (
        <div>
            <StronglyAuthenticatedInfo/>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Form.SectionTitle style={SectionTitle}>Profiili</Form.SectionTitle>
                <PasswordChangeDialogOpenButton onClick={() => setPasswordChangeDialogOpen(true)}>Vaihda salasana</PasswordChangeDialogOpenButton>
                <PasswordChange open={passwordChangeDialogOpen} setOpen={setPasswordChangeDialogOpen}/>
            </div>

            <Form
                action={(body) => {
                    fetch(`${config.accountAddress}/me`, {
                        method: 'PATCH',
                        headers: {
                            Authorization: `Bearer ${Cookies.get('access_token')}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            account: {
                                first_name: body.firstname,
                                last_name: body.lastname,
                                email: body.email,
                                phone: body.phone,
                                address: {
                                    street_address: body.address,
                                    city: body.city,
                                    postal_code: body.postal_code
                                },
                                marketing_permission: marketingAllowed
                            }
                        })
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw response.json();
                            }
                            return response;
                        })
                        .then((response) => {
                            const profile = JSON.parse(Cookies.get('profile'));
                            const newProfile = {
                                ...profile,
                                first_name: body.firstname,
                                last_name: body.lastname,
                                email: body.email,
                                phone: body.phone,
                                address: {
                                    ...profile.address,
                                    street_address: body.address,
                                    city: body.city,
                                    postal_code: body.postal_code
                                },
                                marketing_permission: marketingAllowed
                            };
                            Cookies.set('profile', newProfile);
                            updateUser(newProfile);
                            showMessage('Tiedot päivitetty');
                        })
                        .catch((error) => {
                            console.error(error);
                            showMessage('Tietojen päivitys epäonnistui', true);
                        });
                }}
                id='accountForm'
                style={{ width: '100%' }}
            >
                <Form.SectionContainer>
                    <Form.Section>
                        <Form.SectionTitle color='black'>Nimitiedot</Form.SectionTitle>
                        <NameTextField disabled={stronglyAuthenticated} name='firstname' label='Etunimi' required value={firstname} onChange={(event) => setFirstname(event.target.value)} />
                        <NameTextField disabled={stronglyAuthenticated} name='lastname' label='Sukunimi' required value={lastname} onChange={(event) => setLastname(event.target.value)} />
                    </Form.Section>
                    <Form.Section>
                        <Form.SectionTitle color='black'>Yhteystiedot</Form.SectionTitle>
                        <TextField type='email' name='email' label='Sähköposti' required value={email} onChange={(event) => setEmail(event.target.value)} />
                        <TextField type='tel' name='phone' label='Puhelinnumero' value={phone} onChange={(event) => setPhone(event.target.value)} />
                        <Checkbox
                            name='marketing_allowed'
                            label='Haluan saada Arpamaailma-aiheista sähköpostia.'
                            checked={marketingAllowed}
                            onChange={(event) => setMarketingAllowed(event.target.checked)}
                            labelColor='black'
                        />
                    </Form.Section>
                    <Form.Section>
                        <Form.SectionTitle color='black'>Osoitetiedot</Form.SectionTitle>
                        <TextField name='address' label='Lähiosoite' required value={address} onChange={(event) => setAddress(event.target.value)} />
                        <PostalCodeField type='tel' name='postal_code' label='Postinumero' required value={postalCode} onChange={(event) => setPostalCode(event.target.value)} />
                        <TextField name='city' label='Postitoimipaikka' required value={city} onChange={(event) => setCity(event.target.value)} />
                    </Form.Section>
                </Form.SectionContainer>
                <SubmitButton form='accountForm'>Tallenna</SubmitButton>
            </Form>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ConnectedInfo = connect(mapStateToProps, mapDispatchToProps)(Info);

export { ConnectedInfo as Info };
