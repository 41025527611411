import styled from 'styled-components';

const StepLabel = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    color: ${(props) => props.theme.textSecondary};
    font-weight: 500;
    letter-spacing: 0.1em;
    font-size: 0.8em;

    @media (max-width: 700px) {
        font-size: 0.5em;
    }
`;

export { StepLabel };
