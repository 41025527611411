import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from '.';
import { CheckCircle } from '../common/Icons';
import config from '../config';
import { NavLink } from 'react-router-dom';

const AuthButton = styled(Button)`
    display: ${(props) => props.hidden ? 'none': 'block'}
    margin: 0 auto;
    background: ${(props) => props.bg || 'rgba(0, 153, 102, 1)'};
    color: ${(props) => props.color || 'white'};
    :hover {
        background: ${(props) => props.theme.buttonHover};
        color: ${(props) => props.theme.text};
    }
    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        width: 100%;
        margin: 1em 0 1em 0;
    }
`;

const AuthIcon = styled(CheckCircle)`
    fill: ${(props) => props.theme.success};
    vertical-align: middle;
    margin-right: 0.5em;
`;

const SubIcon = styled(CheckCircle)`
    fill: ${(props) => props.theme.primary};
    vertical-align: middle;
    margin-right: 0.5em;
`;

const AuthBox = styled.div`
    margin: 0 auto;
    background-color: #e0e0e0;
    padding: ${(props) => props.padding || '3em'};
    font-size: 0.9em;
    font-weight: bold;
`;

const AuthHelp = styled.div`
    margin: 0 auto;
    padding-bottom: ${(props) => props.paddingBottom || '1em'};
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
`;

const AuthHelpText = styled.span`
    vertical-align: middle;
`;

const Link = styled(NavLink)`
    text-align: center;
    text-decoration: none;
    color: #282828;
    &.active {
        text-decoration: underline;
    }

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        font-size: 1.2em;

        padding-bottom: 0px;

        :last-child {
            padding-bottom: 10px;
        }
    }
`;

const Auth = ({ user }) => {
    const stronglyAuthenticated = user.strongly_authenticated;

    return (
        <AuthBox id='authbox'>
            <AuthHelp>{ stronglyAuthenticated !== undefined ? <AuthIcon /> : null }
                <AuthHelpText>
                    {
                        stronglyAuthenticated !== undefined ?
                            'Olet vahvasti tunnistautunut nimellä ' + stronglyAuthenticated.first_names + ' ' + stronglyAuthenticated.family_name:
                            'Jotta voit lisätä saldoa, sinun on tunnistauduttava vahvasti. Tunnistautuminen tehdään vain kerran ja on sinulle maksutonta.'
                    }
                </AuthHelpText>
            </AuthHelp>
            <AuthButton name='authbutton' hidden={stronglyAuthenticated} onClick={(event) => {
                event.preventDefault();
                const redirect_uri = encodeURIComponent(config.strong_auth_settings.redirect_uri);
                window.location.href=`${config.strong_auth_settings.auth_url}?response_type=${config.strong_auth_settings.response_type}&scope=${config.strong_auth_settings.scope}&client_id=${config.strong_auth_settings.client_id}&redirect_uri=${redirect_uri}`;
            }} >Tunnistaudu</AuthButton>
        </AuthBox>
    );
};

const StronglyAuthenticatedInfo = ({ user }) => {
    const stronglyAuthenticated = user.strongly_authenticated;

    if(!stronglyAuthenticated) {
        return null;
    }

    return (
        <AuthBox padding='1em'>
            <AuthHelp paddingBottom='0em'>
                <AuthIcon/>
                <AuthHelpText>
                    {`Olet vahvasti tunnistautunut nimellä ${stronglyAuthenticated.first_names} ${stronglyAuthenticated.family_name}`}
                </AuthHelpText>
            </AuthHelp>
        </AuthBox>
    );
};


const SubscriptionInfo = () => (
    <AuthBox padding='1em'>
        <AuthHelp paddingBottom='0em'>
            <SubIcon/>
            <Link to={`/pelattavat/${window.location.hostname === '127.0.0.1' ? 'arpa-0' : 'nuorelle-siivet-arpa'}`}>
                <AuthHelpText>
                    Olet kuukausitilaaja arpajaisessa Nuorelle Siivet -arpa
                </AuthHelpText>
            </Link>
        </AuthHelp>
    </AuthBox>
);

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const ConnectedAuth = connect(mapStateToProps)(Auth);
const ConnectedStronglyAuthenticatedInfo =
      connect(mapStateToProps)(StronglyAuthenticatedInfo);

export {
    ConnectedAuth as Auth,
    ConnectedStronglyAuthenticatedInfo as StronglyAuthenticatedInfo,
    SubscriptionInfo
};
