import styled from 'styled-components';

const SectionTitle = styled.div`
    color: ${(props) => props.color || props.theme.secondaryText};
    font-weight: 400;
    font-size: ${(props) => props.fontSize || '1.5em'};
    text-align: ${(props) => props.textAlign || 'center'};
`;

export { SectionTitle };
