import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TimePicker from 'react-time-picker';
import moment from 'moment';
import { Button, FixedSpinner, Form, IntegerField, showMessage } from '../common';
import SubsectionHeader from '../common/AccountSubsectionHeader';
import { fetchWrap as fetch, formatCurrency } from '../common/functions';
import { useFetch } from '../common/hooks';
import { Select } from '../common/Select';
import config from '../config';

const SubmitButton = styled(Button)`
    margin: 0 auto;

    background: ${(props) => props.bg || 'rgba(153, 0, 102, 1)'};
    color: ${(props) => props.color || 'white'};

    :hover {
        background: ${(props) => props.theme.buttonHover};
        color: ${(props) => props.theme.text};
    }

    @media (max-width: 700px) {
        width: 100%;
        margin: 5rem 0 3rem 0;
    }
`;

const TimePickerSection = styled.div`
    width: 100%;
    text-align: center;
    padding: 20px;

    .react-time-picker {
        background-color: ${(props) => props.theme.text};
    }
`;

const CenteredValue = styled.div`
    text-align: center;
`;

const BoxCenteredValue = styled(CenteredValue)`
    background: green;
    margin: 2px;
    border-radius: 10px;
    color: white;
`;

const PlayPauseUntil = styled.div`
    text-align: center;
    margin-bottom: 1em;
`;

const MaxValueLabel = styled.div`
    margin-right: 2em;
    text-align: right;
`;

const getNextValueCmp = (value, timeframeName, unit) => {
    if(value == null) {
        return <CenteredValue>&nbsp;</CenteredValue>;
    } else if(value === -1) {
        return (
            <CenteredValue>
                Raja poistetaan {timeframeName} vaihtuessa
            </CenteredValue>
        );
    } else {
        return (
            <BoxCenteredValue>
                Seuraava raja <b>{value} {unit}</b><br/>
                asetetaan {timeframeName} vaihtuessa
            </BoxCenteredValue>
        );
    }
};

const keys = [
    'play_by_day',
    'play_by_week',
    'play_by_month',
    'deposit_by_month',
    'play_time_start',
    'play_time_end',
];

const playTimeKeys = ['play_time_start', 'play_time_end'];

const Limits = ({ history }) => {
    const [{ data, loading, refresh: reloadLimits }, setUrl, setOptions, setData] =
          useFetch(`${config.backendAddress}/api/v1/users/limits`);

    const [{ data: defaultLimitsData }] =
          useFetch(`${config.backendAddress}/api/v1/users/default-limits`);

    const [playByDay, setPlayByDay] = useState(null);
    const [playByDayLeft, setPlayByDayLeft] = useState(null);
    const [nextPlayByDay, setNextPlayByDay] = useState(null);

    const [playByWeek, setPlayByWeek] = useState(null);
    const [playByWeekLeft, setPlayByWeekLeft] = useState(null);
    const [nextPlayByWeek, setNextPlayByWeek] = useState(null);

    const [playByMonth, setPlayByMonth] = useState(null);
    const [playByMonthLeft, setPlayByMonthLeft] = useState(null);
    const [nextPlayByMonth, setNextPlayByMonth] = useState(null);

    const [depositByMonth, setDepositByMonth] = useState(null);
    const [depositByMonthLeft, setDepositByMonthLeft] = useState(null);
    const [nextDepositByMonth, setNextDepositByMonth] = useState(null);

    const [playTimeStart, setPlayTimeStart] = useState(null);
    const [nextPlayTimeStart, setNextPlayTimeStart] = useState(null);

    const [playTimeEnd, setPlayTimeEnd] = useState(null);
    const [nextPlayTimeEnd, setNextPlayTimeEnd] = useState(null);

    const [playPause, setPlayPause] = useState(null);
    const [playPauseDatetime, setPlayPauseDatetime] = useState(null);

    useEffect(() => {
        if(data) {
            const valueMap = data.reduce((map, obj) => {
                if(playTimeKeys.indexOf(obj.variety) !== -1) {
                    map[obj.variety] = obj.value;
                } else if (obj.variety === 'play_pause') {
                    map[obj.variety] = obj.value;
                } else {
                    map[obj.variety] = obj.value != null ?
                        obj.value / 100 :
                        null;
                }
                return map;
            }, {});

            const nextValueMap = data.reduce((map, obj) => {
                if (playTimeKeys.indexOf(obj.variety) !== -1 ||
                    obj.next_value === -1) {
                    map[obj.variety] = obj.next_value;
                } else if (obj.variety === 'play_pause') {
                    map[obj.variety] = obj.value;
                } else {
                    map[obj.variety] = obj.next_value != null ?
                        obj.next_value / 100 :
                        null;
                }
                return map;
            }, {});

            const valueLeftMap =  data.reduce((map, obj) => {
                if (playTimeKeys.indexOf(obj.variety) !== -1 ||
                    obj.value_left === -1) {
                    map[obj.variety] = obj.value_left;
                } else if (obj.variety === 'play_pause') {
                    map[obj.variety] = obj.value;
                } else {
                    map[obj.variety] = obj.value_left != null ?
                        obj.value_left / 100 :
                        null;
                }
                return map;
            }, {});

            setPlayByDay(valueMap.play_by_day);
            setPlayByWeek(valueMap.play_by_week);
            setPlayByMonth(valueMap.play_by_month);
            setDepositByMonth(valueMap.deposit_by_month);

            setPlayTimeStart(valueMap.play_time_start);
            setPlayTimeEnd(valueMap.play_time_end);

            setPlayPauseDatetime(valueMap.play_pause);

            setNextPlayByDay(nextValueMap.play_by_day);
            setNextPlayByWeek(nextValueMap.play_by_week);
            setNextPlayByMonth(nextValueMap.play_by_month);
            setNextDepositByMonth(nextValueMap.deposit_by_month);

            setNextPlayTimeStart(nextValueMap.play_time_start);
            setNextPlayTimeEnd(nextValueMap.play_time_end);

            setPlayByDayLeft(valueLeftMap.play_by_day);
            setPlayByWeekLeft(valueLeftMap.play_by_week);
            setPlayByMonthLeft(valueLeftMap.play_by_month);
            setDepositByMonthLeft(valueLeftMap.deposit_by_month);
        }

    }, [data]);

    return (
        <div style={{ width: '100%' }}>
            <SubsectionHeader sectionTitle='Pelirajat'/>
            <div style={{ padding: '2% 5% 0% 5%' }}>
                <p>
                    Voit asettaa itsellesi pelirajoja pelaamisen hallitsemiseksi.
                </p>

                <p>
                    Alentaessasi rajaa, tulee se voimaan heti. Rajojen nosto tulee voimaan vasta seuraavalla aikavälillä (esim. jos nostat viikkorajaasi nyt, tulee se voimaan vasta ensi maanantaina).
                </p>

                <p>
                    Voit myös asettaa itsellesi pelitauon määrätyksi ajaksi.
                </p>
            </div>

            {data && (
                <Form
                    id='limitsForm'
                    action={(body) => {
                        fetch(
                            `${config.backendAddress}/api/v1/users/limits`, {
                                method: 'PATCH',
                                body: JSON.stringify(
                                    Object.keys(body)
                                        .filter((key) => keys.indexOf(key) !== -1)
                                        .map((key) => {
                                            let value = body[key];
                                            if(value.length === 0) {
                                                value = null;
                                            } else if(playTimeKeys.indexOf(key) !== -1) {
                                            } else {
                                                value = Number(value) * 100;
                                            }

                                            return {
                                                variety: key,
                                                value: value,
                                            };
                                        }))
                            }
                        )
                            .then((response) => {
                                return response.json();
                            })
                            .then((json) => {
                                setData(json);
                                showMessage('Tiedot päivitetty');
                            })
                            .catch((error) => {
                                if(error.status === 400) {
                                    reloadLimits();
                                }
                            });
                    }}
                >
                    <Form.SectionContainer
                        className='user-limit-section-container'
                        display='block'
                    >
                        <Form.GridSection gridTemplateColumns='1fr 1fr 1fr 1fr'>
                            <div></div>
                            <CenteredValue>Raja</CenteredValue>
                            <CenteredValue>Pelattavissa nyt</CenteredValue>
                            <div></div>
                        </Form.GridSection>

                        <Form.GridSection
                            gridTemplateColumns='1fr 1fr 1fr 1fr'
                        >
                            <Form.SectionTitle
                                color='black'
                                fontSize='1em'
                                textAlign='left'
                            >
                                Kuukausittainen talletusraja
                            </Form.SectionTitle>
                            <div>
                                <IntegerField
                                    name='deposit_by_month'
                                    label='€'
                                    value={depositByMonth}
                                    onChange={(event) => {
                                        setDepositByMonth(event.target.value);
                                    }}
                                    margin='5px'
                                    paddingTop='0px'
                                    display='grid'
                                    style={{ gridTemplateColumns: '80% 20%' }}
                                    labelPosition='relative'
                                />
                                <MaxValueLabel>
                                    (max. {defaultLimitsData &&
                                           formatCurrency(defaultLimitsData['deposit_by_month'], 0)} €)
                                </MaxValueLabel>
                            </div>
                            <div>
                                <IntegerField
                                    name='deposit_by_month_left'
                                    label='€'
                                    value={depositByMonthLeft}
                                    margin='5px'
                                    paddingTop='0px'
                                    display='grid'
                                    style={{ gridTemplateColumns: '80% 20%' }}
                                    labelPosition='relative'
                                    disabled
                                />
                                {getNextValueCmp(nextDepositByMonth, 'kuukauden', '€')}
                            </div>
                        </Form.GridSection>

                        <Form.GridSection gridTemplateColumns='1fr 1fr 1fr 1fr'>
                            <Form.SectionTitle
                                color='black'
                                fontSize='1em'
                                textAlign='left'
                            >
                                Pelien ostoraja vuorokaudessa
                            </Form.SectionTitle>
                            <div>
                                <IntegerField
                                    name='play_by_day'
                                    label='€'
                                    value={playByDay}
                                    onChange={(event) => {
                                        setPlayByDay(event.target.value);
                                    }}
                                    margin='5px'
                                    paddingTop='0px'
                                    display='grid'
                                    style={{ gridTemplateColumns: '80% 20%' }}
                                    labelPosition='relative'
                                />
                                <MaxValueLabel>
                                    (max. {defaultLimitsData &&
                                           formatCurrency(defaultLimitsData['play_by_day'], 0)} €)
                                </MaxValueLabel>
                            </div>
                            <div>
                                <IntegerField
                                    name='play_by_day_left'
                                    label='€'
                                    value={playByDayLeft}
                                    margin='5px'
                                    paddingTop='0px'
                                    display='grid'
                                    style={{ gridTemplateColumns: '80% 20%' }}
                                    labelPosition='relative'
                                    disabled
                                />
                                {getNextValueCmp(nextPlayByDay, 'vuorokauden', '€')}
                            </div>
                        </Form.GridSection>

                        <Form.GridSection gridTemplateColumns='1fr 1fr 1fr 1fr'>
                            <Form.SectionTitle
                                color='black'
                                fontSize='1em'
                                textAlign='left'
                            >
                                Pelien ostoraja viikossa
                            </Form.SectionTitle>
                            <div>
                                <IntegerField
                                    name='play_by_week'
                                    label='€'
                                    value={playByWeek}
                                    onChange={(event) => {
                                        setPlayByWeek(event.target.value);
                                    }}
                                    margin='5px'
                                    paddingTop='0px'
                                    display='grid'
                                    style={{ gridTemplateColumns: '80% 20%' }}
                                    labelPosition='relative'
                                />
                                <MaxValueLabel>
                                    (max. {defaultLimitsData &&
                                           formatCurrency(defaultLimitsData['play_by_week'], 0)} €)
                                </MaxValueLabel>
                            </div>
                            <div>
                                <IntegerField
                                    name='play_by_week_left'
                                    label='€'
                                    value={playByWeekLeft}
                                    margin='5px'
                                    paddingTop='0px'
                                    display='grid'
                                    style={{ gridTemplateColumns: '80% 20%' }}
                                    labelPosition='relative'
                                    disabled
                                />
                                {getNextValueCmp(nextPlayByWeek, 'viikon', '€')}
                            </div>
                        </Form.GridSection>

                        <Form.GridSection gridTemplateColumns='1fr 1fr 1fr 1fr'>
                            <Form.SectionTitle
                                color='black'
                                fontSize='1em'
                                textAlign='left'
                            >
                                Pelien ostoraja kuukaudessa
                            </Form.SectionTitle>
                            <div>
                                <IntegerField
                                    name='play_by_month'
                                    label='€'
                                    value={playByMonth}
                                    onChange={(event) => {
                                        setPlayByMonth(event.target.value);
                                    }}
                                    margin='5px'
                                    paddingTop='0px'
                                    display='grid'
                                    style={{ gridTemplateColumns: '80% 20%' }}
                                    labelPosition='relative'
                                />
                                <MaxValueLabel>
                                    (max. {defaultLimitsData &&
                                           formatCurrency(defaultLimitsData['play_by_month'], 0)} €)
                                </MaxValueLabel>
                            </div>
                            <div>
                                <IntegerField
                                    name='play_by_month_left'
                                    label='€'
                                    value={playByMonthLeft}
                                    margin='5px'
                                    paddingTop='0px'
                                    display='grid'
                                    style={{ gridTemplateColumns: '80% 20%' }}
                                    labelPosition='relative'
                                    disabled
                                />
                                {getNextValueCmp(nextPlayByMonth, 'kuukauden', '€')}
                            </div>
                        </Form.GridSection>

                        <Form.GridSection gridTemplateColumns='1fr 1fr 1fr 1fr'>
                            <Form.SectionTitle
                                color='black'
                                fontSize='1em'
                                textAlign='left'
                            >
                                Sallittu peliaika vuorokaudessa
                            </Form.SectionTitle>

                            <TimePickerSection>
                                <Form.SectionTitle
                                    color='black'
                                    fontSize='1em'
                                >
                                    Alku
                                </Form.SectionTitle>
                                <TimePicker
                                    value={playTimeStart}
                                    local='fi-FI'
                                    disableClock={true}
                                    format='H-mm'
                                    name='play_time_start'
                                    maxDetail='second'
                                    onChange={(time) => {
                                        setPlayTimeStart(time);
                                    }}
                                />
                                {getNextValueCmp(nextPlayTimeStart, 'vuorokauden')}
                            </TimePickerSection>

                            <TimePickerSection>
                                <Form.SectionTitle
                                    color='black'
                                    fontSize='1em'
                                >
                                    Loppu
                                </Form.SectionTitle>
                                <TimePicker
                                    value={playTimeEnd}
                                    local='fi-FI'
                                    disableClock={true}
                                    format='H-mm'
                                    maxDetail='second'
                                    name='play_time_end'
                                    onChange={(time) => {
                                        setPlayTimeEnd(time);
                                    }}
                                />
                                {getNextValueCmp(nextPlayTimeEnd, 'vuorokauden')}
                            </TimePickerSection>
                        </Form.GridSection>
                    </Form.SectionContainer>
                    <SubmitButton form='limitsForm'>Tallenna</SubmitButton>
                </Form>
            )}

            <hr/>

            {data && (
                <Form
                    id='playPauseForm'
                    action={(body) => {
                        fetch(
                            `${config.backendAddress}/api/v1/users/limits`, {
                                method: 'PATCH',
                                body: JSON.stringify([{
                                    variety: 'play_pause',
                                    value: playPause,
                                }])
                            }
                        )
                            .then((response) => {
                                return response.json();
                            })
                            .then((json) => {
                                setData(json);
                                showMessage('Tiedot päivitetty');
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }}
                >
                    <Form.SectionContainer
                        className='play-pause-section-container'
                        display='block'
                    >
                        <Select
                            label='Pelitauko'
                            labelTextAlign='center'
                            name='play_pause'
                            onChange={(item) => {
                                setPlayPause(item.value);
                            }}
                            options={[
                                { label: 'Vuorokausi', value: 24*60 },
                                { label: 'Viikko', value: (24*7*60) },
                                { label: 'Kuukausi', value: (24*30*60) },
                            ]}
                        />
                    </Form.SectionContainer>

                    <PlayPauseUntil>
                        {playPauseDatetime && (
                            `Pelitauko on voimassa ${moment(playPauseDatetime).format('YYYY-MM-DD')} kello ${moment(playPauseDatetime).format('HH:mm')} asti`
                        )}
                    </PlayPauseUntil>

                    <SubmitButton form='playPauseForm'>
                        Aseta pelitauko
                    </SubmitButton>

                    <CenteredValue>
                        Pelitauolla voit kirjautua sisään ja
                        lunastaa aiemman voittamiasi voittoja,
                        mutta et voi ostaa arpaa.
                    </CenteredValue>
                </Form>
            )}

            {loading && <FixedSpinner/>}
        </div>
    );
};

export { Limits };
