import styled from 'styled-components';

const Image = styled.div`
    flex: 1 1 30%;
    background-color: transparent;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    @media (max-width: 800px) {
        display: block;
        height: 300px;
    }
`;

export { Image };
