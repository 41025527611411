import styled from 'styled-components';

const Title = styled.div`
    position: absolute;

    top: 30%;
    left: 0;
    right: 0;
    margin: auto;

    text-align: center;

    background: ${(props) => props.bg || props.theme.text};
    color: ${(props) => props.theme.secondaryText};
    border-radius: 0px;
    font-size: 1.5em;
    font-weight: 500;
    padding: 0.3em;
`;

export { Title };
