import React, { useState, useEffect } from 'react';
import { Button, Form, ItemPage, TextField } from '../common';
import styled from 'styled-components';
import { fetchWrap as fetch, getClaimHeaders } from '../common/functions';
import config from '../config';
import { showMessage } from '../common/Notifier';
import * as Cookies from 'js-cookie';


const SubmitButton = styled(Button)`
    height: 70px;
    margin: 12px 10px 0 10px;
    width: 14vw;
`;

const InfoField = styled(TextField)`
    min-width: 40%;
    max-width: 30vw;
    margin: 1%;
`;

const VerticalSection = styled(Form.Section)`
    display: flex;
    flex-direction: column;
`;

const HorizontalSection = styled(Form.Section)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;


const RedeemForeignUser = ({ lottery, onSend }) => {
    const [number, setNumber] = useState(null);
    const [email, setEmail] = useState(null);
    const [firstname, setFirstname] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [disabled, setDisabled] = useState(false);

    return (
        <>
            <ItemPage.Title>Onnea, voitit ristikkolehtipaketin!</ItemPage.Title>
            <ItemPage.Description>
                <ItemPage.Text>Lähetä lahjakorttikoodi sähköpostiisi tai tekstarina puhelimeesi</ItemPage.Text>
            </ItemPage.Description>
            <ItemPage.Actions style={{ display: 'flex', flexDirection: 'column' }}>
                <Form id='codeForm' style={{ width: '100%', padding: '0' }} withSpinner action={() => {
                    setDisabled(true);
                    fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.id}/claims`, {
                        method: 'PUT',
                        body: JSON.stringify({
                            firstname: firstname,
                            lastname: lastname,
                            email: email,
                            phone: number,
                        }),
                        headers: getClaimHeaders()
                    })
                        .then((response) => {
                            if (response?.ok) {
                                fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.id}/claims/commit`, {
                                    method: 'PUT',
                                    headers: getClaimHeaders
                                }).then((response) => {
                                    if (response.ok) {
                                        showMessage('Koodi lähetetty', false);
                                        onSend();
                                    } else {
                                        showMessage('Koodia lähettäessä tapahtui virhe', true);
                                    }
                                });
                            }
                        }).catch(() => {
                            showMessage('Koodia lähettäessä tapahtui virhe', true);
                            setDisabled(false);
                        });
                }}>
                    <Form.SectionContainer style={{ display: 'flex', flexDirection: 'column', padding: '0' }}>
                        <VerticalSection style={{ display: 'flex', flexDirection: 'column' ,justifyContent: 'flex-start' }}>
                            <HorizontalSection style={{ maxWidth: '36.5vw' }}>
                                <InfoField required name='firstname' label='Etunimi' value={firstname} onChange={(event) => setFirstname(event.target.value)} />
                                <InfoField required name='lastname' label='Sukunimi' value={lastname} onChange={(event) => setLastname(event.target.value)} />
                            </HorizontalSection>
                            <InfoField required name='email' label='Sähköposti' value={email} onChange={(event) => setEmail(event.target.value)} />
                            <InfoField required name='phone' label='Puhelinnumero'  value={number} onChange={(event) => setNumber(event.target.value)}  />
                        </VerticalSection>
                        <HorizontalSection>
                            <SubmitButton disabled={disabled} form='codeForm'>Lähetä</SubmitButton>
                        </HorizontalSection>
                    </Form.SectionContainer>
                </Form>
            </ItemPage.Actions>
        </>
    );
};

export { RedeemForeignUser };
