import styled from 'styled-components';

const Content = styled.div`
    padding: ${(props) => props.padding || '2em 0'};

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1em;
    }
`;

export { Content };
