import React, { Component } from 'react';
import { TextField } from './TextField';
import { isPostalCodeValid } from './functions/validate';
import i18n from '../i18n';

class PostalCodeField extends Component {
    validatePostalCode = (target, value) => {
        if(!isPostalCodeValid(value)) {
            target.setCustomValidity(i18n.gettext('invalid_postal_code'));
        } else {
            target.setCustomValidity('');
        }
    };

    postalCodeChange = (event, origOnChange) => {
        this.validatePostalCode(event.target, event.target.value);
        origOnChange && origOnChange(event);
    };

    render() {
        const { onChange, ...props } = this.props;
        return (
            <TextField
                {...props}
                onChange={(event) => this.postalCodeChange(event, onChange)}
                mountFun={this.validatePostalCode}
            />
        );
    };
};

export { PostalCodeField };
