import React from 'react';
import styled from 'styled-components';

import { ExpandLess, ExpandMore } from '../Icons';

const Container = styled.div`
    position: relative;
    height: 50px;
`;

const Expander = styled.div`
    position: absolute;
    bottom: 0;
    fill: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.primary};
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
    overflow: hidden;
    width: 100%;
    height: ${(props) => props.open ? '200px' : '50px'};
    background: ${(props) => props.theme.background};
    transition: 0.25s height;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 2em;
    }

    > * {
        margin-top: 2em;
    }
`;

const Drawer = ({ open, children, onOpen, onClose, ...props }) => {
    return (
        <Container {...props}>
            <Expander open={open}>
                {open ? <ExpandMore onClick={onClose} style={{ cursor: 'pointer' }} /> : <ExpandLess onClick={onOpen} style={{ cursor: 'pointer', display: 'inline-block' }} />}
                {children}
            </Expander>
        </Container>
    );
};

export { Drawer };
