import styled from 'styled-components';
import LogoImage from '../images/logos/Arpamaailma_logo_white_Avainlippu.png';

const Logo = styled.img.attrs({
    src: LogoImage,
    alt: 'logo'
})`
    height: 100%;
`;

export { Logo };
