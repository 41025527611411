import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as Cookies from 'js-cookie';
import { Page, Button, Footer, Stepper, Slider, Switcher } from '../common';
import { List } from './Step1List';
import { Contact } from './Step2Contact';
import { Summary } from './Step4Summary';
import { PaymentMethod } from './Step5PaymentMethod';
import { Spinner } from '../common';
import { Assignment, Check, Payment, Cart as CartIcon } from '../common/Icons';
import { fetchWrap as fetch, formatCurrency } from '../common/functions';
import * as userActions from '../store/actions';
import config from '../config';

const CartSteps = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100px;
    margin-bottom: 1em;

    > ${Button} {
        display: inline-block;
        padding: 0.5em 2em;
        margin-left: 5%;
        height: 63px;
    }

    > ${Button}:first-child {
        margin-left: 0;
    }
`;

const CartSummary = styled.div`
    font-size: 1.1em;
    font-weight: 500;
    color: black;
    text-align: center;

    @media (max-width: 700px) {
        display: none;
    }
`;

const CartItemsAmount = styled.div`
`;

const CartTotalPrice = styled.div`
    font-weight: 500;
    font-size: 1.8em;
    color: ${(props) => props.theme.warning};
`;

const getCartValue = (cart) => {
    let total = 0;
    for (const item of cart.items) {
        total += item.value;
    }
    return total;
};

const Cart = ({
    history,
    user,
    userActions: { saveState, loadShopCart, saveShopCart, removeItem, clearCart, refreshAccounts }
}) => {
    const [step, setStep] = useState(0);
    const [contacts, setContacts] = useState({});
    const [balanceAmount, setBalanceAmount] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState(getCartValue(user.cart));
    const [deliveryAmount, setDeliveryAmount] = useState(0);
    const [sending, setSending] = useState(false);
    const [redeemClicked, setRedeemClicked] = useState(false);

    useEffect(() => {
        const elem = document.getElementById('cartPageContent');
        if(elem) {
            elem.scrollTo(0,0);
        }
    }, [step]);

    useEffect(() => {
        const products = [];
        for (const cartItem of user.cart.items) {
            const product = products.find((item) => cartItem.id === item.product_id);
            if (product) {
                product.quantity++;
            } else {
                const newItem = {
                    product_id: cartItem.id,
                    quantity: 1,
                    variation_id: cartItem.option?.variation_id
                };
                if (cartItem.product_option_id) {
                    newItem.product_option_id = cartItem.product_option_id;
                }
                products.push(newItem);
            }
        }

        if (products.length > 0) {
            fetch(`${config.backendAddress}/api/v1/shop/orders?provisional=true`, {
                method: 'POST',
                body: JSON.stringify({
                    products,
                    payment_method: '1',
                    user: {
                        firstname: '',
                        lastname: '',
                        address: '',
                        postal_code: '20780',
                        city: '',
                        email: '',
                        phone: '',
                        marketing_allowed: true,
                        advertisement_allowed: true
                    }
                }),
                headers: {
                    'Arpamaailma-Order-Token': Cookies.get('arpamaailma-order-token')
                }
            })
                .then((response) => response.json())
                .then((json) => {
                    setDeliveryAmount(json.delivery.value);
                    setBalanceAmount(
                        json.withdrawn.wins +
                            json.withdrawn.extra_wins +
                            json.withdrawn.bonus
                    );
                    setPaymentAmount(json.withdrawn.order_deposit);
                })
                .catch((error) => {
                    console.error(error);
                    loadShopCart(() => history.push('/voittokauppa'));
                });
        }
    }, [contacts, history, user.cart.items, step]);
    return (
        <Page>
            <Page.Title>Voittojen lunastaminen</Page.Title>
            <Page.Content id='cartPageContent'  style={{ overflowX: 'hidden' }}>
                <div style={{ minHeight: 'calc(100vh - 284px)' }}>
                    {redeemClicked && <Spinner/>}
                    {!redeemClicked &&
                     <>
                         <Stepper value={step}>
                             <Stepper.Step icon={<CartIcon />} label='Ostoskori' />
                             <Stepper.Step icon={<Assignment />} label='Yhteystietosi' />
                             {/*<Stepper.Step icon={<Shipping />} label='Toimitustapa' />*/}
                             <Stepper.Step icon={<Check />} label='Yhteenveto' />
                             {paymentAmount > 0 && (
                                 <Stepper.Step icon={<Payment />} label='Maksutapa' />
                             )}
                         </Stepper>
                         <Switcher value={step} style={{ flex: 1 }}>
                             <List
                                 items={user.cart.items}
                                 onRemoveItem={(item) => {
                                     removeItem(item);
                                     saveShopCart();
                                 }}
                                 onLinkClick={(item) => history.push(`/voittokauppa/${item.id}`)}
                             />
                             <Contact user={user} onNextStep={(body) => {
                                 setContacts(body);
                                 setStep(step + 1);
                             }}/>
                             <Summary
                                 items={user.cart.items}
                                 balanceAmount={balanceAmount}
                                 paymentAmount={paymentAmount}
                                 deliveryAmount={deliveryAmount}
                                 user={contacts}
                             />
                             {paymentAmount > 0 && (
                                 <PaymentMethod
                                     user={user}
                                     contacts={contacts}
                                     saveState={saveState}
                                     sending={sending}
                                     setSending={setSending}
                                 />
                             )}
                         </Switcher>

                         <CartSteps>
                             <Switcher value={step}>
                                 <>
                                     {user.cart.items.length > 0 && <Button.Warning onClick={() => {
                                         clearCart();
                                         saveShopCart();
                                     }}>Tyhjennä ostoskori</Button.Warning>}
                                     {user.cart.items.length > 0 && (
                                         <CartSummary>
                                             <CartItemsAmount>{user.cart.items.length} kpl tuotteita<br />Hinta yhteensä</CartItemsAmount>
                                             <CartTotalPrice>{formatCurrency(user.cart.items.reduce((acc, item) => acc + item.value, 0))} €</CartTotalPrice>
                                         </CartSummary>
                                     )}
                                     {user.cart.items.length > 0 && <Button onClick={() => setStep(step + 1)}>Seuraava</Button>}
                                 </>
                                 <>
                                     <Button onClick={() => setStep(step - 1)}>Takaisin</Button>
                                     <Button type='submit' form='contactForm'>Seuraava</Button>
                                 </>
                                 <>
                                     <Button onClick={() => setStep(step - 1)}>Takaisin</Button>
                                     {paymentAmount > 0 && (
                                         <Button.Confirm onClick={() => {
                                             setStep(step + 1);
                                         }}>
                                             Seuraava
                                         </Button.Confirm>
                                     ) || (
                                         <Button.Confirm disabled={redeemClicked} onClick={() => {
                                             if(redeemClicked) return;
                                             setRedeemClicked(true);

                                             const products = [];
                                             for (const cartItem of user.cart.items) {
                                                 const product = products.find((item) => cartItem.id === item.product_id);
                                                 if (product) {
                                                     product.quantity++;
                                                 } else {
                                                     const newItem = {
                                                         product_id: cartItem.id,
                                                         quantity: 1,
                                                         variation_id: cartItem.option?.variation_id
                                                     };
                                                     if (cartItem.product_option_id) {
                                                         newItem.product_option_id = cartItem.product_option_id;
                                                     }
                                                     products.push(newItem);
                                                 }
                                             }
                                             fetch(`${config.backendAddress}/api/v1/shop/orders`, {
                                                 method: 'POST',
                                                 body: JSON.stringify({
                                                     products,
                                                     user: contacts
                                                 }),
                                                 headers: {
                                                     'Arpamaailma-Order-Token': Cookies.get('arpamaailma-order-token')
                                                 }
                                             })
                                                 .then((response) => response.json())
                                                 .then((json) => {
                                                     refreshAccounts();
                                                     clearCart();
                                                     saveShopCart();
                                                     history.push('/success');
                                                 })
                                                 .catch((error) => {
                                                     console.error(error);
                                                     setRedeemClicked(false);
                                                 });
                                         }}>
                                             Lunasta
                                         </Button.Confirm>
                                     )}
                                 </>

                                 {paymentAmount > 0 && !sending && (
                                     <>
                                         <Button onClick={() => setStep(step - 1)}>Takaisin</Button>
                                     </>
                                 )}
                             </Switcher>

                         </CartSteps>
                     </>}
                </div>
                <Footer />
            </Page.Content>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ConnectedCart = connect(mapStateToProps, mapDispatchToProps)(Cart);

export { ConnectedCart as Cart };
