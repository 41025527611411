import React from 'react';
import styled from 'styled-components';
import { Card } from './Card';

const PrizeCard = styled(Card)`
    background-color: white;
    background-image: none;
    padding-bottom: 20%;
    min-height: 270px;

    &:before {
        display: none;
    }
`;

const ImageContainer = styled.div`
    position: absolute;

    top: 1em;
    left: 0;
    right: 0;

    text-align: center;

    img {
        max-height: 150px;
        width: auto;
    }
`;

const Button = styled(Card.Button)`
    bottom: 1em;
`;

const Title = styled(Card.Title)`
    position: absolute;
    bottom: 3em;
    width: 100%;
    font-weight: normal;
    top: 60%;
    background: unset;
`;

const PrizeValue = styled.div`
    position: absolute;
    top: 0;
    margin-top: 0.5em;
    margin-left: 0.5em;
    font-weight: bold;
    background-color: white;
    box-shadow: 10px 10px 15px 10px rgba(255,255,255,1);
`;

const LeftTitleContent = styled.div`
    float: left;
    line-height: 2em;
    vertical-align: middle;
    margin-left: 1em;
`;

const RightTitleContent = styled.div`
    float: right;
    margin-right: 0.1em;
`;

const PrizeName = styled.span`
    font-weight: normal;
`;

const Image = ({ src }) => {
    return (
        <ImageContainer>
            <img alt='' src={src}/>
        </ImageContainer>
    );
};


Title.LeftContent = LeftTitleContent;
Title.RightContent = RightTitleContent;
PrizeCard.Button = Button;
PrizeCard.PrizeValue = PrizeValue;
PrizeCard.Title = Title;
PrizeCard.LotteryName = PrizeName;
PrizeCard.Image = Image;

export { PrizeCard };
