import Aktia from './payment/aktia.png';
import Alandsbanken from './payment/alandsbanken.png';
import Danskebank from './payment/danskebank.png';
import Handelsbanken from './payment/handelsbanken.png';
import Nordea from './payment/nordea.png';
import Omasp from './payment/omasp.png';
import Osuuspankki from './payment/osuuspankki.png';
import Pop from './payment/pop.png';
import Sp from './payment/sp.png';
import Spankki from './payment/spankki.png';

const PaymentMethods = [
    { id: 'nordea', image: Nordea },
    { id: 'osuuspankki', image: Osuuspankki },
    { id: 'danske', image: Danskebank },
    { id: 'alandsbanken', image: Alandsbanken },
    { id: 'handelsbanken', image: Handelsbanken },
    { id: 'spankki', image: Spankki },
    { id: 'aktia', image: Aktia },
    { id: 'pop', image: Pop },
    { id: 'saastopankki', image: Sp },
    { id: 'omasp', image: Omasp },
];

export { PaymentMethods };
