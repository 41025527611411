import styled from 'styled-components';
import { Button } from './Button';

const Hollow = styled(Button)`
    color: white;
    fill: white;
    background: transparent;
    border: 2px white solid;

    :hover {
        background: rgba(255, 255, 255, 0.4);
        border-color: white;
    }
`;

export { Hollow };
