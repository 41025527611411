import React, { useState, useEffect } from 'react';
import * as Cookies from 'js-cookie';
import styled from 'styled-components';
import { Page, Button, Form, TextField, Checkbox, PostalCodeField, Stepper, Slider } from '../common';
import { useFetch } from '../common/hooks';
import { fetchWrap as fetch, getClaimHeaders, getLangValue, getImageValue, formatCurrency } from '../common/functions';
import config from '../config';

import { Assignment, Check } from '../common/Icons';

const RedeemSteps = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100px;
    background: ${(props) => props.theme.background};
    border-top: 5px ${(props) => props.theme.secondary} solid;
    padding: 18px;

    > ${Button} {
        display: inline-block;
        padding: 0.5em 2em;
        margin-left: 5%;
    }

    > ${Button}:first-child {
        margin-left: 0;
    }

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        padding-bottom: 200px;
    }
`;

const SummaryWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Summary = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 5%;
    margin: 0 auto;
`;

const SummarySection = styled.div`
    width: 100%;
    margin-bottom: 2em;
`;

const SummarySectionTitle = styled.div`
    color: ${(props) => props.theme.primaryDark};
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-bottom: 1em;
`;

const SummarySectionContent = styled.div`
    display: flex;
    flex-direction: ${(props) => props.column ? 'column' : 'row'};
    color: #2B384A;
    background: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    margin-bottom: 1em;
    @media (max-width: 700px) {
        flex-direction ${(props) => props.mobile ? 'column' : props.column ? 'column' : 'row'};
    }
`;

const SummarySectionPicture = styled.div`
    flex: 2;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    @media (max-width: 700px) {
        flex: 0 1 auto;
        height: 200px;
    }
`;

const SummarySectionText = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: 2em;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 5%;

    @media (max-width: 700px) {
        flex: 0 1 auto;
        font-size: 1.5em;
        height: 150px;
    }
`;

const TextRow = styled.div`
    display: flex;
    width: 100%;
    font-size: 1.5em;
    margin-bottom: 1em;

    >:first-child {
        font-weight: bold;
        width: 200px;
    }

    >:last-child, &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 700px) {
        display: block;
    }
`;

const TextRowRightColumn = styled.div`
    flex: 3;
    margin-left: 1em;

    @media (max-width: 700px) {
        margin-left: 0;
    }
`;

const Redeem = ({ lottery, prize, user, onClaim, onClaimExtra, option }) => {
    const [{ data }, setUrl] = useFetch(null);
    useEffect(() => {
        if (prize && prize.product_id) {
            setUrl(`${config.backendAddress}/api/v1/products/${prize.product_id}`);
        }
    }, [prize, setUrl]);
    const [firstname, setFirstname] = useState(user.first_name);
    const [lastname, setLastname] = useState(user.last_name);
    const [address, setAddress] = useState(user.address ? user.address.street_address : '');
    const [postalCode, setPostalCode] = useState(user.address ? user.address.postal_code : '');
    const [city, setCity] = useState(user.address ? user.address.city : '');
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone || '');
    const [marketingAllowed, setMarketingAllowed] = useState(user.marketing_allowed);
    const [advertisementAllowed, setAdvertisementAllowed] = useState(user.advertisement_allowed);
    const [extraLotteryBody, setExtraLotteryBody] = useState({});

    const claimProduct = (body) => {
        const requestBody = { ...body };
        if (option) {
            requestBody.product_option_id = option;
        }

        fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.id}/claims`, {
            method: 'PUT',
            body: JSON.stringify(requestBody),
            headers: getClaimHeaders(),
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.id}/claims/commit`, {
                    method: 'PUT',
                    body: JSON.stringify(requestBody),
                    headers: getClaimHeaders(),
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((json) => {
                        onClaim(json);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const claimExtraLottery = (body) => {
        fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.id}/extra-lottery/commit`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: getClaimHeaders(),
        })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                onClaimExtra(json);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Page>
            <Page.Title>{prize && prize.product_id ? 'Palkinnon lunastaminen' : 'Osallistu lisäarvontaan'}</Page.Title>
            <Page.Content flex style={{ overflowX: 'hidden' }}>
                <div>
                    <Form
                        action={prize && prize.product_id ? claimProduct : (body) => {
                            claimExtraLottery(body);
                        }}
                        method='PUT'
                        id='contactForm'
                    >
                        <Form.Section>
                            <Form.SectionTitle>Nimitiedot</Form.SectionTitle>
                            <TextField name='firstname' label='Etunimi' required value={firstname} onChange={(event) => setFirstname(event.target.value)} />
                            <TextField name='lastname' label='Sukunimi' required value={lastname} onChange={(event) => setLastname(event.target.value)} />
                        </Form.Section>
                        <Form.Section>
                            <Form.SectionTitle>Yhteystiedot</Form.SectionTitle>
                            <TextField type='email' name='email' label='Sähköposti' required value={email} onChange={(event) => setEmail(event.target.value)} />
                            <TextField type='tel' name='phone' label='Puhelinnumero' required value={phone} onChange={(event) => setPhone(event.target.value)} />
                            <Checkbox name='marketing_allowed' label='Haluan saada Arpamaailma aiheista sähköpostia.' checked={marketingAllowed} onChange={(event) => setMarketingAllowed(event.target.checked)} />
                            <Checkbox name='advertisement_allowed' label='Haluan saada Arpamaailman yhteistyökumppaneilta tietoa.' checked={advertisementAllowed} onChange={(event) => setAdvertisementAllowed(event.target.checked)} />
                        </Form.Section>
                        <Form.Section>
                            <Form.SectionTitle>Osoitetiedot</Form.SectionTitle>
                            <TextField name='address' label='Lähiosoite' required value={address} onChange={(event) => setAddress(event.target.value)} />
                            <PostalCodeField type='tel' name='postal_code' label='Postinumero' required value={postalCode} onChange={(event) => setPostalCode(event.target.value)} />
                            <TextField name='city' label='Postitoimipaikka' required value={city} onChange={(event) => setCity(event.target.value)} />
                        </Form.Section>
                    </Form>
                </div>
                <RedeemSteps>
                    <Button type='submit' form='contactForm'>Vahvista</Button>
                </RedeemSteps>
            </Page.Content>
        </Page>
    );
};

export { Redeem };
