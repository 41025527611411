import React from 'react';
import moment from 'moment';
import { IncrementalList } from '../common';
import SubsectionHeader from '../common/AccountSubsectionHeader';
import { getLangValue, formatCurrency } from '../common/functions';
import config from '../config';
import i18n from '../i18n';


const getItemCmp = (item) => {
    const productNames = item.product_names ? getLangValue(item.product_names, 'fi') : '';
    const productOptionName = item.product_option_names ? getLangValue(item.product_option_names, 'fi') : null;

    const value = item.product_value_transferrable
        ? `${formatCurrency(item.prize)} €`
        : `${productNames}${
            (productOptionName)
                ? `, ${productOptionName}`
                : (productNames)
                    ? ''
                    : 'Ei voittoa'}`;
    return (
        <>
            <td key='lotteryID'>{item.playable_ticket_code_a}</td>
            <td key='lotteryName'>{getLangValue(item.lottery_names, 'fi')}</td>
            <td key='played'>{item.played ? 'Valmis' : 'Kesken'}</td>
            <td key='value'>{value}</td>
            <td key='timestamp'>
                {moment.utc(item.commited_at || item.created_at).local().format('DD.MM.YYYY HH:mm')}
            </td>
        </>
    );
};

const getListHeader = () => {
    return (
        <>
            <th key='headerTicketID'>{i18n.gettext('ticketID')}</th>
            <th key='headerLotteryName'>{i18n.gettext('lottery')}</th>
            <th key='headerPlayed'>{i18n.gettext('state')}</th>
            <th key='headerValue'>{i18n.gettext('win')}</th>
            <th key='headerTimestamp'>{i18n.gettext('timestamp')}</th>
        </>
    );
};

const GameHistory = ({ history }) => {
    return (
        <>
            <SubsectionHeader sectionTitle='Pelihistoria'/>
            <IncrementalList
                url={`${config.backendAddress}/api/v1/playable-tickets/states`}
                getItemCmp={getItemCmp}
                getListHeader={getListHeader}
                noItemsMessage='Ei pelejä'
            />
        </>
    );

};

export { GameHistory };
