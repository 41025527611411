import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Page, Footer } from '../common';

const Text = styled.div`
    width: 100%;
    max-Width: 1280px;
    margin: auto;
    padding: 1em;
    color: black;

    * > h2 {
        text-transform: uppercase;
    }
`;

const Privacy = ({ app: { cms } }) => {
    return (
        <Page>
            <Page.Title>Rekisteri ja tietosuojaseloste</Page.Title>
            <Page.Content>
                <Text>
                    { cms && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: cms.privacy && cms.privacy.content
                            }}
                        ></div>
                    )}
                </Text>
                <Footer />
            </Page.Content>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        app: state.app
    };
};

const ConnectedPrivacy = connect(mapStateToProps)(Privacy);

export { ConnectedPrivacy as Privacy };
