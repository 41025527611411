import styled from 'styled-components';

const Label = styled.label`
    display: block;
    padding: 0 0 0 45px;
    color: ${(props) => props.labelColor || props.theme.secondaryText};
    font-size: 0.9em;
    font-weight: 500;

    &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: 2px solid ${(props) => props.labelColor || props.theme.secondaryText};
        border-radius: 2px;
        z-index: 0;
        transition: all .28s cubic-bezier(.4, 0, .2, 1);
        transition-property: background-color, border-color;
    }

    &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 8px;
        width: 8px;
        height: 16px;
        border-bottom: 4px solid transparent;
        border-right: 4px solid transparent;
        transform: rotate(45deg);
        z-index: 1;
        transition: border-color .28s cubic-bezier(.4, 0, .2, 1);
    }
`;

export { Label };
