import styled from 'styled-components';

const Button = styled.button`
    position: absolute;

    width: 200px;
    right: 0;
    left: 0;
    margin: auto;

    bottom: 40px;
    text-transform: uppercase;

    background: ${(props) => props.bg || props.theme.tertiary};
    color: ${(props) => props.color || 'white'};

    font-size: 100%;
    font-family: 'Titillium Web';
    font-weight: bold;

    border: ${(props) => props.borderWidth || '0px'} ${(props) => props.borderColor || 'white'} solid;
    border-radius: ${(props) => props.borderRadius || '10px'};

    padding: 0.7em;
    cursor: pointer;
    transition: background 0.25s;

    outline: none;

    :hover {
        background: ${(props) => props.hoverBg || props.theme.buttonHover};
    }
`;

export { Button };
