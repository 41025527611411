import React from 'react';
import styled from 'styled-components';
import { Page, Button, Footer } from '../common';

const Message = styled.div`
    font-size: 2em;
    color: black;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
    margin-top: 2em;
`;

const ThanksRedeem = ({ history }) => {
    return (
        <Page>
            <Page.Title>Lunastettu</Page.Title>
            <Page.Content flex style={{ overflowX: 'hidden' }}>
                <div style={{ height: 'calc(100% - 120px)', marginBottom: '2em' }}>
                    <Message>Kiitos tilauksestasi!</Message>
                    <Button onClick={() => history.push('/')} style={{ margin: '2em auto 0 auto', width: 250, height: 80 }}>Takaisin etusivulle</Button>
                </div>
                <Footer />
            </Page.Content>
        </Page>
    );
};

export { ThanksRedeem };
