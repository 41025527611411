import React, { useContext } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import { Page, LotteryCard, Spinner, Footer } from '../common';
import { useFetch } from '../common/hooks';
import { getLangValue, getImageValue } from '../common/functions';
import config from '../config';
import { Slideshow } from '../common/Slideshow/Lotteries';
import { LotteryButton } from '../common/LotteryButton';

const Grid = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    width: 100%;
    max-width: 1200px;
    margin: 2em auto;
    padding: 20px;

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 5%;
        margin: auto;
    }
`;

const Lotteries = ({ history, app: { cms }, theme }) => {
    const themeContext = useContext(ThemeContext);
    const [{ data, loading }] = useFetch(`${config.backendAddress}/api/v1/lotteries?variety=claimable`);
    return (
        <Page>
            <Page.Content bgColor={themeContext.darkroom}>
                <div style={{ minHeight: 'calc(100vh - 200px)' }}>
                    <Slideshow slides={
                        cms
                            && cms.otherLotteries
                            && cms.otherLotteries.slides
                    }
                    />
                    <Grid>
                        {!loading && data && data.filter((lottery) => !lottery.hidden).map((lottery) => (
                            <LotteryCard
                                key={lottery.id}
                                src={getImageValue(lottery.images, 'small')}
                                style={{ height: 250 }}
                                bgSize='contain'
                            >
                                <LotteryCard.Title>
                                    <LotteryCard.Title.LeftContent>
                                        <LotteryCard.LotteryName>
                                            {getLangValue(lottery.names, 'fi')}
                                        </LotteryCard.LotteryName>
                                    </LotteryCard.Title.LeftContent>

                                    <LotteryCard.Title.RightContent>
                                        <LotteryButton onClick={
                                            () => history
                                                .push('/lunastettavat/' + lottery.slug)
                                        }>
                                            Valitse
                                        </LotteryButton>
                                    </LotteryCard.Title.RightContent>
                                </LotteryCard.Title>
                            </LotteryCard>
                        ))}
                        {loading && <Spinner />}
                    </Grid>
                </div>
                <Footer />
            </Page.Content>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        app: state.app
    };
};

const ConnectedLotteries = connect(mapStateToProps)(Lotteries);

export { ConnectedLotteries as Lotteries };
