import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, FixedSpinner } from '../common';
import { useFetch } from '../common/hooks';

const Message = styled.div`
    font-size: 2em;
    color: ${(props) => props.theme.textSecondary};
    font-weight: 400;
    letter-spacing: 0.1em;
    text-align: center;
    padding-top: 2em;
    padding-bottom: 2em;
`;

const List = styled.table`
    border-spacing: 0;
    table-layout: auto;
    margin: 0 auto 0 auto;
    padding: 2em;
    max-width: 1200px;
    width: 100%;
`;

const Item = styled.tr`
    font-size: 1.2em;
    text-align: center;

    td {
        padding: 0.5em;
    }
`;

const MoreButton = styled(Button)`
    margin: 0 auto;
    margin-bottom: 3em;

    background: ${(props) => props.bg || 'rgba(153, 0, 102, 1)'};
    color: ${(props) => props.color || 'white'};

    :hover {
        background: ${(props) => props.theme.buttonHover};
        color: ${(props) => props.theme.text};
    }
`;

const HeaderItem = styled(Item)`
    text-align: center;

    th {
        padding: 0.5em;
        border-bottom: 2px grey solid;
        font-weight: 500;
        font-size: 1.2em;

        @media (max-width: 750px) {
            font-size: 0.8em;
        }
    }
`;

const getList = (items, getItemCmp, increase, hidden, getListHeader = null) => {
    return [
        <List
            className='mainlist'
            key='mainlist'
        >
            <tbody>
                {getListHeader && <HeaderItem key='listHeader'>{getListHeader()}</HeaderItem>}

                {items.map((item, index) => (
                    <Item key={item.id}>
                        {getItemCmp(item)}
                    </Item>
                ))}
            </tbody>
        </List>,
        <div
            key='btnContainer'
            style={{
                width: '100%',
                textAlign: 'center',
            }}
        >
            {!hidden &&
             <MoreButton
                 key='loadmore'
                 onClick={increase}
             >
                 Lisää
             </MoreButton>
            }
        </div>
    ];
};

const LIMIT = 7;

const IncrementalList = ({
    url,
    getItemCmp,
    noItemsMessage,
    key = 'items',
    getListHeader = null,
}) => {
    const [{ data, loading }, setUrl] = useFetch(null);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [btnHidden, setBtnHidden] = useState(true);

    const increase = () => {
        if(offset + LIMIT < total) {
            setOffset(offset + LIMIT);
        }
    };

    useEffect(() => {
        if(data) {
            setTotal(data.total);

            // Filter out existing items by id.
            setItems(
                (items) =>
                    items.concat(
                        data[key].filter(
                            (item) => (
                                items.length
                                    ? !items.find((existingItem) => item.id === existingItem.id)
                                    : true
                            )
                        )
                    )
            );

            setBtnHidden(data.total <= offset + data.items.length);
        } else {
            setTotal(0);
            setItems([]);
            setBtnHidden(true);
        }
    }, [data, key, offset]);

    useEffect(() => {
        setUrl(`${url}?limit=${LIMIT}&offset=${offset}`);
    }, [setUrl, offset, url]);

    return (
        <div style={{
            width: '100%',
        }}>
            {data && (
                <>
                    {items.length === 0
                        ? <Message>{noItemsMessage}</Message>
                        : getList(items, getItemCmp, increase, btnHidden, getListHeader)
                    }
                </>
            )}
            {loading && <FixedSpinner />}
        </div>
    );
};

export { IncrementalList, List, Item, HeaderItem };
