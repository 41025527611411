import styled from 'styled-components';
import { Cart } from '../Icons/Cart';

const Icon = styled(Cart)`
    border-radius: 50%;
    border: 3px ${(props) => props.theme.primary} solid;
    background-color: ${(props) => props.theme.primary};
    fill: ${(props) => props.theme.text};
    padding: 3px;
`;

export { Icon };
