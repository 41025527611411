import styled from 'styled-components';

const Text = styled.div`
    text-align: center;
    color: white;
    font-weight: 500;
    padding: 2em 15%;
`;

export { Text };
