import styled from 'styled-components';

const ItemPage = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;

    ${(props) => props.minHeight && `
        min-height: ${(props.minHeight)}
    `}

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        display: block;
    }
`;

export { ItemPage };
