import React from 'react';
import { Button, ItemPage } from '../common';
import { getLangValue } from '../common/functions';

const Home = ({ lottery, authenticated, onLogin, onRedeem }) => {
    return (
        <>
            <ItemPage.Title>{lottery && getLangValue(lottery.names, 'fi')}</ItemPage.Title>
            <ItemPage.Description>
                <ItemPage.Text>
                    {lottery && getLangValue(lottery.descriptions, 'fi')}
                </ItemPage.Text>
            </ItemPage.Description>
            <ItemPage.Actions>
                {authenticated && <Button onClick={onRedeem}>Jatka</Button>}
                {!authenticated && <Button onClick={onLogin}>Kirjaudu</Button>}
                {!authenticated && lottery.quick_claim_enabled && <Button onClick={onRedeem}>Pikalunastus</Button>}
            </ItemPage.Actions>
        </>
    );
};

export { Home };
