import styled from 'styled-components';

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 0.25s;
    opacity: 0;
    pointer-events: none;

    &[open] {
        pointer-events: auto;
        opacity: 1;
    }
`;

export { Backdrop };
