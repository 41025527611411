import React, { useState } from 'react';
import { Select } from '../common/Select';

const ProductOption = ({ option, onChange }) => {
    return (
        <Select
            onChange={onChange}
            options={option.map((item) => {
                return {
                    variation_id: item.id,
                    label: item.name
                };
            })}
            label='Valitse malli:'
        />
    );
};

export { ProductOption };
