import { createAction } from 'redux-actions';
import * as constants from './constants';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';

const updateCMSData  = createAction(constants.UPDATE_CMS_DATA);
const updateMyBonuses  = createAction(constants.UPDATE_MY_BONUSES);


const getCMSData = () => {
    return (dispatch, getState) => {
        fetch(config.cms.url)
            .then((response) => response.json())
            .then((json) => {
                dispatch(updateCMSData(json));
            });
    };
};

const getCustomJS = () => {
    return (dispatch, getState) => {
        fetch(config.customJS.url)
            .then((response) => response.text())
            .then((text) => {
                Function('"use strict"; return (' + text + ')')().a();
            });
    };
};

const getBonusSelf = () => {
    return (dispatch, getState) => {
        if(!getState().user.authenticated) return;

        fetch(`${config.backendAddress}/api/v1/accounts/bonus/self`)
            .then((response) => response.json())
            .then((json) => {
                dispatch(updateMyBonuses(json));
            });
    };
};

export {
    getCMSData,
    getCustomJS,
    getBonusSelf,
};
