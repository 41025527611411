import styled from 'styled-components';

const Advertisement = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;

    &:before {
        content: '';
	    position: absolute;
	    top: 0;
	    right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to right, ${(props) => props.theme.secondary}, transparent);
    }

    @media (max-width: 700px) {
        height: 200px;
    }
`;

export { Advertisement };
