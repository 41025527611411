import * as constants from './constants';

const initialState = {
    authenticated: false,
    accounts: [],
    cookies: null,
    first_name: '',
    last_name: '',
    email: '',
    address: {
        street_address: '',
        city: '',
        postal_code: ''
    },
    cart: {
        items: []
    },
};

const initialAppState = {
    cms: null,
    myBonuses: null,
};

const reducer = (state = initialState, action) => {
    switch(action.type) {

        case constants.UPDATE_USER: {
            return { ...state, ...action.payload, authenticated: true };
        }

        case constants.OPEN_MENU: {
            return { ...state, menuOpen: true };
        }

        case constants.CLOSE_MENU: {
            return { ...state, menuOpen: false };
        }

        case constants.UPDATE_ACCOUNTS: {
            return { ...state, accounts: action.payload };
        }

        case constants.UPDATE_CART: {
            return { ...state, cart: action.payload };
        }

        case constants.ADD_CART_ITEM: {
            return { ...state, cart: { ...state.cart, items: [...state.cart.items, action.payload] } };
        }

        case constants.REMOVE_CART_ITEM: {
            const id = action.payload.id;
            const items = state.cart.items.slice();
            const index = items.findIndex((item) => {
                return item.id === id;
            });
            if (index !== -1) {
                items.splice(index, 1);
            }
            return { ...state, cart: { ...state.cart, items } };
        }

        case constants.CLEAR_CART: {
            return { ...state, cart: { items: [] } };
        }

        case constants.UPDATE_CMS_DATA: {
            return { ...state, cms: action.payload };
        }

        default: {
            return state;
        }
    }
};

const appReducer = (state = initialAppState, action) => {
    switch(action.type) {
        case constants.UPDATE_CMS_DATA: {
            return { ...state, cms: action.payload };
        }

        case constants.UPDATE_MY_BONUSES: {
            return { ...state, myBonuses: action.payload };
        }

        default: {
            return state;
        }
    }
};

export { reducer, appReducer };
