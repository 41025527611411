import styled from 'styled-components';

const Text = styled.div`
    position: absolute;

    top: 45%;
    right: 0;
    left: 0;
    margin: auto;

    text-align: center;

    padding-left: 1em;
    padding-right: 1em;

    color: ${(props) => props.color || 'white'};
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 0.1em;
    align-self: flex-end;
`;

export { Text };
