import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Page, Footer } from '../common';

import * as CookieConsent from 'vanilla-cookieconsent';

const Text = styled.div`
    width: 100%;
    max-Width: 1280px;
    margin: auto;
    padding: 1em;
    color: black;

    * > h2 {
        text-transform: uppercase;
    }
`;

const Cookies = ({ app: { cms } }) => {
    return (
        <Page>
            <Page.Title>Evästeiden käyttö</Page.Title>
            <Page.Content >
                <Text style={{ minHeight: 'calc(100vh - 284px)' }}>
                    { cms && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: cms.cookies && cms.cookies.content
                            }}
                        ></div>
                    )}
                    <a href='#' onClick={() => CookieConsent.show(true)}>Vaihda evästeasetuksia</a>
                </Text>
                <Footer />
            </Page.Content>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        app: state.app
    };
};

const ConnectedCookies = connect(mapStateToProps)(Cookies);

export { ConnectedCookies as Cookies };
