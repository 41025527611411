import styled from 'styled-components';
import { Button } from './Button';

const Warning = styled(Button)`
    background: ${(props) => props.theme.warning};
    border: 3px ${(props) => props.theme.warning} solid;
    color: ${(props) => props.theme.warningText};

    :hover {
        background: ${(props) => props.theme.warningDark};
        border-color: ${(props) => props.theme.warningDark};
    }
`;

export { Warning };
