import React from 'react';
import styled from 'styled-components';

const Svg = ({ title, path, ...props }) => {
    return (
        <svg {...props}>
            <title>{title}</title>
            {path}
        </svg>
    );
};

Svg.defaultProps = {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 24 24',
    width: 40,
    height: 40
};

const StyledSvg = styled(Svg)``;

export { StyledSvg as Svg };
