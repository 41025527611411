let accountAddress = 'https://account-api.eventio.fi';
let backendAddress = 'https://arpamaailma.fi';
let cmsUrl = 'https://sbhdiag215.blob.core.windows.net/arpamaailma-static/cms.json';
let customJSUrl = 'https://sbhdiag215.blob.core.windows.net/arpamaailma-static/am-custom.js';
let dreamshopUrl = 'https://dreamshop.arpamaailma.fi';
let dreamshopIncomingFrom = 'arpamaailma';
let gtmId = 'GTM-K8MMDSD';

switch (window.location.origin) {
    case 'https://127.0.0.1:3000':
        accountAddress = process.env.REACT_APP_EVENTIO_API_ADDRESS || 'https://account-api.eventio.fi';
        backendAddress = process.env.REACT_APP_ARPAMAAILMA_BACKEND_ADDR || 'https://arpamaailma.fi';
        cmsUrl = process.env.REACT_APP_CMS_URL || 'https://sbhdiag215.blob.core.windows.net/arpamaailma-static/cms.json';
        customJSUrl = process.env.REACT_APP_CUSTOM_JS_URL || 'https://sbhdiag215.blob.core.windows.net/arpamaailma-static/am-custom.js';
        dreamshopUrl = process.env.REACT_APP_DREAMSHOP_URL || 'https://dreamshop.arpamaailma.fi';
        dreamshopIncomingFrom = process.env.REACT_APP_DREAMSHOP_INCOMING_FROM || 'arpamaailma';
        gtmId = '';
        break;

    case 'https://dev.arpamaailma.fi':
        accountAddress = 'https://account-api.dev.eventio.fi';
        backendAddress = 'https://dev.arpamaailma.fi';
        cmsUrl = 'https://sbhdiag215.blob.core.windows.net/arpamaailma-testing/cms.json';
        customJSUrl = 'https://sbhdiag215.blob.core.windows.net/arpamaailma-testing/am-custom.js';
        dreamshopUrl = 'https://dreamshop.dev.arpamaailma.fi';
        dreamshopIncomingFrom = 'arpamaailma-dev';
        gtmId = 'GTM-PR7HZGB';
        break;

    case 'https://dev2.arpamaailma.fi':
        accountAddress = 'https://account-api.dev.eventio.fi';
        backendAddress = 'https://dev2.arpamaailma.fi';
        cmsUrl = 'https://sbhdiag215.blob.core.windows.net/arpamaailma-testing/cms.json';
        customJSUrl = 'https://sbhdiag215.blob.core.windows.net/arpamaailma-testing/am-custom.js';
        dreamshopUrl = 'https://dreamshop.dev.arpamaailma.fi';
        dreamshopIncomingFrom = 'arpamaailma-dev';
        gtmId = 'GTM-PR7HZGB';
        break;

    // production
    default:
        accountAddress = process.env.REACT_APP_EVENTIO_API_ADDRESS || 'https://account-api.eventio.fi';
        backendAddress = process.env.REACT_APP_ARPAMAAILMA_BACKEND_ADDR || 'https://arpamaailma.fi';
        cmsUrl = process.env.REACT_APP_CMS_URL || 'https://sbhdiag215.blob.core.windows.net/arpamaailma-static/cms.json';
        customJSUrl = process.env.REACT_APP_CUSTOM_JS_URL || 'https://sbhdiag215.blob.core.windows.net/arpamaailma-static/am-custom.js';
        dreamshopUrl = process.env.REACT_APP_DREAMSHOP_URL || 'https://dreamshop.arpamaailma.fi';
        dreamshopIncomingFrom = process.env.REACT_APP_DREAMSHOP_INCOMING_FROM || 'arpamaailma';
        gtmId = 'GTM-K8MMDSD';
}

const config = {
    accountAddress: accountAddress,

    backendAddress: backendAddress,

    cms: {
        url: cmsUrl
    },

    customJS: {
        url: customJSUrl
    },

    beacon: {
        id: 'c7448749-4760-44bc-8e33-e066c60e3f1f'
    },

    dreamshop: {
        url: dreamshopUrl,
        incomingFrom: dreamshopIncomingFrom,
    },

    gtm: {
        id: gtmId,
    },

    playableTicketsEnabled: true,

    oauth2_settings: {
        authority: process.env.REACT_APP_AUTH_ADDR ||
            (window.location.origin === 'https://arpamaailma.fi' ?
                'https://auth.eventio.fi/' : 'https://auth.dev.eventio.fi/'),

        client_id: process.env.REACT_APP_AUTH_CLIENT_ID || 'arpamaailma.fi',

        redirect_uri: `${window.location.origin}/callback`,
        post_logout_redirect_uri: `${window.location.origin}`,
        response_type: 'code',
        scope: 'openid profile email phone address offline',
        loadUserInfo: true,
        mergeClaims: true,
    },

    strong_auth_settings: {
        auth_url: process.env.REACT_APP_STRONG_AUTH_URL ||
            (window.location.origin === 'https://arpamaailma.fi' ?
                'https://tunnistus.telia.fi/uas/oauth2/authorization' : 'https://tunnistus-pp.telia.fi/uas/oauth2/authorization'),

        client_id: process.env.REACT_APP_STRONG_AUTH_CLIENT_ID ||
            (window.location.origin === 'https://arpamaailma.fi' ?
                '48907d73-5ff8-4888-8b21-dc2c58128a42' : 'c4b9859f-252a-4374-8f59-a160349ec0f0'),

        redirect_uri: `${window.location.origin}/auth`,
        response_type: 'code',
        scope: 'openid',
    }
};

export default config;
