import styled from 'styled-components';

const Content = styled.div`
    position: relative;
    ${(props) => props.flex && `
        display: flex;
        flex-direction: column;
        flex: 1;
    `}

    background-color: ${(props) => props.bgColor || props.theme.primaryText};
    height: ${(props) => props.fullHeight ? '100%' : 'auto'};
    overflow: auto;
`;

export { Content };
