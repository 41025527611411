import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImageGallery from 'react-image-gallery';
import * as userActions from '../store/actions';
import queryString from 'query-string';
import { Page, Button, ItemPage, Spinner, Select } from '../common';
import { useFetch } from '../common/hooks';
import { getLangValue, getImageValue, formatCurrency } from '../common/functions';
import { ProductOption } from './ProductOption';
import config from '../config';
import { Back } from '../common/Icons';

const Product = ({ history, location, match: { params: { slug, id } }, userActions: { addItem, updateCart, saveShopCart }, user: { authenticated, cart } }) => {
    const { lottery } = queryString.parse(location.search);
    const [{ data, loading }] = useFetch(`${config.backendAddress}/api/v1/shop/products/${id}`);
    const [option, setOption] = useState(null);

    return (
        <Page>
            <Page.Title>Palkinnon tiedot</Page.Title>
            <Page.BackButton onClick={() => history.push(slug ? `/${slug}/voittokauppa` : '/voittokauppa')}><Back />Takaisin</Page.BackButton>
            <Page.Content flex>
                {!loading && data && (
                    <ItemPage>
                        <ImageGallery
                            showPlayButton={false}
                            showThumbnails={false}
                            items={data.images.filter((item) => item.variety == 'large').map((item) => {
                                return { original: item.source };
                            })}
                        />
                        <ItemPage.Content>
                            <ItemPage.Title>{`${data.custom_id ? `${data.custom_id}. ` : ''}${getLangValue(data.names, 'fi')}`}</ItemPage.Title>
                            <ItemPage.Description>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: getLangValue(data.descriptions, 'fi')
                                    }}
                                ></div>
                            </ItemPage.Description>
                            <ItemPage.Title style={{ marginTop: '1em' }}>Arvo {formatCurrency(data.value)} €</ItemPage.Title>
                            <ItemPage.Actions>
                                <div style={{ display: 'grid' }}>
                                    {data && data.variations.length > 0 &&
                                     (
                                         <ProductOption
                                             option={data.variations}
                                             onChange={(item) => setOption(item)}
                                         />
                                     )}

                                    {authenticated && (
                                        <Button
                                            style={{ marginTop: '10px' }}
                                            onClick={() => {
                                                const item = { ...data, option: option };
                                                // Determine target lottery based on first item.
                                                if (cart.items.length === 0) {
                                                    updateCart({
                                                        lottery_id: lottery,
                                                        items: [item]
                                                    });
                                                } else {
                                                    addItem(item);
                                                }

                                                /* Saving shop cart gets us an
                                                 * order token, thus we push
                                                 * history after finishing
                                                 * saving cart. */
                                                saveShopCart(() => history.push('/ostoskori'));
                                            }}>
                                            Lisää ostokoriin
                                        </Button>
                                    )}
                                </div>
                            </ItemPage.Actions>
                        </ItemPage.Content>
                    </ItemPage>
                )}
                {loading && <Spinner />}
            </Page.Content>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ProductConnected = connect(mapStateToProps, mapDispatchToProps)(Product);

export { ProductConnected as Product };
