import styled from 'styled-components';
import { Card } from './Card';

const LotteryCard = styled(Card)`
    padding-bottom: 50%;
    min-height: 15em;
`;

const Title = styled(Card.Title)`
    top: unset;
    bottom: 0;
    width: 100%;
    background-color: ${(props) => props.bgColor || props.theme.backgroundCard };
`;

const LeftTitleContent = styled.div`
    float: left;
    line-height: 2em;
    vertical-align: middle;
    margin-left: 1em;
`;

const RightTitleContent = styled.div`
    float: right;
    margin-right: 0.1em;
    margin-top: 0.1em;
`;

const LotteryName = styled.span`
    font-weight: normal;
`;

Title.LeftContent = LeftTitleContent;
Title.RightContent = RightTitleContent;
LotteryCard.Title = Title;
LotteryCard.LotteryName = LotteryName;

export { LotteryCard };
