import styled from 'styled-components';

const Input = styled.input.attrs({
    type: 'checkbox'
})`
    position: absolute;
    top: -1px;
    left: 0;
    width: 25px;
    height: 25px;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
`;

export { Input };
