import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { Button } from '../common';
import { getLangValue, getImageValue, formatCurrency } from '../common/functions';

import { Close } from '../common/Icons';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    overflow: auto;
`;

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 5%;
    margin: 0 auto;
`;

const CartItem = styled.div`
    display: flex;
    position: relative;
    background: white;
    width: 100%;
    height: 200px;
    box-shadow: -1px -1px 20px 2px rgba(0,0,0,0.4);
    margin-bottom: 2em;

    @media (max-width: 700px) {
        display: block;
        height: auto;
        font-size: 0.8em;
    }
`;

const CartItemPicture = styled.div`
    flex: 2;
    background-image: url(${(props) => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 700px) {
        height: 200px;
    }
`;

const CartItemInfo = styled.div`
    display: flex;
    flex: 3;
    padding: 0 5%;
    justify-content: space-between;
    align-items: center;
`;

const CartItemTitle = styled.div`
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 1em;
`;

const CartItemLink = styled.a`
    color: 'white';
    font-size: 1.5em;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    cursor: pointer;
`;

const CartItemPrice = styled.div`
    font-size: 2em;
    font-weight: bold;
`;

const CartItemRemoveButton = styled(Close)`
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    background-color: ${(props) => props.theme.warning};
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    fill: white;
    padding: 0.5em;

    @media (max-width: 700px) {
        right: 1em;
        top: 1em;
        width: 40px;
        height: 40px;
    }
`;

const EmptyText = styled.div`
    font-size: 1.6em;
    color: ${(props) => props.theme.secondaryText};
    font-weight: 400;
    text-align: center;
`;

const List = ({ history, items, onRemoveItem, onLinkClick }) => {
    return (
        <Wrapper>
            <Container>
                {items && items.length > 0 ? items.map((item, index) => (
                    <CartItem key={index}>
                        <CartItemPicture src={getImageValue(item.images, 'small')} />
                        <CartItemRemoveButton onClick={() => onRemoveItem(item)} />
                        <CartItemInfo>
                            <div>
                                <CartItemTitle>{getLangValue(item.names, 'fi')} {item.option && `(${item.option.label})`}</CartItemTitle>
                                <CartItemLink onClick={() => onLinkClick(item)}>Tuotesivu</CartItemLink>
                            </div>
                            <div>
                                <CartItemPrice>{formatCurrency(item.value)} €</CartItemPrice>
                            </div>
                        </CartItemInfo>
                    </CartItem>
                )) : (
                    <>
                        <EmptyText>Ostokorisi on tyhjä</EmptyText>
                    </>
                )}
                <Button onClick={() => history.push('/voittokauppa')} style={{ margin: '1em auto 1em auto' }}>Lisää voittoja</Button>
            </Container>
        </Wrapper>
    );
};

const ListWithRouter = withRouter(List);

export { ListWithRouter as List };
