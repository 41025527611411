import styled from 'styled-components';

const Text = styled.div`
    color: ${(props) => props.theme.secondaryText};
    font-weight: 400;
    font-size: 1.1em;

    ${(props) => props.mobileCenter && `
        @media (max-width: 700px) {
            text-align: center;
        }
    `}
`;

export { Text };
