import React from 'react';
import moment from 'moment';
import { IncrementalList } from '../common';
import SubsectionHeader from '../common/AccountSubsectionHeader';
import { getLangValue, formatCurrency } from '../common/functions';
import config from '../config';
import i18n from '../i18n';

const getItemCmp = (item) => {
    return ([
        <td key='product'>{item.products.map((product) => product.product_id ? `${getLangValue(product.product_names, 'fi')}${product.product_option_id ? `, ${getLangValue(product.product_option_names, 'fi')}` : ''} (${product.count} kpl)` : '').join(', ')}</td>,
        <td key='state'>{i18n.gettext(item.state)}</td>,
        <td key='value'>{`${formatCurrency(item.value)} €`}</td>,
        <td key='timestamp'>{moment.utc(item.created_at).local().format('DD.MM.YYYY HH:mm')}</td>,
    ]);
};

const getListHeader = () => {
    return (
        <>
            <th key='headerProduct'>{i18n.gettext('product')}</th>
            <th key='headerState'>{i18n.gettext('state')}</th>
            <th key='headerValue'>{i18n.gettext('win')}</th>
            <th key='headerTimestamp'>{i18n.gettext('timestamp')}</th>
        </>
    );
};

const Orders = ({ history }) => {
    return (
        <>
            <SubsectionHeader sectionTitle='Muut voittotapahtumat'/>
            <IncrementalList
                url={`${config.backendAddress}/api/v1/orders`}
                getItemCmp={getItemCmp}
                getListHeader={getListHeader}
                noItemsMessage='Ei tilauksia'
            />
        </>
    );
};

export { Orders };
