import { Button } from './Button';
import { Card } from './Card';
import { Title } from './Title';
import { Text } from './Text';
import { Image } from './Image';

Card.Button = Button;
Card.Title = Title;
Card.Text = Text;
Card.Image = Image;

export { Card };
