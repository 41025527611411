import React from 'react';
import { Button, ItemPage } from '../common';

const RedeemLoggedUser = ({ onEnterNew }) => {
    return (
        <>
            <ItemPage.Title>Onnea, voitit ristikkolehtipaketin!</ItemPage.Title>
            <ItemPage.Description>
                <ItemPage.Text>Voittokoodi on lähetetty sähköpostiisi ja tekstarina puhelinnumeroosi.</ItemPage.Text>
            </ItemPage.Description>
            <ItemPage.Actions>
                <Button onClick={onEnterNew}>Syötä uusi</Button>
            </ItemPage.Actions>
        </>
    );
};

export { RedeemLoggedUser };
