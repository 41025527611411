import styled from 'styled-components';

const Page = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export { Page };
