import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    will-change: transform;
    transition: transform 0.25s;
    height: 100%;
    min-height: 0;

    > * {
        width: 100%;
        flex-shrink: 0;
        overflow: auto;
    }

    @media (max-width: 1100px) {
        height: 100%;
        > * {
            height: 100%;
        }
    }
`;

const Slider = ({ value, style, ...props }) => {
    return (
        <Container {...props} style={{ ...style, transform: `translate(${value * -100}%, 0)` }} />
    );
};

export { Slider };
