import React from 'react';
import styled from 'styled-components';
import { Title } from './Title';
import { Amount } from './Amount';
import { formatCurrency } from '../functions';

const Balance = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 2em;
    padding-right: 2em;
    height: 100%;
    color: ${(props) => props.theme.text};
    background-color: ${(props) => props.theme.tertiary};

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }
`;

const BalanceContainer = ({ value, titleText }) => {
    return (
        <Balance>
            <Title>{titleText || 'Saldosi'}:&nbsp;</Title>
            <Amount>{formatCurrency(value)} €</Amount>
        </Balance>
    );
};

export { BalanceContainer as Balance };
