import styled from 'styled-components';

const Title = styled.h1`
    color: ${(props) => props.theme.primaryText};
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height};
    text-align: center;
    background: ${(props) => props.theme.primary};
    padding: 18px 0;
    margin: 0;
    letter-spacing: 0.1em;
    font-weight: 500;

    @media (max-width: 700px) {
        font-size: 1em;
    }
`;

export { Title };
