import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, showMessage } from '../common';
import * as Cookies from 'js-cookie';
import { formatCurrency } from '../common/functions';

const Message = styled.div`
    font-size: 2em;
    color: ${(props) => props.theme.textSecondary};
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    margin-top: 2em;
`;

const BackButton = styled(Button)`
    margin: 0 auto;
    background: ${(props) => props.bg || 'rgba(153, 0, 102, 1)'};
    color: ${(props) => props.color || 'white'};
    margin-top: 2em;
    margin-bottom: 2em;

    :hover {
        background: ${(props) => props.theme.buttonHover};
        color: ${(props) => props.theme.text};
    }
`;

const AddSaldoSuccess = ({ history }) => {
    const saldo = window.sessionStorage.getItem('saldo');
    const redirect_to = Cookies.get('deposit_redirect_to');
    Cookies.remove('deposit_redirect_to');

    useEffect(() => {
        if(history && redirect_to) {
            showMessage('Arvoa lisätty tilillesi');
            history.push(redirect_to);
        }
    }, [history, redirect_to]);

    return (
        <div style={{ width: '100%' }}>
            <Message>Rahansiirto onnistui</Message>
            <Message style={{ fontSize: '1.5em', fontWeight: '100', fontStyle: 'italic' }}>
                {formatCurrency(saldo * 100)} € on lisätty pelitilillesi ja se on käytössä heti.
            </Message>
            <BackButton
                onClick={() => history.push('/')}
            >
                Takaisin etusivulle
            </BackButton>
        </div>
    );
};

export { AddSaldoSuccess };
