import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { TextField, Button, showMessage } from '../common';
import { Dialog } from '../common/Dialog';
import { connect } from 'react-redux';

const SubscribeButton = styled(Button)`
    width: 75%;
    &:disabled {
        background: gray;
    }
`;

const SubscriptionDialog = ({ open, setDialogOpen, app: { cms } }) => {
    const SubscriptionDialogRef = useRef(null);
    const [content, setContent] = useState([]);

    useEffect(() => {
        if (cms && cms.playable_lotteries && cms.playable_lotteries.filter((item) => item.popup != null).length > 0){
            setContent(cms.playable_lotteries.filter((item) => item.popup != null)[0].popup);
        }
    }, [cms]);

    return (
        <Dialog
            ref={SubscriptionDialogRef}
            open={open}
            style={{ maxHeight: '600px' }}
        >
            <Dialog.Header style={{ justifyContent: 'center' }}>Liity kuukausitilaajaksi</Dialog.Header>
            <Dialog.Content>
                <Dialog.Text style={{ fontSize: 26, padding: '0 2% 0 2%', margin: '0 0 0 0' }}>
                    <p>{content}</p>
                </Dialog.Text>
            </Dialog.Content>

            <Dialog.Actions
                style={{ justifyContent: 'center' }}
            >
                <SubscribeButton
                    onClick={() => setDialogOpen(false)}
                >
                    OK
                </SubscribeButton>
            </Dialog.Actions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        app: state.app
    };
};

const ConnectedSubscriptionDialog = connect(mapStateToProps)(SubscriptionDialog);

export { ConnectedSubscriptionDialog as SubscriptionDialog };
