import styled from 'styled-components';
import { Svg } from '../Icons/Svg';

const BackButton = styled.button`
    position: absolute;
    top: 0.5em;
    left: 9%;
    text-transform: uppercase;
    background: transparent;
    font-size: 120%;
    font-family: inherit;
    font-weight: 600;
    letter-spacing: 0.1em;
    border: 2px white solid;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 5px;
    transition: background 0.25s;

    :hover {
        background: rgba(255, 255, 255, 0.4);
    }

    > ${Svg} {
        width: 36px;
        height: 36px;
        fill: white;
    }

    @media (max-width: 700px) {
        top: 9px;
        left: 10px;
        height: 42px;
        width: 42px;
        font-size: 0;

        > ${Svg} {
            width: 30px;
            height: 30px;
        }
    }
`;

export { BackButton };
