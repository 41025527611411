import React, { Component } from 'react';
import styled from 'styled-components';
import { CheckCircle } from './Icons';
import i18n from '../i18n';

const Group = styled.div`
    display: ${(props) => props.type === 'hidden' ? 'none' : (props.display || 'block')}
    position: relative;
    margin: ${(props) => props.margin || '1rem 1rem 2rem 1rem'};
    padding-top: ${(props) => props.paddingTop || '1.5rem'};
    background: white;
    border: 3px solid ${(props) => props.focus ? 'black': 'rgb(190,190,190)'};
    border-radius: 5px;

    @media (max-width: 700px) {
        margin: 1rem 0 2rem 0;
    }
`;

const Icon = styled(CheckCircle)`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: -45px;
    transition: all 0.28s ease;

    @media (max-width: 700px) {
        display: none;
    }
`;

const Label = styled.label`
    position: ${(props) => props.position || 'absolute'};
    top: 0rem;
    pointer-events: none;
    padding-left: 0.5rem;
    z-index: 1;
    color: black;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0em;
    transition: all 0.28s ease;

    &[required]:after {
        position: absolute;
        top: 0.1rem;
        width: 100%;
        color: ${(props) => props.theme.warningDark};
        font-weight: 500;
        font-size: 1rem;
        margin-left: 1em;
        letter-spacing: 0em;
        content: '*';
    }

`;

const Input = styled.input`
    display: block;
    padding: 1em 0.5em;
    height: 1.9rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0em;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    color: black;
    transition: all 0.28s ease;
    box-shadow: none;
    outline: none;

    & ~ ${Icon} {
        fill: grey;
    }

    ${(props) => !props.empty && `
        &:valid {
            border-bottom: 2px ${(props) => props.theme.textSecondary} solid;
        }

        &:valid ~ ${Label}, &:invalid ~ ${Label} {
            font-size: 1.2rem;
            left: 0;
        }

        &:valid ~ ${Icon} {
            fill: ${props.theme.success};
        }

        &:invalid {
            color: ${props.theme.warning}
        }

        &:invalid ~ ${Label} {
            color: ${props.theme.warning}
        }

        &:invalid ~ ${Icon} {
            fill: ${props.theme.warning};
        }
    `}
`;

class TextField extends Component {
    state = {
        value: '',
        focus: false,
    }

    textInput = React.createRef();

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.value != null && nextProps.value !== prevState.value) {
            return { value: nextProps.value };
        } else {
            return null;
        }
    }

    onChange = (event) => {
        this.setState({ value: event.target.value });
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    componentDidMount = () => {
        this.validateField();
        if(this.props.mountFun) {
            this.props.mountFun(this.textInput.current, this.props.value);
        }
    }

    validateField = () => {
        const target = document.getElementById(this.getId());
        if(!target) {
            return;
        }
        if(this.props.required && !target.value) {
            target.setCustomValidity(i18n.gettext('missing_value'));
        } else {
            target.setCustomValidity('');
        }
    }

    getId = () => {
        return this.props.id || 'input_' + this.props.name;
    };

    fieldChange = (event, originalOnChange) => {
        this.validateField();
        originalOnChange && originalOnChange(event);
    };

    render() {
        const { label, id, name, type, required, placeholder, pattern, disabled, readonly, autoComplete, onChange, mountFun, ...props } = this.props;
        const { value } = this.state;
        return (
            <Group required={required} focus={this.state.focus} type={type} {...props}>
                <Input
                    id={this.getId()}
                    name={name}
                    type={type}
                    onChange={(event) => this.fieldChange(
                        event,
                        onChange || this.onChange,
                    )}
                    onBlur={() => this.setState({ focus: false })}
                    onFocus={() => this.setState({ focus: true })}
                    ref={this.textInput}
                    value={value}
                    placeholder={placeholder}
                    pattern={pattern}
                    required={required}
                    disabled={disabled}
                    readonly={readonly}
                    autoComplete={autoComplete}
                    empty={value.length === 0} />
                <Label
                    required={required}
                    htmlFor={id}
                    position={props.labelPosition}
                >
                    {label}
                </Label>
                {/*<Icon />*/}
            </Group>
        );
    }
}

export { TextField };
