import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, TextField, ItemPage } from '../common';
import { getLangValue } from '../common/functions';
import config from '../config';

const InputContainer = styled.div`
    font-size: 1.2rem;
    font-weight: 500;

    @media(max-width: 800px) {
        text-align: center;
    }
`;

const Input = styled.input`
    padding: 1em 0.5em;
    height: 1.9rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0em;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    box-shadow: none;
    outline: none;

    border: 3px solid gray;
    width: 100px;
    text-align: center;

    &:focus {
        border: 3px solid black;
    }

    @media(max-width: 800px) {
        width: 27%;
    }
`;

const TripletTextField = ({ name }) => {
    const [value, setValue] = useState('');
    const [firstFieldValue, setFirstFieldValue] = useState('');
    const [secondFieldValue, setSecondFieldValue] = useState('');
    const [thirdFieldValue, setThirdFieldValue] = useState('');

    useEffect(() => {
        setValue(firstFieldValue + secondFieldValue + thirdFieldValue);
    }, [firstFieldValue, secondFieldValue, thirdFieldValue]);

    return (
        <>
            <input name={name} value={value} readOnly hidden={true}></input>

            <InputContainer>
                <Input
                    id='triplet_text_field_first'
                    value={firstFieldValue}
                    required={true}
                    onChange={(event) => {
                        if(event.target.value.length <= 4) {
                            setFirstFieldValue(event.target.value);
                        }
                        if(event.target.value.length >= 4) {
                            document.getElementById('triplet_text_field_second').focus();
                        }
                    }}
                >
                </Input>
                &mdash;
                <Input
                    id='triplet_text_field_second'
                    value={secondFieldValue}
                    required={true}
                    onChange={(event) => {
                        if(event.target.value.length <= 4) {
                            setSecondFieldValue(event.target.value);
                        }
                        if(event.target.value.length >= 4) {
                            document.getElementById('triplet_text_field_third').focus();
                        }
                    }}
                >
                </Input>
                &mdash;
                <Input
                    id='triplet_text_field_third'
                    value={thirdFieldValue}
                    required={true}
                    onChange={(event) => {
                        if(event.target.value.length <= 4) {
                            setThirdFieldValue(event.target.value);
                        }
                        if(event.target.value.length >= 4) {
                            document.getElementById('triplet_text_field_third').blur();
                        }
                    }}
                >
                </Input>
            </InputContainer>
        </>
    );
};

export { TripletTextField };
