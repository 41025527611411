import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import moment from 'moment';
import queryString from 'query-string';
import { showMessage } from '../common/Notifier';
import { Countdown as CountdownIcon } from '../common/Icons/Countdown';
import * as userActions from '../store/actions';
import { Button, Spinner, Page, ItemPage, TextField } from '../common';
import { Balances } from '../common/Balance';
import { Dialog } from '../common/Dialog';
import { useFetch } from '../common/hooks';
import { Info as InfoIcon, Close as CloseIcon } from '../common/Icons';
import config from '../config';
import { fetchWrap as fetch, formatCurrency, getImageValue, getLangValue } from '../common/functions';
import { capitalize } from 'lodash';
import 'moment/locale/fi';
import { SubscriptionDialog } from './SubscriptionDialog';
import * as Cookies from 'js-cookie';

moment.locale('fi');

const Title = styled(Page.Title)`
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        display: none;
    }
`;

const TextButton = styled.div`
    cursor: pointer;
    margin-top: 0;
`;

const PageTitleAndBalance = styled.div`
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        height: 9vh;
        font-size: 0.9rem;
        display: inline;
        align-items: flex-start;
    }
`;

const Countdown = styled.div`
    border: 2px solid black;
    padding: 10px;
    width: 60%;
    background-color: ${(props) => props.theme.secondary};
    color: white;
    font-size: 1.5em;
    display: table;

    > span {
        display: table-cell;
        text-align: center;
        vertical-align: middle;

        > svg {
            vertical-align: sub;
        }
    }

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        width: 100%;
    }
`;

const Info = styled.div`
    float: right;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const DiscountInfo = styled.div`
    font-size: 1.2em;
    color: white;
    background-color: #e95f51;
    width: 60%;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 10px;
    text-align: center;
`;

let interval = null;

const PURCHASE_VARIETY_SINGLE_ONLY = 0;
const PURCHASE_VARIETY_MONTHLY_BUNDLE_FIRST = 1;
const PURCHASE_VARIETY_PREFER_MONTHLY_BUNDLE = 2;
const acceptable_slugs = ['nuorelle-siivet', 'nuorelle-siivet-arpa', 'arpa-0', 'arpa-1'];


//let TEST_FAKE_NOW; // eslint-disable-line prefer-const

const SingleInfo = ({
    existingTickets,
}) => {
    return (
        existingTickets &&
            existingTickets.playable_tickets.length > 0 &&
            <p>Sinulla on { existingTickets.playable_tickets.length } {existingTickets.playable_tickets.length > 1 ? 'pelaamatonta arpaa' : 'pelaamaton arpa'}.</p>
    );
};

const BundleInfo = ({
    lottery,
    existingBundles,
    existingTickets,
    timeUntilEndOfMonth,
}) => {
    if(!existingTickets || !existingBundles) {
        return null;
    }

    /*
      Possible cases:

      - no existing bundles and no existing tickets
      - no existing bundles but existing tickets from last month
      - existing bundles, no all played
      - existing bundles, all played
     */

    // TODO handle changing of month

    return (
        <>
            <h2>{capitalize(moment().format('MMMM'))}n arpapaketti</h2>
            <p>
                {capitalize(moment().format('MMMM'))}n arpapaketti,&nbsp;
                {lottery.playable_ticket_bundle_size} kpl
                arpoja a' {formatCurrency(lottery.playable_ticket_prices[0].price)} €,&nbsp;
                yhteensä {lottery.playable_ticket_bundle_size * formatCurrency(lottery.playable_ticket_prices[0].price)} €.
            </p>

            <p>
                Sinulle on varattu joka kuukausi arpapaketti.
                Arpapaketin voit ostaa koska tahansa kuukauden aikana.
                Ensi kuussa tulee taas uusi paketti!
            </p>

            {
                existingBundles.items.length === 0 &&
                    existingTickets.playable_tickets.length === 0 &&
                    (
                        <>
                            <div>
                                Aikaa ostaa tämän kuun arpapaketti:
                                <Countdown>
                                    <span><CountdownIcon /></span>
                                    {timeUntilEndOfMonth && (
                                        <span>
                                            {timeUntilEndOfMonth.days()} päivää,&nbsp;
                                            {timeUntilEndOfMonth.hours()} h&nbsp;
                                            {timeUntilEndOfMonth.minutes()} m&nbsp;
                                            {timeUntilEndOfMonth.seconds()} s
                                        </span>
                                    )}
                                </Countdown>
                            </div>
                        </>
                    )
            }

            {
                existingBundles.items.length > 0 &&
                    existingBundles.items.every((bundle) => bundle.amount === bundle.played_amount) &&
                    (
                        <>
                            <h3>Olet jo ostanut tämän kuun arpapaketin</h3>
                            <p>Kiitos tuestasi! Toivottavasti sinulla oli arpaonnea.</p>
                            <p>
                                Seuraava arpapaketti on ostettavissa:&nbsp;
                                <b>{moment().add(1, 'months').format('MMMM')}n 1. päivänä.</b>
                            </p>

                        </>
                    )
            }

            {
                existingBundles.items.length > 0 &&
                    existingBundles.items.some((bundle) => bundle.amount !== bundle.played_amount) &&
                    (
                        <>
                            <h3>Olet jo ostanut tämän kuun arpapaketin</h3>
                            <p>
                                Sinulla on {
                                    existingBundles
                                        .items
                                        .reduce((acc, cur) => acc + (cur.amount - cur.played_amount), 0)
                                } pelaamatonta arpaa arpapaketissa.
                            </p>
                        </>
                    )
            }

            { (existingBundles.items.length === 0 ||
               existingBundles.items.every((bundle) => bundle.amount === bundle.played_amount)) &&
              existingTickets && <SingleInfo existingTickets={existingTickets}/>
            }

        </>
    );
};


const SingleActions = ({
    lottery,
    existingTickets,
    history,
    slug,
}) => {
    let discount = lottery.playable_ticket_discount;

    if(existingTickets && existingTickets.discount_used) {
        discount = 0;
    }

    return (
        <>
            {
                (!existingTickets || existingTickets.playable_tickets.length === 0) ? (
                    <Button onClick={() => {
                        history.push(`/pelattavat/${slug}/pelaa`, {
                            purchaseAmount: 1
                        });

                        /*
            fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.id}/playable-tickets?amount=1`, {
                method: 'POST',
            }, [402])
                .then(() => {
                })
                .catch((error) => {
                    if(error.status === 402) {
                        showMessage('Lisää saldoa arpapaketin ostoa varten', false);
                        history.push(`/tili/lisaa-saldoa?amount=${lottery.playable_ticket_bundle_size * lottery.playable_ticket_prices[0].price}`, {
                            redirect_to: `/pelattavat/${slug}`
                        });
                    }
                });
            */
                    }}>
                        Osta arpa ({
                            formatCurrency(
                                lottery.playable_ticket_prices[0].price - discount
                            )
                        } €)
                    </Button>
                ) : (
                    <Button fontSize='1.4em' onClick={() => {
                        history.push(`/pelattavat/${slug}/pelaa`, {
                            purchaseAmount: 1,
                            demoGame: false
                        });
                    }}>



            Siirry pelaamaan
                    </Button>
                )
            }
        </>
    );

};

const BundlesActions = ({
    lottery,
    existingBundles,
    existingTickets,
    history,
    slug,
    disabled,
    setDisabled,
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    if(!existingTickets || !existingBundles) {
        return null;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '55%' }}>
            { existingBundles.items.length === 0 &&
              existingTickets.playable_tickets.length === 0 &&
                    (
                        <Button onClick={() => {
                            history.push(`/pelattavat/${slug}/pelaa`, {
                                purchaseAmount: lottery.playable_ticket_bundle_size
                            });

                            /*
                            fetch(`${config.backendAddress}/api/v1/lotteries/${lottery.id}/playable-tickets?amount=${lottery.playable_ticket_bundle_size}`, {
                                method: 'POST',
                            }, [402])
                                .then(() => {
                                    history.push(`/pelattavat/${slug}/pelaa`, {
                                        purchaseAmount: lottery.playable_ticket_bundle_size
                                    });
                                })
                                .catch((error) => {
                                    if(error.status === 402) {
                                        showMessage('Lisää saldoa arpapaketin ostoa varten', false);
                                        history.push(`/tili/lisaa-saldoa?amount=${lottery.playable_ticket_bundle_size * lottery.playable_ticket_prices[0].price}`, {
                                            redirect_to: `/pelattavat/${slug}`
                                        });
                                    }
                                });
                            */
                        }}>
                            Osta arpapaketti (
                            {
                                formatCurrency(
                                    (lottery.playable_ticket_bundle_size *
                                     lottery.playable_ticket_prices[0].price)
                                        - (existingTickets.discount_used ? 0 : lottery.playable_ticket_discount))
                            } €)
                        </Button>
                    )
            }

            {
                existingBundles.items.length > 0 &&
                    existingBundles.items.every((bundle) => bundle.amount === bundle.played_amount) &&
                    (
                        <SingleActions
                            lottery={lottery}
                            existingTickets={existingTickets}
                            history={history}
                            slug={slug}
                        />
                    )
            }

            {
                existingBundles.items.length > 0 &&
                    existingBundles.items.some((bundle) => bundle.amount !== bundle.played_amount) &&
                    (
                        <Button fontSize='1.4em' onClick={() => {
                            history.push(`/pelattavat/${slug}/pelaa`, {
                                purchaseAmount: lottery.playable_ticket_bundle_size
                            });
                        }}>
                            Siirry pelaamaan
                        </Button>
                    )
            }

            {
                existingBundles.items.length === 0 &&
                existingTickets.playable_tickets.length > 0 &&
                    (
                        <SingleActions
                            lottery={lottery}
                            existingTickets={existingTickets}
                            history={history}
                            slug={slug}
                        />
                    )

            }

            <SubscriptionDialog open={dialogOpen} setDialogOpen={setDialogOpen}/>
        </div>
    );
};

const PlayableLotteryInfo = ({
    history,
    location,
    app: { cms },
    match: { params: { slug } },
    user: { accounts, authenticated },
    userActions: { login, refreshAccounts, register }
}) => {
    const [{ data: lottery, loading: lotteryLoading }] = useFetch(`${config.backendAddress}/api/v1/lotteries/by-slug/${slug}`);
    const [{ data: existingTickets, refresh: refreshExistingTickets }, setExistingTicketsUrl] = useFetch();
    const [{ data: existingBundles, refresh: refreshExistingBundles }, setExistingBundlesUrl] = useFetch();

    const [endOfMonth, setEndOfMonth] = useState();
    const [now, setNow] = useState();
    const [timeUntilEndOfMonth, setTimeUntilEndOfMonth] = useState();

    const [playableSaldo, setPlayableSaldo] = useState(0);
    const [totalSaldo, setTotalSaldo] = useState(0);

    const [description, setDescription] = useState('');
    const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);

    const [bottomDesription, setBottomDescription] = useState(null);

    const [purchaseAmount, setPurchaseAmount] = useState();

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if(accounts && accounts.length > 0) {
            setPlayableSaldo(
                accounts.reduce((acc, cur) => {
                    if(cur.variety === 'money' ||
                       cur.variety === 'extra_wins' ||
                       cur.variety === 'game_currency') {
                        return acc + cur.saldo;
                    }
                    return acc;
                }, 0));
            setTotalSaldo(
                accounts.reduce((acc, cur) => {
                    if(cur.variety === 'money' ||
                       cur.variety === 'wins' ||
                       cur.variety === 'extra_wins'
                       || cur.variety === 'game_currency') {
                        return acc + cur.saldo;
                    }
                    return acc;
                }, 0));
        }
    }, [accounts]);

    useEffect(() => {
        if(endOfMonth && now) {
            const newTimeUntilEndOfMonth = moment.duration(endOfMonth - now);
            if(newTimeUntilEndOfMonth.as('seconds') >= 0) {
                setTimeUntilEndOfMonth(newTimeUntilEndOfMonth);
            }
        }
    }, [endOfMonth, now]);

    useEffect(() => {
        setEndOfMonth(moment().endOf('month'));
        setNow(moment());
        //TEST_FAKE_NOW = moment().endOf('month').subtract({ seconds: 5 });
        //setNow(TEST_FAKE_NOW);

        interval = setInterval(() => {
            setNow(moment());

            //TEST_FAKE_NOW.add({ seconds: 1 });
            //setNow(TEST_FAKE_NOW.clone());

        }, 1000);

        return () => {
            if(interval) {
                clearInterval(interval);
                interval = null;
            }
        };
    }, [setNow]);

    useEffect(() => {
        if(location) {
            const parsedValue = parseInt(queryString.parse(location.search).purchase_amount, 10);
            if (!Number.isNaN(parsedValue)) {
                setPurchaseAmount(parsedValue);
            }
        }
    }, [location]);

    useEffect(() => {
        if(authenticated && lottery) {
            setExistingBundlesUrl(`${config.backendAddress}/api/v1/lotteries/${lottery.id}/playable-ticket-bundles?current_month=true`);
            setExistingTicketsUrl(`${config.backendAddress}/api/v1/playable-tickets?state=purchased&lottery_id=${lottery.id}`);
            refreshAccounts();
        }
    }, [authenticated, lottery, setExistingTicketsUrl, setExistingBundlesUrl, refreshAccounts]);

    useEffect(() => {
        if(!slug || !cms || !cms.playable_lotteries) return;
        const index = cms.playable_lotteries.findIndex((item) => item.slug === slug);
        if(index === -1) return;
        setDescription(cms.playable_lotteries[index]['description']);
        setBottomDescription(cms.playable_lotteries[index]['bottom_description']);
    }, [slug, cms]);

    const beacon = document.getElementById('beacon-container');
    if(beacon) {
        beacon.hidden = true;
    }

    useEffect(() => {
        return () => {
            const beacon = document.getElementById('beacon-container');
            if(beacon) {
                beacon.hidden = false;
            }
        };
    }, []);

    return (
        <Page>
            {authenticated && (
                <PageTitleAndBalance>
                    <Title height='100%'>Pelaa</Title>
                    <Balances
                        history={history}
                        titleText1='Pelattavissa'
                        titleText2='Saldo'
                        value1={playableSaldo}
                        value2={totalSaldo}
                    />
                </PageTitleAndBalance>
            )}

            <Page.Content flex>
                {!lotteryLoading && lottery && (
                    <ItemPage>
                        <ItemPage.Image src={getImageValue(lottery.images, 'large')} />
                        <ItemPage.Content minHeight='500px' style={{ padding: '2%' }}>
                            <ItemPage.Title>
                                {lottery && getLangValue(lottery.names, 'fi')}
                                {description && (
                                    <Info
                                        onClick={
                                            () => {
                                                document
                                                    .getElementById('playableLotteryInfoDialog')
                                                    .scroll(0,0);
                                                setDescriptionDialogOpen(true);
                                            }
                                        } >
                                        <InfoIcon/>
                                        <span>&nbsp;INFO</span>
                                    </Info>
                                )}
                            </ItemPage.Title>
                            <ItemPage.Description>
                                <ItemPage.Text>
                                    {lottery && getLangValue(lottery.descriptions, 'fi')}
                                    {
                                        lottery.playable_ticket_purchase_variety === PURCHASE_VARIETY_SINGLE_ONLY &&
                                            <SingleInfo existingTickets={existingTickets}/>
                                    }

                                    {
                                        lottery.playable_ticket_purchase_variety === PURCHASE_VARIETY_MONTHLY_BUNDLE_FIRST &&
                                            <BundleInfo
                                                lottery={lottery}
                                                existingBundles={existingBundles}
                                                existingTickets={existingTickets}
                                                timeUntilEndOfMonth={timeUntilEndOfMonth}
                                            />
                                    }

                                    {
                                        lottery &&
                                        lottery.playable_ticket_discount > 0 &&
                                            existingTickets &&
                                            existingTickets.discount_used === false &&
                                            <DiscountInfo>
                                                <div>Ensimmäinen {lottery.playable_ticket_purchase_variety === PURCHASE_VARIETY_SINGLE_ONLY ? 'arpa': 'arpapaketti'} {formatCurrency(lottery.playable_ticket_discount)} € alennuksella!</div>
                                                <div>(Normaali hinta {lottery.playable_ticket_bundle_size * formatCurrency(lottery.playable_ticket_prices[0].price)} €)</div>
                                            </DiscountInfo>
                                    }

                                    {
                                        lottery.playable_ticket_purchase_variety === PURCHASE_VARIETY_PREFER_MONTHLY_BUNDLE && (
                                            purchaseAmount === 1 ?
                                                <SingleInfo existingTickets={existingTickets}/> :
                                                <BundleInfo
                                                    lottery={lottery}
                                                    existingBundles={existingBundles}
                                                    existingTickets={existingTickets}
                                                    timeUntilEndOfMonth={timeUntilEndOfMonth}
                                                />
                                        )
                                    }
                                </ItemPage.Text>
                            </ItemPage.Description>

                            <ItemPage.Actions>
                                {authenticated && lottery.playable_ticket_purchase_variety === PURCHASE_VARIETY_SINGLE_ONLY &&
                                 (
                                     existingTickets &&
                                     <>
                                         <SingleActions
                                             lottery={lottery}
                                             existingTickets={existingTickets}
                                             history={history}
                                             slug={slug}
                                         />
                                     </>
                                 )
                                }

                                {authenticated && lottery.playable_ticket_purchase_variety === PURCHASE_VARIETY_MONTHLY_BUNDLE_FIRST &&
                                 (
                                     existingBundles &&
                                         <BundlesActions
                                             lottery={lottery}
                                             existingBundles={existingBundles}
                                             existingTickets={existingTickets}
                                             history={history}
                                             slug={slug}
                                             disabled={disabled}
                                             setDisabled={setDisabled}
                                         />
                                 )
                                }

                                {
                                    authenticated && lottery.playable_ticket_purchase_variety === PURCHASE_VARIETY_PREFER_MONTHLY_BUNDLE && (
                                        purchaseAmount === 1 ?
                                            (
                                                <SingleActions
                                                    lottery={lottery}
                                                    existingTickets={existingTickets}
                                                    history={history}
                                                    slug={slug}
                                                />
                                            ) : (
                                                <BundlesActions
                                                    lottery={lottery}
                                                    existingBundles={existingBundles}
                                                    existingTickets={existingTickets}
                                                    history={history}
                                                    slug={slug}
                                                    disabled={disabled}
                                                    setDisabled={setDisabled}
                                                />
                                            )
                                    )
                                }

                                {!authenticated &&
                                 <>
                                     <Button onClick={() => login()}>Kirjaudu</Button>
                                     <Button onClick={() => register()}>Uusi tili</Button>
                                 </>
                                }
                            </ItemPage.Actions>
                            <div style={{ display: 'flex', justifyContent: 'left', marginRight: 'auto', marginLeft: 'auto' }}>
                                <TextButton style={{ width: '250px' }} onClick={() => {
                                    history.push(`/pelattavat/${slug}/pelaa`, {
                                        purchaseAmount: 1,
                                        demoGame: true
                                    });
                                }}>
                                    Kokeile leikkirahalla
                                </TextButton>
                            </div>

                        </ItemPage.Content>
                    </ItemPage>
                )}
                {lotteryLoading && <Spinner />}

                {bottomDesription &&
                 <div
                     style={{ flex: 1, margin: 0 }}
                     dangerouslySetInnerHTML={{
                         __html: bottomDesription
                     }}
                 >
                 </div>
                }
            </Page.Content>

            <Dialog
                open={descriptionDialogOpen}
                bg='white'
                maxHeight='90vh'
                maxWidth='90%'
                overflowX='scroll'
                id='playableLotteryInfoDialog'
            >
                <Dialog.Header
                    bg='white'
                    logoVisible={false}
                >
                    <CloseIcon
                        style={{ cursor: 'pointer' }}
                        onClick={(event) => {
                            event.stopPropagation();
                            setDescriptionDialogOpen(false);
                        }}
                    />
                </Dialog.Header>

                <Dialog.Content
                    padding='2em'
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: description
                        }}
                    >
                    </div>
                </Dialog.Content>

                <Dialog.Actions>
                    <Button onClick={(event) => {
                        event.stopPropagation();
                        setDescriptionDialogOpen(false);
                    }}>
                        Sulje
                    </Button>
                </Dialog.Actions>
            </Dialog>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ConnectedPlayableLotteryInfo = connect(mapStateToProps, mapDispatchToProps)(PlayableLotteryInfo);

export { ConnectedPlayableLotteryInfo as PlayableLotteryInfo };
