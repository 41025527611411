import * as Cookies from 'js-cookie';

const getClaimHeaders = function(headers_) {
    const headers = headers_ || {};
    const claimToken = Cookies.get('arpamaailma-claim-token100');
    if(claimToken) {
        headers['Arpamaailma-Claim-Token'] = claimToken;
    }

    return headers;
};

export { getClaimHeaders };
