import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from './store/actions';
import * as appActions from './store/appActions';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Layout, Notifier } from './common';
import i18n from './i18n';

import * as Cookies from 'js-cookie';
import * as CookieConsent from 'vanilla-cookieconsent';

// Routes
import { LandingPage } from './landing';
import { Lotteries, Lottery, ThanksRedeem, ThanksExtraLottery } from './lottery';
import { PlayableLotteries, PlayableLotteryInfo, PlayableLottery } from './playable';
import { Shop, Product } from './shop';
import { Cart, Thanks } from './cart';
import { Account } from './account';
import { Terms, Privacy, Cookies as CookiesPage } from './terms';
import { Spinner } from './common/Spinner';
import { getShopUrl } from './common/functions';

import config from './config';

const App = ({
    appActions: { getCMSData, getCustomJS, getBonusSelf },
    user: { authenticated },
    userActions: { loadShopCart, refreshAccounts }
}) => {
    const [dreamshopLoading, setDreamshopLoading] = useState(true);

    useEffect(() => {
        if (authenticated) {
            refreshAccounts();
            loadShopCart();
        }

        CookieConsent.run({
            categories: {
                necessary: {
                    enabled: true,
                    readOnly: true
                },
                analytics: {}
            },
            guiOptions: {
                consentModal: {
                    layout: 'box',
                    position: 'bottom right',
                    flipButtons: false,
                    equalWeightButtons: false
                }
            },
            onFirstConsent: ({ cookie }) => {
                window.location.reload();
            },
            onConsent: ({ cookie }) => {
                // Looks like we have to call removal here on page
                // load in case the user has removed consent to all
                // cookies.
                const index = cookie?.categories?.indexOf('analytics');
                if (typeof index === 'number' && index === -1) {
                    const cookies = Cookies.get();
                    Object.keys(cookies).forEach((key) => {
                        if(key.startsWith('_ga') || key.startsWith('_gid')) {
                            Cookies.remove(key, { path: '/', domain: document.domain });
                        }
                    });
                }
            },
            onChange: ({ cookie, changedCategories, changedServices }) => {
                window.location.reload();
            },
            language: {
                default: 'fi',
                translations: {
                    fi: {
                        consentModal: {
                            title: '',
                            description: 'Käytämme evästeitä parantaaksemme käyttökokemustasi ja palveluamme. Osa evästeistä on välttämättömiä palvelun toiminnan kannalta. Alta voit hyväksyä kaikki tai vain välttämättömät evästeet. Lue lisää <a  href="/tietosuoja">tietosuojaselosteesta</a>.',
                            acceptAllBtn: 'Hyväksy kaikki',
                            acceptNecessaryBtn: 'Hyväksy vain välttämättömät',
                        }
                    }
                }
            }
        });

        window.Beacon('init', config.beacon.id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getCMSData();
    }, [getCMSData]);

    useEffect(() => {
        getCustomJS();
    }, [getCustomJS]);

    useEffect(() => {
        getBonusSelf();
    }, [getBonusSelf]);

    return (
        <>
            <Notifier />
            <Layout>
                <Switch>
                    <Route exact path='/' component={LandingPage} />

                    <Route path='/voittokauppa/:id' component={Product} />
                    <Route path='/voittokauppa' component={Shop} />

                    {/*<Route path='/lunastettavat/:slug/voittokauppa/:id' component={Prize} />*/}
                    {/*<Route path='/lunastettavat/:slug/voittokauppa' component={Prizes} />*/}

                    <Route path='/ostoskori' component={Cart} />
                    <Route path='/success' component={Thanks} />
                    <Route path='/success-extra' component={ThanksExtraLottery} />
                    <Route path='/success-redeem' component={ThanksRedeem} />
                    <Route path='/tili' component={Account} />
                    <Route path='/ehdot' component={Terms} />
                    <Route path='/tietosuoja' component={Privacy} />
                    <Route path='/evasteet' component={CookiesPage} />
                    {/* This has to be last so any non-reserved url goes to custom lottery page */}
                    <Route path='/lunastettavat/:slug' render={(props) => (
                        <Lottery {...props} />
                    )} />
                    {config.playableTicketsEnabled &&
                     <Route path='/pelattavat/:slug/pelaa' component={PlayableLottery}/>
                    }
                    {config.playableTicketsEnabled &&
                     <Route path='/pelattavat/:slug' component={PlayableLotteryInfo}/>
                    }
                    {config.playableTicketsEnabled &&
                    <Route path='/pelattavat' exact component={PlayableLotteries}/>
                    }
                    <Route path='/lunastettavat' exact component={Lotteries}/>
                </Switch>
            </Layout>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch),
        appActions: bindActionCreators({ ...appActions }, dispatch),
    };
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export { ConnectedApp as App };
