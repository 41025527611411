import styled from 'styled-components';
import { Input } from './Input';
import { Label } from './Label';

const Group = styled.div`
    display: inline-block;
    position: relative;
    margin: 1rem 1rem 2rem 1rem;
    line-height: 24px;

    ${Input}:checked + ${Label} {
        &:before {
            background-color: ${(props) => props.theme.success};
            border-color: ${(props) => props.theme.success};
        }

        &:after {
            border-color: #395064;
        }
    }

    @media (max-width: 700px) {
        margin: 1rem 5% 2rem 5%;
    }
`;

export { Group };
