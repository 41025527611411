import styled from 'styled-components';

const Card = styled.div`
    position: relative;
    background-color: ${(props) => props.bgColor || props.theme.backgroundCard };
    background-image: ${(props) => props.src ? `url(${props.src})` : ''};
    background-size: ${(props) => props.bgSize || 'cover'};
    background-repeat: no-repeat;
    border-radius: ${(props) => props.borderRadius || '0px'};
    border: 0;
    box-shadow: 0px 2px 7px rgba(0,0,0,0.5);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: ${(props) => props.noGradient ? '' : 'linear-gradient(to top, black, transparent, transparent)'};
        border-radius: ${(props) => props.borderRadius || '0px'};
        border: 0;
    }
`;

export { Card };
