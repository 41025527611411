import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spinner } from '../Spinner';
import { fetchWrap as fetch } from '../functions';

const StyledForm = styled.form`
    position: relative;
    display: grid;
    position: relative;
    padding: ${(props) => props.padding || '5%'};

`;

class Form extends Component {

    state = {
        isLoading: false,
        error: null
    }

    submit = (event) => {
        if (this.props.native) {
            return;
        }
        event.preventDefault();
        if (typeof this.props.action == 'function') {
            this.props.action(this.formToJson(event.target));
        } else {
            event.target.style.height = event.target.offsetHeight + 'px';
            this.setState({ isLoading: true, error: null });
            fetch(this.props.action, {
                method: this.props.method || 'POST',
                body: !this.props.enctype || this.props.enctype === 'application/json' ?
                    JSON.stringify(this.formToJson(event.target)) : new FormData(event.target),
                headers: {
                    'Content-Type': this.props.enctype || 'application/json'
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    this.setState({ isLoading: false, error: null });
                    if (this.props.onResponse) {
                        this.props.onResponse(json);
                    }
                })
                .catch((error) => {
                    this.setState({ isLoading: false, error: error });
                    if (this.props.onError) {
                        this.props.onError(error);
                    }
                });
        }
        event.preventDefault();
    }

    formToJson = (form) => {
        const body = {};
        for (const pair of new FormData(form)) {
            if (pair[1] === 'on') {
                body[pair[0]] = true;
            } else {
                body[pair[0]] = pair[1];
            }
        }
        return body;
    }

    render() {
        const { children, withSpinner, action, onError, onResponse, ...props } = this.props;
        const { isLoading, error } = this.state;
        return (
            <StyledForm {...props} action={typeof action === 'string' ? action : ''} onSubmit={this.submit} error={error}>
                {withSpinner && isLoading ? <Spinner /> : children}
            </StyledForm>
        );
    }
}




Form.propTypes = {
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    method: PropTypes.string,
    enctype: PropTypes.string,
    native: PropTypes.bool,
    withSpinner: PropTypes.bool,
    onResponse: PropTypes.func,
    onError: PropTypes.func
};

export { Form };
