import React from 'react';
import styled from 'styled-components';
import { Button, ItemPage, Select, Spinner } from '../common';
import { useFetch } from '../common/hooks';
import { getDescriptionValue, getLangValue, formatCurrency } from '../common/functions';
import config from '../config';

const Prize = (props) => {
    if (props.prize && props.prize.product_id) {
        if (props.prize.product_claimable) {
            return <Win {...props} />;
        } else {
            return <WinUnclaimable {...props} />;
        }
    } else {
        return <NoWin {...props} />;
    }
};

const ProductOptions = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row: 2 / 2;
    grid-column-gap: 0.5em;
    grid-row-gap: 0.5em;
    justify-self: center;
`;

const ProductOption = styled.div`
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15em;

    & label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 1.3em;
        color: black;
        background: ${(props) => `url(${props.image}) no-repeat center` || 'none'};
    }

    & input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    & input[type="radio"]:checked + label {
        color: white;
        background-color: gray;
    }
`;

const Win = ({ prize, authenticated, onSelectPrize, onEnterNew, onRedeemPrize, onChangeOption, option }) => {
    const [{ data: prizeData, loading }] = useFetch(`${config.backendAddress}/api/v1/products/${prize.product_id}`);
    const [{ data: optionsData }] = useFetch(`${config.backendAddress}/api/v1/products/${prize.product_id}/options`);
    return (
        <>
            {!loading && prizeData && (
                <>
                    {authenticated && prize.product_value_transferrable ? (
                        <>
                            <ItemPage.Title>Voitto</ItemPage.Title>
                            <ItemPage.Description>
                                <ItemPage.Text>
                                    <h2>{getLangValue(prizeData.names, 'fi')}</h2>
                                    <p>{getDescriptionValue(prizeData.descriptions, 'fi')}</p>
                                    <p>Voitettu summa on lisätty saldoosi.</p>
                                </ItemPage.Text>
                            </ItemPage.Description>
                            <ItemPage.Actions>
                                <Button onClick={onSelectPrize}>Valitse voitto</Button>
                                <Button onClick={onEnterNew}>Syötä uusi</Button>
                            </ItemPage.Actions>
                        </>
                    ) : (
                        <>
                            <ItemPage.Title>Voitto</ItemPage.Title>
                            <ItemPage.Description>
                                <ItemPage.Text>
                                    <h2>{getLangValue(prizeData.names, 'fi')}</h2>
                                    <p>{getLangValue(prizeData.descriptions, 'fi')}</p>
                                </ItemPage.Text>
                                {optionsData && optionsData.length > 0 &&
                                 <>
                                     <span style={{ fontWeight: 400, fontSize: '1.1em' }}>Valitse:</span>
                                     <ProductOptions>
                                         {optionsData.map((item) => (
                                             <ProductOption image={ item?.images[0]?.source }>
                                                 <input onClick={() => onChangeOption(item.id)} id={item.id} type='radio' name='product_option' />
                                                 <label for={item.id}>
                                                     {getLangValue(item.names, 'fi')}
                                                 </label>
                                             </ProductOption>
                                         ))}
                                     </ProductOptions>
                                 </>
                                }
                            </ItemPage.Description>
                            <ItemPage.Actions>
                                <Button disabled={optionsData?.length > 0 && !option} onClick={onRedeemPrize}>Lunasta</Button>
                            </ItemPage.Actions>
                        </>
                    )}
                </>
            )}
            {loading && <Spinner />}
        </>
    );
};

const WinUnclaimable = ({ prize, authenticated, onRedeemPrize, onEnterNew }) => {
    const [{ data: prizeData, loading }] = useFetch(`${config.backendAddress}/api/v1/products/${prize.product_id}`);
    return (
        <>
            {prizeData && !loading ? (
                <>
                    <ItemPage.Title>Voitto</ItemPage.Title>
                    <ItemPage.Description>
                        <ItemPage.Text>
                            <h2>{getLangValue(prizeData.names, 'fi')}</h2>

                            <p>{getDescriptionValue(prizeData.descriptions, 'fi')}</p>
                            <p>{authenticated ? 'Olemme sinuun yhteydessä.' : 'Anna meille vielä yhteystietosi, niin voimme olla sinuun yhteydessä.'}</p>

                        </ItemPage.Text>
                        <ItemPage.Actions>
                            {authenticated ? (
                                <Button onClick={onEnterNew}>Valmis</Button>
                            ) : (
                                <Button onClick={onRedeemPrize}>Jatka antamaan yhteystietoja</Button>
                            )}

                        </ItemPage.Actions>
                    </ItemPage.Description>
                </>
            ) : <Spinner />}
        </>
    );
};

const NoWin = ({ lottery, prize, authenticated, onRedeemPrize, onEnterNew }) => {
    return (
        <>
            <ItemPage.Description>
                <ItemPage.Text>Tällä arvalla ei ole voittoa.</ItemPage.Text>
                {lottery.extra_lottery_enabled && <ItemPage.Text>{
                    authenticated
                        ? 'Olet osallistunut lisäarvontaan!'
                        : (prize.can_partake_in_extra_lottery ? 'Voit vielä osallistua lisäarvontaan.' : '')
                }</ItemPage.Text>
                }
            </ItemPage.Description>
            <ItemPage.Actions>
                {lottery.extra_lottery_enabled
                 && !authenticated
                 && prize.can_partake_in_extra_lottery
                 && <Button onClick={onRedeemPrize}>Osallistu lisäarvontaan</Button>
                }
                <Button onClick={onEnterNew}>Syötä uusi</Button>
            </ItemPage.Actions>
        </>
    );
};

export { Prize };
