import React from 'react';
import moment from 'moment';
import { IncrementalList } from '../common';
import SubsectionHeader from '../common/AccountSubsectionHeader';
import { getLangValue, formatCurrency } from '../common/functions';
import config from '../config';
import i18n from '../i18n';

const getItemCmp = (item) => {
    const product_name = item.product_names ? getLangValue(item.product_names, 'fi') : null;
    const product_option_name = item.product_option_names ? getLangValue(item.product_option_names, 'fi') : null;

    return ([
        <td key='lotteryName'>{getLangValue(item.lottery_names, 'fi')} {item.ticket_series_alias}</td>,
        <td key='played'>{item.claimed ? '' : 'Kesken'}</td>,
        <td key='product'>
            {
                item.product_value_transferrable
                    ? `${formatCurrency(item.product_value)} €`
                    : `${product_name ? `${product_name}` : ''}${product_option_name ? `, ${product_option_name}` : ''}`
            }
            {item.prize_code ? <b>{item.prize_code}</b> : ''}
        </td>,
        <td key='timestamp'>{moment.utc(item.commited_at || item.created_at).local().format('DD.MM.YYYY HH:mm')}</td>,
    ]);
};

const getListHeader = () => {
    return (
        <>
            <th key='headerLotteryName'>{i18n.gettext('lottery')}</th>
            <th key='headerState'>{i18n.gettext('state')}</th>
            <th key='headerValue'>{i18n.gettext('value')}</th>
            <th key='headerTimestamp'>{i18n.gettext('timestamp')}</th>
        </>
    );
};

const Winnings = ({ history }) => {
    return (
        <>
            <SubsectionHeader sectionTitle='Arpojen Rekisteröinnit'/>
            <IncrementalList
                url={`${config.backendAddress}/api/v1/claims`}
                getItemCmp={getItemCmp}
                getListHeader={getListHeader}
                noItemsMessage='Ei voittoja'
            />
        </>
    );
};

export { Winnings };
