import React, { useEffect, useState } from 'react';

const DepositBonusContainer = ({ cms, myBonuses }) => {
    const [depositBonusDescription, setDepositBonusDescription] = useState('');

    useEffect(() => {
        setDepositBonusDescription(
            cms &&
                cms.playableLotteries &&
                cms.playableLotteries.depositBonus &&
                cms.playableLotteries.depositBonus.description || '');
    }, [cms, setDepositBonusDescription]);

    if (!myBonuses) return <></>;

    if (myBonuses.deposit_bonus_received) return <></>;

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: depositBonusDescription
            }}
        >
        </div>
    );
};

export { DepositBonusContainer };
