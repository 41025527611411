import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Drawer as DrawerBase, Button, Cart } from '../../common';
import { getShopUrl } from '../functions';
import * as userActions from '../../store/actions';

import { Close, User } from '../Icons';

import config from '../../config';

const DrawerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0.5em 1em;

    > * {
        display: flex;
        align-items: center;
    }
`;

const CloseButton = styled.div`
    color: ${(props) => props.theme.primary};
    fill: ${(props) => props.theme.primary};
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    cursor: pointer;
`;

const UserIcon = styled(User)`
    fill: ${(props) => props.theme.primary};
`;

const Username = styled.div`
    margin: 0;
    color: black;
    margin-left: 1em;
`;

const Login = styled.div`
    text-transform: uppercase;
    color: ${(props) => props.theme.primary};
    cursor: pointer;
    font-weight: bold;
`;

const Link = styled(NavLink)`
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    white-space: nowrap;
    color: ${(props) => props.theme.primary};
    padding: 1em;
    border-bottom: 5px transparent solid;
    &.active {
        background: ${(props) => props.theme.primary};
        color: white;
        border-bottom: 5px ${(props) => props.theme.primary} solid;
    }
`;

const ExternalLink = styled.a`
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 0.1em;
    white-space: nowrap;
    color: ${(props) => props.theme.primary};
    padding: 1em;
    border-bottom: 5px transparent solid;
    &.active {
        background: ${(props) => props.theme.primary};
        color: white;
        border-bottom: 5px ${(props) => props.theme.primary} solid;
    }
`;

const LinkNoNav = styled.div`
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    white-space: nowrap;
    color: ${(props) => props.theme.primary};
    padding: 1em;
    border-bottom: 5px transparent solid;
    cursor: pointer;
    &.active {
        background: ${(props) => props.theme.primary};
        color: white;
        border-bottom: 5px ${(props) => props.theme.primary} solid;
    }
`;

const CartBalanceWrapper = styled.div`
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Drawer = ({
    history,
    location,
    user: { first_name, last_name, menuOpen, authenticated },
    userActions: { login, logout, refreshAccounts, register, closeMenu }
}) => {

    return (
        <DrawerBase open={menuOpen}>
            <DrawerHeader>
                {authenticated &&
                    <div>
                        <UserIcon />
                        <Username>{first_name} {last_name}</Username>
                    </div>
                }
                {!authenticated && <Login onClick={() => login()}>Kirjaudu</Login>}
                <CloseButton onClick={() => closeMenu()}>
                    Sulje
                    <Close />
                </CloseButton>
            </DrawerHeader>

            <CartBalanceWrapper>
                {authenticated && <Cart onCartClick={() => {
                    closeMenu();
                    history.push('/ostoskori');
                }}/>}
            </CartBalanceWrapper>

            {
                <>
                    <Link exact to='/' onClick={() => closeMenu()}>Etusivu</Link>
                    {config.playableTicketsEnabled &&
                     <Link to='/pelattavat' onClick={() => closeMenu()}>Pelaa arpoja</Link>
                    }
                    <Link to='/lunastettavat' onClick={() => closeMenu()}>Muut arvat</Link>

                    <Link to='/voittokauppa' onClick={() => closeMenu()}>Voittokauppa</Link>

                    {!authenticated && (
                        <LinkNoNav onClick={() => register()}>Luo tili</LinkNoNav>
                    )}
                    {authenticated && (
                        <>
                            <Link to='/tili' onClick={() => {
                                refreshAccounts();
                                closeMenu();
                            }}>
                                Oma tili
                            </Link>
                        </>
                    )}
                </>
            }
            {authenticated && <Button.Warning style={{ margin: '1em' }} onClick={() => logout()}>Kirjaudu ulos</Button.Warning>}
        </DrawerBase>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ConnectedDrawer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Drawer));

export { ConnectedDrawer as Drawer };
