import styled from 'styled-components';

const Title = styled.div`
    text-align: left;
    color: ${(props) => props.theme.secondaryText}
    font-size: 2em;
    font-weight: 500;
    letter-spacing: 0.1em;
    margin-bottom: 1em;

    @media (max-width: 700px) {
        text-align: center;
        margin-bottom: 0.5em;
    }
`;

export { Title };
