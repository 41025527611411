import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { connect } from 'react-redux';
import { Page, Footer } from '../common';
import { Cards } from './Cards';
import { Slideshow } from './Slideshow';

const LandingPage = ({ history, app: { cms }, theme }) => {
    const themeContext = useContext(ThemeContext);

    return (
        <Page>
            <Page.Content bgColor={themeContext.darkroom}>
                <div style={{ minHeight: 'calc(100vh - 200px)' }}>
                    <Slideshow slides={
                        cms
                            && cms.landingPage
                            && cms.landingPage.slides
                    }
                    />
                    <Cards
                        history={history}
                        cms={cms}
                    />
                </div>
                <Footer />
            </Page.Content>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        app: state.app
    };
};

const ConnectedLandingPage = connect(mapStateToProps)(LandingPage);

export { ConnectedLandingPage as LandingPage };
