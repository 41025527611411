import React from 'react';
import styled from 'styled-components';
import { Title } from './Title';
import { Amount } from './Amount';
import { Icon } from './Icon';

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => props.reverse ? 'row-reverse' : 'row'};

    margin-left: 15px;
    margin-right: 10px;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        margin-left: 5px !important;
        margin-right: 35px !important;
    }

    > ${InfoWrapper} {
        ${(props) => props.reverse ? `
            margin-right: 1em;
        ` : `
            margin-left: 1em;
        `}
    }
`;

const Cart = ({ items, onCartClick, ...props }) => {
    return (
        <Wrapper {...props}>
            <Icon />
            <InfoWrapper>
                <Title onClick={onCartClick}>Ostoskori</Title>
                {items && items.length > 0 && <Amount>{items.length} {items.length === 1 ? 'tuote' : 'tuotetta'}</Amount>}
            </InfoWrapper>
        </Wrapper>
    );
};

export { Cart };
