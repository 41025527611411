import styled from 'styled-components';

const Actions = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || 'left'};
    margin-top: 1em;

    > * {
        width: 250px;
        margin-left: 5%;
    }

    > *:first-child {
        margin-left: 0;
    }

    @media (max-width: 900px) {
        flex-direction: column;
        flex-shrink: 0;
        margin-bottom: 3em;
        height: auto;
        margin-top: 1em;
        font-size: 1.2em;

        > * {
            width: 100%;
            max-width: none;
            margin-top: 5%;
            margin-left: 0;
        }
    }
`;

export { Actions };
