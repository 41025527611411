import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import styled from 'styled-components';
import * as Cookies from 'js-cookie';

import { Auth, Button, showMessage } from '../common';
import SubsectionHeader from '../common/AccountSubsectionHeader';
import { PaymentMethods } from '../common/payments';
import { fetchWrap as fetch } from '../common/functions';
import config from '../config';
import * as userActions from '../store/actions';
import i18n from '../i18n';

const Container = styled.div`
    max-width: 1000px;
    margin: 0 auto;
`;

const PaymentOptions = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    margin: 0 2vh 2vh 2vh;
`;

const PaymentOption = styled.div`
    border: ${(props) => props.selected ? '10px' : '2px'}
            ${(props) => props.selected ? props.theme.secondary : props.theme.primary} solid;
    border-radius: 5px;

    height: 8em;
    width: 8em;

    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${(props) => props.src});

    cursor: pointer;
    outline: none;
`;

const PaymentValueButtons = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    padding: 5%;
    width: 100%;
    margin: auto;
`;

const NavButton = styled(Button)`
    margin: 3em auto;
    padding-left: 2em;
    padding-right: 2em;

    font-size: 12pt;

    background: ${(props) => props.bg || 'rgba(153, 0, 102, 1)'};
    color: ${(props) => props.color || 'white'};

    :hover {
        background: ${(props) => props.theme.buttonHover};
        color: ${(props) => props.theme.text};
    }

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        width: 90%;
    }
`;

const PaymentValueButton = styled.button`
    width: 200px;
    right: 0;
    left: 0;
    margin: auto;

    bottom: 16px
    text-transform: uppercase;

    border: 2px ${(props) => props.theme.primary} solid;
    background-color: ${(props) => props.theme.text};
    color: ${(props) => props.theme.textSecondary};

    font-size: 100%;
    font-family: inherit;
    font-weight: 500;
    font-size: 16pt;

    border-radius: 10px;

    padding: 0.7em;
    cursor: pointer;
    transition: background 0.25s;

    outline: none;

    :hover {
        background: ${(props) => props.theme.buttonHover};
        color: ${(props) => props.theme.text};
        border: 2px ${(props) => props.theme.buttonHover} solid;
    }

    &.selected {
        background: ${(props) => props.theme.secondaryDark};
        color: ${(props) => props.theme.text};
        border: 2px ${(props) => props.theme.secondaryDark} solid;
    }
`;

const PaymentValueSlider = styled.input.attrs((props) => ({
    type: 'range',
    min: 0,
    max: 50
}))`
`;

const PaymentValueInput = styled.input`
    border: 2px solid rgb(0,88,150);
    border-radius: 5px;
    font-family: 'Titillium Web';
    font-size: 16pt;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 30px;
    width: 100px;
    text-align: right;
    position: relative;
    bottom: 4px;
`;

const CenteredContainer = styled.div`
    text-align: center;
    color: ${(props) => props.theme.textSecondary};
    font-weight: 400;
    font-size: 1.5em;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        font-size: 2em;
    }
`;

const Title = styled.div`
    color: ${(props) => props.theme.textSecondary};
    font-weight: 500;
    font-size: 1.5em;
    text-align: center;
    margin-top: 1em;

    @media (max-width: ${(props) => props.theme.mobileLimit}) { font-size: 2em; }
`;
const TitleContainer = styled.div`
    background-color: ${(props) => props.theme.tertiary};
    border-radius: 0px;
    color: white;
    display: flex;
    flex-flow: column;
    font-weight: 400;
    padding: 10px;
    padding-bottom: 2em;
`;

const paymentParameterToInput = (param) => <input type='hidden' name={param.name} value={param.value} />;

const AddSaldo = ({ location, history, user, userActions: { saveState } }) => {
    const paymentForm = useRef(null);

    const [paymentDetails, setPaymentDetails] = useState(null);

    const { amount } = queryString.parse(history.location.search);
    const redirect_to = location.state && location.state.redirect_to;
    const [paymentValue, setPaymentValue] = useState(10);
    const [paymentMethod, setPaymentMethod] = useState();
    const stronglyAuthenticated = user.strongly_authenticated;

    const getPaymentOptions = () => {
        return PaymentMethods.map((item) => (
            <PaymentOption
                key={item.id}
                src={item.image}
                selected={paymentMethod === item.id}
                onClick={() => {
                    setPaymentMethod(item.id);
                }}
            />
        ));
    };

    const handleValueInputChange = (value) => {
        const parsed = parseInt(value, 10);
        if (isNaN(parsed)) {
            showMessage('Virheellinen syöte', true);
            setPaymentValue(10);
        } else if(value < 10) {
            showMessage('Pienin summa on 10 €', true);
            setPaymentValue(10);
        } else if(value > 100) {
            showMessage('Suurin summa on 100 €', true);
            setPaymentValue(100);
        } else {
            setPaymentValue(value);
        }
    };

    const getPaymentValueCmp = () => {
        return (
            <div>
                <Title>Valitse summa</Title>
                <PaymentValueButtons>
                    <PaymentValueButton
                        className={paymentValue === 10 && 'selected'}
                        onClick={() => setPaymentValue(10)}
                    >
                            10 €
                    </PaymentValueButton>
                    <PaymentValueButton
                        className={paymentValue === 20 && 'selected'}
                        onClick={() => setPaymentValue(20)}
                    >
                            20 €
                    </PaymentValueButton>
                    <PaymentValueButton
                        className={paymentValue === 50 && 'selected'}
                        onClick={() => setPaymentValue(50)}
                    >
                            50 €
                    </PaymentValueButton>
                    <PaymentValueButton
                        className={paymentValue === 100 && 'selected'}
                        onClick={() => setPaymentValue(100)}
                    >
                            100 €
                    </PaymentValueButton>
                </PaymentValueButtons>

                {false &&
                     <CenteredContainer>
                         10
                         <PaymentValueSlider
                             value={paymentValue}
                             onChange={(event) => setPaymentValue(event.target.value)}
                             onBlur={(event) => handleValueInputChange(event.target.value)}
                         />
                         100
                     </CenteredContainer>}

                <CenteredContainer>
                    <span style={{ marginRight: '10px' }}>Siirrettävä summa:</span>
                    <PaymentValueInput
                        value={paymentValue}
                        onChange={(event) => setPaymentValue(event.target.value)}
                        onBlur={(event) => handleValueInputChange(event.target.value)}
                    />
                    <span style={{ marginLeft: '10px' }}>€</span>
                </CenteredContainer>
            </div>
        );
    };

    useEffect(() => {
        if (paymentDetails) {
            paymentForm.current.submit();
        }
    }, [paymentDetails]);

    const enforceAmount = (amount) => {
        const euros = amount / 100.0;
        if(euros < 10) return 10;
        else if(euros > 100) return 100;
        return Math.ceil(euros);
    };

    useEffect(() => {
        if(amount) {
            setPaymentValue(enforceAmount(amount));
        }
    }, [setPaymentValue, amount]);

    useEffect(() => {
        if(redirect_to) {
            Cookies.set('deposit_redirect_to', redirect_to);
        }
    }, [redirect_to]);

    useEffect(() => {
        if(paymentMethod) {
            if(!paymentValue) {
                showMessage(i18n.gettext('missing_payment_value'), true);
                return;
            }

            if(!paymentMethod) {
                showMessage(i18n.gettext('missing_payment_method'), true);
                return;
            }


            fetch(`${config.backendAddress}/api/v2/accounts/deposit`, {
                method: 'POST',
                body: JSON.stringify({
                    value: paymentValue * 100,
                    payment_method: paymentMethod,
                    subject: `${user.sub}`,
                    name: `${user.first_name} ${user.last_name}`
                }),
            })
                .then((response) => response.json())
                .then((json) => {
                    saveState();
                    setPaymentDetails(json);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        window.sessionStorage.setItem('saldo', (paymentValue).toString());
    }, [saveState, paymentValue, paymentMethod]);

    useEffect(() => {
        const elem = document.getElementById('authbox');
        if(elem) {
            setTimeout(() => elem.scrollIntoView(), 200);
        }
    });

    if(!stronglyAuthenticated) {
        return <Auth/>;
    };

    return (
        <>
            <SubsectionHeader sectionTitle='Lisää saldoa'/>
            <Container>
                {getPaymentValueCmp()}
            </Container>

            <Title>Siirry maksamaan valitsemalla pankkisi</Title>

            <Container>
                <PaymentOptions>
                    {getPaymentOptions()}
                </PaymentOptions>
            </Container>

            {paymentDetails &&
             <form
                 ref={paymentForm}
                 action={paymentDetails.payment_provider.url}
                 method='POST'
                 id='paymentForm'
                 style={{ display: 'none' }}
             >
                 {paymentDetails.payment_provider.parameters.map(paymentParameterToInput)}
             </form>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ConnectedAddSaldo = connect(mapStateToProps, mapDispatchToProps)(AddSaldo);

export { ConnectedAddSaldo as AddSaldo };
