import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as Cookies from 'js-cookie';
import config from '../config';

import { Spinner } from '../common';
import { PaymentMethods } from '../common/payments';
import { fetchWrap as fetch } from '../common/functions';

const PaymentOptions = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    padding: 5%;
    width: 100%;
    max-width: 1000px;
    margin: auto;
`;

const PaymentOption = styled.div`
    border: 0px;
    border-radius: 5px;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${(props) => props.src});
    cursor: pointer;
    padding-bottom: 80%;
    outline: none;
`;

const paymentParameterToInput = (param) => <input type='hidden' name={param.name} value={param.value} />;

const PaymentMethod = ({ saveState, sending, setSending, user, contacts }) => {
    const paymentForm = useRef(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    useEffect(() => {
        if (paymentDetails) {
            paymentForm.current.submit();
        }
    }, [paymentDetails]);
    return (
        <div>
            {sending && <Spinner/>}
            {!sending &&
             <PaymentOptions>
                 {PaymentMethods.map((item) => (
                     <PaymentOption key={item.id} src={item.image} onClick={() => {
                         setSending(true);

                         const products = [];
                         for (const cartItem of user.cart.items) {
                             const product = products.find((item) => cartItem.id === item.product_id);
                             if (product) {
                                 product.quantity++;
                             } else {
                                 const newItem = {
                                     product_id: cartItem.id,
                                     quantity: 1,
                                     variation_id: cartItem.option?.variation_id
                                 };
                                 if (cartItem.product_option_id) {
                                     newItem.product_option_id = cartItem.product_option_id;
                                 }
                                 products.push(newItem);
                             }
                         }

                         if(sending) {
                             return;
                         }

                         fetch(`${config.backendAddress}/api/v1/shop/orders`, {
                             method: 'POST',
                             body: JSON.stringify({
                                 products,
                                 user: contacts,
                                 payment_method: item.id
                             }),
                             headers: {
                                 'Arpamaailma-Order-Token': Cookies.get('arpamaailma-order-token')
                             }
                         })
                             .then((response) => response.json())
                             .then((json) => {
                                 saveState();
                                 setPaymentDetails(json);
                             })
                             .catch((error) => {
                                 console.error(error);
                                 setSending(false);
                             });
                     }} />
                 ))}
             </PaymentOptions>
            }
            {paymentDetails &&
             <form
                 ref={paymentForm}
                 action={paymentDetails.payment_provider.url}
                 method='POST'
                 id='paymentForm'
                 style={{ display: 'none' }}
             >
                 {paymentDetails.payment_provider.parameters.map(paymentParameterToInput)}
             </form>
            }
        </div>
    );
};

export { PaymentMethod };
