import { Button } from './Button';
import { Confirm } from './Confirm';
import { Warning } from './Warning';
import { Hollow } from './Hollow';

Button.Warning = Warning;
Button.Confirm = Confirm;
Button.Hollow = Hollow;

export { Button };
