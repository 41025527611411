import React, { useContext, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import { Page, LotteryCard, Spinner, Footer } from '../common';
import { useFetch } from '../common/hooks';
import { formatCurrency, getLangValue, getImageValue } from '../common/functions';
import config from '../config';
import { DepositBonusContainer } from '../common/DepositBonus';
import { Slideshow } from '../common/Slideshow/Lotteries';
import { LotteryButton } from '../common/LotteryButton';
import * as appActions from '../store/appActions';

const Grid = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    width: 100%;
    max-width: 1200px;
    margin: 2em auto;
    padding: 20px;

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 5%;
        margin: auto;
    }
`;

const PlayableLotteries = ({
    history,
    app: {
        cms,
        myBonuses
    },
    theme,
    appActions: {
        getBonusSelf
    },
}) => {
    const themeContext = useContext(ThemeContext);

    const [{ data, loading }] = useFetch(`${config.backendAddress}/api/v1/lotteries?variety=playable`);

    useEffect(() => {
        getBonusSelf();
    }, [getBonusSelf]);

    return (
        <Page>
            <Page.Content bgColor={themeContext.darkroom}>
                <div style={{ minHeight: 'calc(100vh - 200px)' }}>
                    <Slideshow slides={
                        cms
                            && cms.playableLotteries
                            && cms.playableLotteries.slides
                    }
                    />

                    <DepositBonusContainer cms={ cms } myBonuses={ myBonuses } />

                    <Grid>
                        {!loading &&
                         data &&
                         (
                             data
                                 .filter((lottery) => !lottery.hidden)
                                 .sort((left, right) => {
                                     if(left.index < right.index) return -1;
                                     if(left.index > right.index) return 1;
                                     return 0;
                                 })
                                 .map((lottery) => (
                                     <LotteryCard
                                         key={lottery.id}
                                         src={getImageValue(lottery.images, 'small')}
                                         bgSize='contain'
                                     >
                                         <LotteryCard.Title>
                                             <LotteryCard.Title.LeftContent>
                                                 <LotteryCard.LotteryName>
                                                     {getLangValue(lottery.names, 'fi')}
                                                 </LotteryCard.LotteryName> |

                                                 <span style={{
                                                     marginLeft: '0.2em',
                                                 }}>
                                                     {
                                                         lottery
                                                             .playable_ticket_prices
                                                             .map((item) => item.price)
                                                             .filter((item, index, self) => self.indexOf(item) === index)
                                                             .map((item) => formatCurrency(item))
                                                             .join(', ')
                                                     } €
                                                 </span>
                                             </LotteryCard.Title.LeftContent>

                                             <LotteryCard.Title.RightContent>
                                                 <LotteryButton
                                                     onClick={
                                                         () => history
                                                             .push('/pelattavat/' + lottery.slug)
                                                     }
                                                 >
                                                     Valitse
                                                 </LotteryButton>
                                             </LotteryCard.Title.RightContent>
                                         </LotteryCard.Title>

                                     </LotteryCard>
                                 ))
                         )}
                        {loading && <Spinner />}
                    </Grid>
                </div>
                <Footer />
            </Page.Content>
        </Page>
    );
};


const mapStateToProps = (state) => {
    return {
        app: state.app
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        appActions: bindActionCreators({ ...appActions }, dispatch),
    };
};

const ConnectedPlayableLotteries = connect(mapStateToProps, mapDispatchToProps)(PlayableLotteries);

export { ConnectedPlayableLotteries as PlayableLotteries };
