import React, { useState, useEffect }  from 'react';
import styled from 'styled-components';
import { getLangValue, getImageValue, formatCurrency } from '../common/functions';
import config from '../config';

const SummaryWrapper = styled.div`
    display: flex;
    justify-content: center;
    overflow: auto;
`;

const Summary = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 5%;
    margin: 0 auto;
`;

const SummarySection = styled.div`
    width: 100%;
    margin-bottom: 1em;
`;

const SummarySectionTitle = styled.div`
    color: ${(props) => props.theme.secondaryText};

    font-weight: 400;
    font-size: 1.5em;
    text-align: center;

    margin-bottom: 1em;
`;

const SummarySectionContent = styled.div`
    display: flex;
    flex-direction: ${(props) => props.column ? 'column' : 'row'};
    color: #2B384A;
    background: white;
    box-shadow: -1px -1px 20px 2px rgba(0,0,0,0.4);
    margin-bottom: 1em;
    @media (max-width: 700px) {
        flex-direction ${(props) => props.mobile ? 'column' : props.column ? 'column' : 'row'};
    }
`;

const SummarySectionPicture = styled.div`
    flex: 2;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    @media (max-width: 700px) {
        flex: 0 1 auto;
        height: 200px;
    }
`;

const SummarySectionText = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: 2em;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 5%;

    @media (max-width: 700px) {
        flex: 0 1 auto;
        font-size: 1.5em;
        height: 150px;
    }
`;

const SmallText = styled.div`
    font-size: 0.7em;
    font-weight: normal;
`;

const TextRow = styled.div`
    display: flex;
    width: 100%;
    font-size: 1.5em;
    margin-bottom: 1em;

    >:first-child {
        font-weight: bold;
        width: 400px;
    }

    >:last-child, &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 700px) {
        display: block;
    }
`;

const TextRowRightColumn = styled.div`
    flex: 3;
    margin-left: 1em;

    @media (max-width: 700px) {
        margin-left: 0;
    }
`;

const Step5Summary = ({ user, items, balanceAmount, paymentAmount, deliveryAmount }) => {
    return (
        <SummaryWrapper>
            <Summary>
                <SummarySection>
                    <SummarySectionTitle>Maksun tiedot</SummarySectionTitle>
                    <SummarySectionContent column style={{ padding: '1em' }}>
                        <TextRow>
                            <div>Käytetään olemassa olevaa saldoa:</div>
                            <TextRowRightColumn>{formatCurrency(balanceAmount, 2)} €</TextRowRightColumn>
                        </TextRow>
                        {deliveryAmount > 0 && (
                            <>
                                <TextRow>
                                    <div>Maksetaan:</div>
                                    <TextRowRightColumn>{formatCurrency(paymentAmount - deliveryAmount, 2)} €</TextRowRightColumn>
                                </TextRow>
                                <TextRow>
                                    <div>
                                        <div>Toimituskulu:</div>
                                        <SmallText>Maksaessasi osan tuotteista rahalla, summaan lisätään toimituskulu</SmallText>
                                    </div>
                                    <TextRowRightColumn>{formatCurrency(deliveryAmount, 2)} €</TextRowRightColumn>
                                </TextRow>
                                <TextRow>
                                    <div>Maksetaan rahalla yhteensä:</div>
                                    <TextRowRightColumn>{formatCurrency(paymentAmount, 2)} €</TextRowRightColumn>
                                </TextRow>
                            </>
                        )}
                    </SummarySectionContent>
                </SummarySection>
                <SummarySection>
                    <SummarySectionTitle>Omat tiedot</SummarySectionTitle>
                    <SummarySectionContent column style={{ padding: '1em' }}>
                        <TextRow>
                            <div>Nimi:</div>
                            <TextRowRightColumn>{`${user.firstname} ${user.lastname}`}</TextRowRightColumn>
                        </TextRow>
                        <TextRow>
                            <div>Sähköposti:</div>
                            <TextRowRightColumn>{user.email}</TextRowRightColumn>
                        </TextRow>
                        {user.phone &&
                            <TextRow>
                                <div>Puhelinnumero:</div>
                                <TextRowRightColumn>{user.phone}</TextRowRightColumn>
                            </TextRow>
                        }
                        <TextRow>
                            <div>Osoite:</div>
                            <TextRowRightColumn>{`${user.address}, ${user.postal_code} ${user.city}`}</TextRowRightColumn>
                        </TextRow>
                    </SummarySectionContent>
                </SummarySection>
                <SummarySection>
                    <SummarySectionTitle>Lunastettavat voitot</SummarySectionTitle>
                    {items && items.map((item, index) => (
                        <SummarySectionContent key={index} mobile>
                            <SummarySectionPicture src={getImageValue(item.images, 'small')} />
                            <SummarySectionText>
                                <div style={{ marginBottom: '1em' }}>{getLangValue(item.names, 'fi')} {item.option && `(${item.option.label})`}</div>
                                <div style={{ fontSize: '0.9em' }}>Arvo {formatCurrency(item.value)} €</div>
                            </SummarySectionText>
                        </SummarySectionContent>
                    ))}
                </SummarySection>
            </Summary>
        </SummaryWrapper>
    );
};

export { Step5Summary as Summary };
