export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS';
export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const UPDATE_CART = 'UPDATE_CART';
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const CLEAR_CART = 'CLEAR_CART';

export const UPDATE_CMS_DATA = 'UPDATE_CMS_DATA';
export const UPDATE_MY_BONUSES = 'UPDATE_MY_BONUSES';
