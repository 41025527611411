import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { Range } from 'rc-slider';

const Label = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => props.theme.secondaryText};
    margin-bottom: 0.2em;
`;

const MinMax = styled.div`
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    font-size: 0.8em;
`;

const Ticks = styled.div`
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin-top: 0.5em;
`;

const trackStyle = {
    background: 'black',
    height: 6,
    position: 'absolute',
    top: 0
};

const handleStyle = {
    width: 20,
    height: 20,
    position: 'absolute',
    background: 'white',
    borderRadius: '50%',
    top: -7,
    cursor: 'grap',
    outline: 'none',
    border: '1px grey solid',
    touchAction: 'pan-x'
};

class RangeSlider extends Component {

    state = {
        values: [this.props.min, this.props.max]
    }

    onChange = (values) => {
        this.setState({ values });
        if (this.props.onChange) {
            this.props.onChange(values);
        }
    }

    render() {
        const { values } = this.state;
        const { min, max, label, onChange, theme, formatMinMax, ...rest } = this.props;

        return (
            <div {...rest}>
                <Label>{label}</Label>
                <MinMax>
                    <div>Min</div>
                    <div>Max</div>
                </MinMax>
                <Range
                    style={{
                        position: 'relative',
                        marginLeft: 5,
                        marginRight: 12
                    }}
                    allowCross={false}
                    min={min}
                    max={max}
                    defaultValue={[min, max]}
                    trackStyle={[trackStyle]}
                    onChange={this.onChange}
                    handleStyle={[{ ...handleStyle, marginLeft: 4 }, handleStyle]}
                    railStyle={{
                        background: theme.backgroundDark,
                        width: '100%',
                        height: 6,
                        borderRadius: 3
                    }}/>
                <Ticks>
                    <div>{formatMinMax ? formatMinMax(values[0]) : values[0]}</div>
                    <div>{formatMinMax ? formatMinMax(values[1]) : values[1]}</div>
                </Ticks>
            </div>
        );
    }
}

const RangeSliderThemed = withTheme(RangeSlider);


export { RangeSliderThemed as RangeSlider };
