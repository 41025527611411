import { useEffect } from 'react';
import * as Cookies from 'js-cookie';
import config from './config';
import { fetchWrap as fetch } from './common/functions';

const Auth = ({ history, children, location: { search } }) => {
    useEffect(() => {
        const params = new URLSearchParams(search);
        const code = params.get('code');
        const state = params.get('state');
        Cookies.set('auth_code', code);
        Cookies.set('auth_state', state);

        // Call auth endpoint to finish authentication
        fetch(`${config.backendAddress}/api/v1/users/auth`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${Cookies.get('access_token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: code,
                state: state
            }),
        }, [400])
            .catch((error) => {
                window.location.href = '/tili?auth=error';
            })
            .then((response) => response.json())
            .then((json) => {
                console.log('AUTH: ', json.error);
                if (json.ok===false) {
                    if(json.error === 'Already exists') {
                        window.location.href = '/tili?auth=eventio-error';
                    } else {
                        window.location.href = '/tili?auth=error';
                    }
                } else {
                    window.location.href = '/tili?auth=ok';
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children || null;
};

export default Auth;
